import React, { useState } from 'react';
import styles from './styles/ScenarioPassword.module.css';

const ScenarioPassword = ({ scenarioPassword, setHasAccess, passwordError, setPasswordError }) => {
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const handlePasswordSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        if (password === scenarioPassword) {
            setHasAccess(true);
            setPasswordError(false);
        } else {
            setPasswordError(true);
        }
        setLoading(false);
    };

    return (
        <div className={styles.container}>
            <h2 className={styles.h2}>Enter Password</h2>
            <form onSubmit={handlePasswordSubmit} className={styles.form}>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className={`${styles.input} ${passwordError ? styles.errorInput : ''}`}
                    placeholder="Password"
                />
                {passwordError && <p className={styles.errorText}>Incorrect Password</p>}
                <button
                    type="submit"
                    className={styles.button}
                    disabled={loading}
                >
                    {loading ? 'Verifying...' : 'Submit'}
                </button>
            </form>
        </div>
    );
};

export default ScenarioPassword;
