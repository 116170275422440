import DOMPurify from 'dompurify';


export function transformIframes(html) {
    if (typeof html !== 'string') {
        return '';
    }

    // Sanitize the HTML to remove any potentially harmful code
    const sanitizedHtml = DOMPurify.sanitize(html, {
        ADD_TAGS: ['iframe', 'oembed'],
        ADD_ATTR: ['url', 'allow', 'allowfullscreen', 'frameborder', 'scrolling'],
    });

    // Create a DOM parser to parse the sanitized HTML
    const parser = new DOMParser();
    const doc = parser.parseFromString(sanitizedHtml, 'text/html');

    // Process <a> tags linking to YouTube videos
    const anchors = doc.querySelectorAll('a[href*="youtube.com/watch"], a[href*="youtu.be/"]');
    anchors.forEach(anchor => {
        const url = anchor.getAttribute('href');
        const iframe = createYouTubeIframe(url);
        if (iframe) {
            anchor.parentNode.replaceChild(iframe, anchor);
        }
    });

    // Process <oembed> tags
    const oembeds = doc.querySelectorAll('oembed[url*="youtube.com/watch"], oembed[url*="youtu.be/"]');
    oembeds.forEach(oembed => {
        const url = oembed.getAttribute('url');
        const iframe = createYouTubeIframe(url);
        if (iframe) {
            oembed.parentNode.replaceChild(iframe, oembed);
        }
    });

    // Process <iframe> tags with url attribute
    const iframes = doc.querySelectorAll('iframe[url*="youtube.com/watch"], iframe[url*="youtu.be/"]');
    iframes.forEach(iframe => {
        const url = iframe.getAttribute('url');
        const newIframe = createYouTubeIframe(url);
        if (newIframe) {
            iframe.parentNode.replaceChild(newIframe, iframe);
        }
    });

    // Serialize the document back to HTML
    return doc.body.innerHTML;
}

// Helper function to create an iframe element for YouTube
function createYouTubeIframe(url) {
    try {
        const urlObj = new URL(url);
        const videoId = getYouTubeVideoId(urlObj);
        if (!videoId) return null;

        const params = new URLSearchParams(urlObj.search);

        let start = params.get('t') || params.get('start');
        let end = params.get('end');

        // Convert time formats like '1m30s' to seconds if necessary
        start = parseTimeToSeconds(start);
        end = parseTimeToSeconds(end);

        // Construct the embed URL with start and end parameters if they exist
        let embedUrl = `https://www.youtube.com/embed/${videoId}`;
        const embedParams = new URLSearchParams();
        if (start) embedParams.set('start', start);
        if (end) embedParams.set('end', end);

        if ([...embedParams].length > 0) {
            embedUrl += `?${embedParams.toString()}`;
        }

        const iframe = document.createElement('iframe');
        iframe.setAttribute('width', '560');
        iframe.setAttribute('height', '315');
        iframe.setAttribute('src', embedUrl);
        iframe.setAttribute('frameborder', '0');
        iframe.setAttribute('allowfullscreen', '');
        iframe.setAttribute('allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture');

        return iframe;
    } catch (error) {
        console.error('Error creating YouTube iframe:', error);
        return null;
    }
}

// Helper function to extract the YouTube video ID from a URL
function getYouTubeVideoId(urlObj) {
    if (urlObj.hostname.includes('youtu.be')) {
        return urlObj.pathname.slice(1);
    } else if (urlObj.hostname.includes('youtube.com')) {
        return urlObj.searchParams.get('v');
    } else {
        return null;
    }
}

// Helper function to parse time formats to seconds
function parseTimeToSeconds(time) {
    if (!time) return null;

    // If time is already in seconds
    if (/^\d+$/.test(time)) {
        return time;
    }

    // Parse time format like '1h2m3s'
    const timeRegex = /(?:(\d+)h)?(?:(\d+)m)?(?:(\d+)s)?/;
    const match = time.match(timeRegex);
    if (!match) return null;

    const hours = parseInt(match[1] || '0', 10);
    const minutes = parseInt(match[2] || '0', 10);
    const seconds = parseInt(match[3] || '0', 10);

    return (hours * 3600 + minutes * 60 + seconds).toString();
}


export function getMediaSrc(audio) {
    if (typeof audio === 'string') {
        // If audio is already a string (URL or data URL), return it directly
        return audio;
    } else if (audio && typeof audio === 'object' && audio.src) {
        // If audio is an object with a 'src' property, return the 'src'
        return audio.src;
    } else {
        // Handle any other cases (e.g., null or undefined)
        return '';
    }
}

export function stripHtml(html) {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
}

export function calculateTimeSince(date) {
    const now = Date.now();
    const created = new Date(date).getTime();
    const diffInSeconds = Math.floor((now - created) / 1000);
    let unit = 'second';
    let diffInUnit = diffInSeconds;
    const units = {
        year: 31536000,
        month: 2628000,
        day: 86400,
        hour: 3600,
        minute: 60,
    };
    for (const [unitKey, secondsInUnit] of Object.entries(units)) {
        if (diffInSeconds >= secondsInUnit) {
            unit = unitKey;
            diffInUnit = Math.floor(diffInSeconds / secondsInUnit);
            break;
        }
    }
    if (diffInUnit !== 1) {
        unit += 's';
    }
    return `${diffInUnit} ${unit}`;
}

export function checkValidity(nodes, edges) {
    let startNodes = nodes.filter(node => node.data.endpointType === 'start');
    let endNodes = nodes.filter(node => node.data.endpointType === 'end');

    console.log("START NODES: ", startNodes);

    const errors = [];
    const visited = new Set();
    const recursionStack = new Set();
    const pathSuccess = new Map();

    if (startNodes.length === 0) {
        errors.push({ reason: 'No start node found' });
        return errors;
    }
    if (startNodes.length > 1) {
        startNodes.forEach(node => errors.push({ node, reason: 'Multiple start nodes' }));
    }
    if (endNodes.length === 0) {
        errors.push({ reason: 'No end node found' });
        return errors;
    }

    function hasPathToEnd(nodeId) {
        let stack = [nodeId];
        let visited = new Set();

        while (stack.length > 0) {
            let currentNodeId = stack.pop();
            let currentNode = nodes.find(n => n.id === currentNodeId);

            visited.add(currentNodeId);

            if (currentNode.data.endpointType === 'end') {
                return true;
            }

            let outgoingEdges = edges.filter(edge => edge.source === currentNodeId && !visited.has(edge.target));
            outgoingEdges.forEach(edge => {
                stack.push(edge.target);
            });
        }
        return false;
    }

    function dfs(node, path = []) {
        let currentPathSuccess = pathSuccess.get(node.id) || false;

        if (visited.has(node.id)) {
            return currentPathSuccess;
        }

        visited.add(node.id);
        recursionStack.add(node.id);
        path.push(node);

        let success = node.data.endpointType === 'end';

        const outgoingEdges = edges.filter(edge => edge.source === node.id);
        if (!success && outgoingEdges.length == 0) {
            errors.push({ node, reason: 'Dead end' });
        }

        outgoingEdges.forEach(edge => {
            const targetNode = nodes.find(n => n.id === edge.target);
            if (edge.source === edge.target && edge.data.choiceType !== 'hint') {
                errors.push({ edge, reason: 'Needs to be a hint' });
            } else if (targetNode) {
                if (recursionStack.has(targetNode.id)) {
                    let cycle = path.slice(path.indexOf(targetNode)).map(n => n.id);
                    let validCycle = hasPathToEnd(targetNode.id);
                    if (!validCycle) {
                        cycle.forEach(nodeId => pathSuccess.set(nodeId, false));
                    }
                    success = validCycle || success;
                } else {
                    success = dfs(targetNode, path) || success;
                }
            }
        });

        recursionStack.delete(node.id);
        path.pop();
        pathSuccess.set(node.id, success);

        if (success) {
            path.forEach(p => pathSuccess.set(p.id, true));
        }

        return success;
    }

    startNodes.forEach(node => dfs(node));

    nodes.forEach(node => {
        if (!visited.has(node.id) && node.data.endpointType !== 'start') {
            errors.push({ node, reason: 'Unreachable node' });
        }
        if (!pathSuccess.get(node.id) && visited.has(node.id)) {
            errors.push({ node, reason: 'Part of a dead cycle' });
        }
    });

    return errors;
}


export const classroomImages = [
    'https://images.unsplash.com/photo-1509062522246-3755977927d7?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8Y2xhc3Nyb29tfGVufDB8fDB8fHwy',
    'https://images.unsplash.com/photo-1580582932707-520aed937b7b?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8Y2xhc3Nyb29tfGVufDB8fDB8fHwy',
    'https://images.unsplash.com/file-1695862032969-7a88a86ce4d5image?dpr=2&w=416&auto=format&fit=crop&q=60',
    'https://images.unsplash.com/photo-1604134967494-8a9ed3adea0d?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8Y2xhc3Nyb29tfGVufDB8fDB8fHwy',
    'https://images.unsplash.com/photo-1503676260728-1c00da094a0b?q=80&w=2922&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    'https://images.unsplash.com/photo-1544640808-32ca72ac7f37?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8Y2xhc3Nyb29tfGVufDB8fDB8fHwy',
    'https://images.unsplash.com/photo-1585637071663-799845ad5212?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8Y2xhc3Nyb29tfGVufDB8fDB8fHwy',
    'https://images.unsplash.com/photo-1588072432836-e10032774350?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8Y2xhc3Nyb29tfGVufDB8fDB8fHwy',
    'https://images.unsplash.com/photo-1588075592446-265fd1e6e76f?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8Y2xhc3Nyb29tfGVufDB8fDB8fHwy',
    'https://images.unsplash.com/photo-1581726707445-75cbe4efc586?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fGNsYXNzcm9vbXxlbnwwfHwwfHx8Mg%3D%3D',
    'https://images.unsplash.com/photo-1617721926586-4eecce739745?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGNsYXNzcm9vbXxlbnwwfHwwfHx8Mg%3D%3D'
];
