import React, { useContext, useState } from 'react';
import { getBezierPath } from 'reactflow';
import CanvasContext from '../../../context/CanvasContext';
import styles from './styles/Edge.module.css';

/**
 * I will be mature I will be mature I will be mature I will be mature I will be mature I will be mature
 * I will be mature I will be mature I will be mature I will be mature I will be mature I will be mature
 * I will be mature I will be mature I will be mature I will be mature I will be mature I will be mature
 * I will be mature I will be mature I will be mature I will be mature I will be mature I will be mature
 * I will be mature I will be mature I will be mature I will be mature I will be mature I will be mature
 * I will be mature I will be mature I will be mature I will be mature I will be mature I will be mature
 * I will be mature I will be mature I will be mature I will be mature I will be mature I will be mature
 * I will be mature I will be mature I will be mature I will be mature I will be mature I will be mature
 * I will be mature I will be mature I will be mature I will be mature I will be mature I will be mature
 * I will be mature I will be mature I will be mature I will be mature I will be mature I will be mature
 */
const Edge = ({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition = 'right',
    targetPosition = 'left',
    source,
    target,
    data,
}) => {
    const { selectedEdgeId, edges, getChoice } = useContext(CanvasContext);
    const [hoveredEdgeId, setHoveredEdgeId] = useState(null);
    const [labelHovered, setLabelHovered] = useState(false);

    /**
     * I failed
     */
    const isEdgingItself = (edgeId) => edgeId === selectedEdgeId;


    const selectedEdgeStyle = {
        stroke: '#006666',
        strokeWidth: 2,
    };

    // Identify similar edges
    const similarEdges = edges.filter(
        (edge) =>
            (edge.source === source && edge.target === target) || 
            (edge.source === target && edge.target === source)
    );
    
    

    // Safeguard: Handle empty similarEdges
    if (similarEdges.length === 0) {
        // No similar edges, so nothing to render
        return null;
    }

    const isFirstEdge = similarEdges[0].id === id;

    if (!isFirstEdge) {
        // Do not render anything for subsequent similar edges
        return null;
    }

    // Determine if labels should be expanded
    const labelsExpanded =
        hoveredEdgeId !== null ||
        labelHovered ||
        similarEdges.some((edge) => edge.id === selectedEdgeId);

    // Handle edge hover
    const handleMouseEnter = (edgeId) => setHoveredEdgeId(edgeId);
    const handleMouseLeave = () => setHoveredEdgeId(null);

    // Handle label hover
    const handleLabelMouseEnter = () => setLabelHovered(true);
    const handleLabelMouseLeave = () => setLabelHovered(false);

    // Handle edge click
    const handleEdgeClick = async (edgeId, event) => {
        event.stopPropagation();
        await getChoice(edgeId);
    };

    // Define default styles
    const defaultEdgeStyle = {
        stroke: '#000',
        strokeWidth: 1,
        fill: 'none',
    };

    // Edge paths rendering
    const edgePaths = similarEdges.map((edge) => {
        const isLoopEdge = edge.source === edge.target;

        let edgePath;

        if (isLoopEdge) {
            // Handle loop edge
            const offset = 5; // Horizontal offset for loop start and end points
            const radiusX = (sourceX - targetX) * 0.6;
            const radiusY = 50;

            // Construct the loop path using SVG arc commands
            edgePath = `M ${sourceX - offset},${sourceY} A ${radiusX},${radiusY} 0 1,0 ${targetX + offset},${targetY}`;
        } else {
            // Use getBezierPath for regular edges
            [edgePath] = getBezierPath({
                sourceX,
                sourceY,
                sourcePosition,
                targetX,
                targetY,
                targetPosition,
            });
        }

        // Determine if the edge is selected
        const isSelected = isEdgingItself(edge.id);

        // Handle hover state for individual edges
        const isHovered = hoveredEdgeId === edge.id;

        // Check if the edge has visibility conditions
        const hasKeyCondition = edge.data.showIfKey || edge.data.hideIfKey;

        const edgeStyle = {
            stroke: isSelected
                ? selectedEdgeStyle.stroke
                : isHovered
                ? '#999' // Change color on hover
                : '#000',
            strokeWidth: isSelected
                ? selectedEdgeStyle.strokeWidth
                : isHovered
                ? 2 // Increase width on hover
                : 1,
            fill: 'none',
            cursor: 'pointer',
            // Add strokeDasharray for dashed/dotted lines
            strokeDasharray: hasKeyCondition ? '5,5' : undefined,
        };

        return (
            <g key={edge.id}>
                <defs>
                    {/* Define the arrowhead marker */}
                    <marker
                        id={`arrow-${edge.id}`}
                        markerWidth="10"
                        markerHeight="7"
                        refX="10"
                        refY="3.5"
                        orient="auto"
                        markerUnits="userSpaceOnUse"
                    >
                        <path
                            d="M0,0 L0,7 L10,3.5 z"
                            fill={isSelected ? selectedEdgeStyle.stroke : edgeStyle.stroke}
                        />
                    </marker>
                </defs>
                {/* Edge path */}
                <path
                    id={edge.id}
                    d={edgePath}
                    style={edgeStyle}
                    markerEnd={`url(#arrow-${edge.id})`}
                    onMouseEnter={() => handleMouseEnter(edge.id)}
                    onMouseLeave={handleMouseLeave}
                    onClick={(event) => handleEdgeClick(edge.id, event)}
                />
                {/* Invisible path to increase click area */}
                <path
                    d={edgePath}
                    stroke="transparent"
                    strokeWidth={15}
                    fill="none"
                    onMouseEnter={() => handleMouseEnter(edge.id)}
                    onMouseLeave={handleMouseLeave}
                    onClick={(event) => handleEdgeClick(edge.id, event)}
                    style={{ cursor: 'pointer' }}
                />
            </g>
        );
    });


    // Calculate label position
    let labelX, labelY;

    const isLoopEdge = source === target;

    if (isLoopEdge) {
        // Handle loop edge label position
        const radiusY = 50;

        labelX = (sourceX + targetX) / 2;
        labelY = sourceY - 1.55 * radiusY;
    } else {
        // Use getBezierPath for regular edges
        [, labelX, labelY] = getBezierPath({
            sourceX,
            sourceY,
            sourcePosition,
            targetX,
            targetY,
            targetPosition,
        });
    }

    // Render labels
    const renderLabels = () => {
        const boxWidth = 86;
        const boxHeight = 20;
        const isSelected = isEdgingItself(id);
        const yOffset = 3;
        const padding = 4; // Padding inside the rectangle

        // Function to get truncated text with ellipsis if too long
        const getEllipsizedText = (text, maxWidth, ctx) => {
            const ellipsis = '...';
            let truncatedText = text;
            
            // Measure text width using a canvas context
            let textWidth = ctx.measureText(truncatedText).width;

            while (textWidth > maxWidth && truncatedText.length > 0) {
                truncatedText = truncatedText.slice(0, -1);
                textWidth = ctx.measureText(truncatedText + ellipsis).width;
            }

            return truncatedText.length < text.length ? truncatedText + ellipsis : text;
        };

        // Create a canvas context to measure text width
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        ctx.font = '12px Arial'; // Adjust this to match your SVG font

        if (similarEdges.length          === 1) {
            // Only one edge, render single label using SVG elements
            const maxTextWidth = boxWidth - padding * 2;
            const truncatedLabel = getEllipsizedText(data.label, maxTextWidth, ctx);

            return (
                <g
                    className={styles.edgeLabelGroup}
                    onMouseEnter={handleLabelMouseEnter}
                    onMouseLeave={handleLabelMouseLeave}
                    onClick={(event) => handleEdgeClick(id, event)}
                    style={{ cursor: 'pointer' }}
                >
                    <rect
                        x={labelX - boxWidth / 2}
                        y={labelY - boxHeight / 2}
                        width={boxWidth}
                        height={boxHeight}
                        fill={isSelected ? '#006666' : '#fff'}
                        stroke="#000"
                        strokeWidth="1"
                        pointerEvents="all" // Ensure rect captures all pointer events
                    />
                    <text
                        x={labelX}
                        y={labelY + yOffset}
                        textAnchor="middle"
                        alignmentBaseline="middle"
                        className={`${styles.edgeLabel} ${isSelected ? styles.edgeLabelSelected : ''}`}
                        pointerEvents="none"
                    >
                        {truncatedLabel}
                    </text>
                </g>
            );
        } else {
            // Multiple similar edges
            if (!labelsExpanded) {
                // Collapsed labels: stack rectangles with offsets
                const offset = 3; // Offset between stacked boxes
                const boxesToRender = Math.min(3, similarEdges.length);
                const rectangles = [];

                for (let i = 0; i < boxesToRender; i++) {
                    const offsetValue = (boxesToRender - 1 - i) * offset;
                    rectangles.push(
                        <rect
                            key={`rect-${i}`}
                            x={labelX - boxWidth / 2 + offsetValue}
                            y={labelY - boxHeight / 2 - offsetValue}
                            width={boxWidth}
                            height={boxHeight}
                            fill={isSelected ? '#006666' : '#fff'}
                            stroke="#000"
                            strokeWidth="1"
                            pointerEvents="all"
                            style={{ cursor: 'pointer' }}
                        />
                    );
                }

                const maxTextWidth = boxWidth - padding * 2;
                const truncatedLabel = getEllipsizedText(
                    similarEdges[similarEdges.length - 1].data.label,
                    maxTextWidth,
                    ctx
                );

                return (
                    <g
                        className={styles.edgeLabelGroup}
                        onMouseEnter={handleLabelMouseEnter}
                        onMouseLeave={handleLabelMouseLeave}
                        onClick={(event) =>
                            handleEdgeClick(similarEdges[similarEdges.length - 1].id, event)
                        }
                        style={{ cursor: 'pointer' }}
                    >
                        {rectangles}
                        <text
                            x={labelX}
                            y={labelY + yOffset}
                            textAnchor="middle"
                            alignmentBaseline="middle"
                            className={`${styles.edgeLabel}`}
                            pointerEvents="none"
                        >
                            {truncatedLabel}
                        </text>
                    </g>
                );
            } else {
                // Expanded labels: stack labels vertically
                const spacing = 0;
                const totalHeight =
                    boxHeight * similarEdges.length + spacing * (similarEdges.length - 1);
                const startYPosition = labelY - boxHeight / 2 - (totalHeight - boxHeight);

                const labels = similarEdges
                    .slice()
                    .reverse()
                    .map((edge, index) => {
                        const yPosition = startYPosition + index * (boxHeight);
                        const isSelected = isEdgingItself(edge.id);
                        const maxTextWidth = boxWidth - padding * 2;
                        const truncatedLabel = getEllipsizedText(edge.data.label, maxTextWidth, ctx);

                        return (
                            <g
                                key={`label-${edge.id}`}
                                onClick={(event) => handleEdgeClick(edge.id, event)}
                                style={{ cursor: 'pointer' }}
                            >
                                <rect
                                    x={labelX - boxWidth / 2}
                                    y={yPosition}
                                    width={boxWidth}
                                    height={boxHeight}
                                    fill={isSelected ? '#006666' : '#fff'}
                                    stroke="#000"
                                    strokeWidth="1"
                                    pointerEvents="all"
                                />
                                <text
                                    x={labelX}
                                    y={yPosition + boxHeight / 2 + yOffset}
                                    textAnchor="middle"
                                    alignmentBaseline="middle"
                                    className={`${styles.edgeLabel} ${
                                        isSelected ? styles.edgeLabelSelected : ''
                                    }`}
                                    pointerEvents="none"
                                >
                                    {truncatedLabel}
                                </text>
                            </g>
                        );
                    });

                return (
                    <g
                        className={styles.edgeLabelGroup}
                        onMouseEnter={handleLabelMouseEnter}
                        onMouseLeave={handleLabelMouseLeave}
                    >
                        {labels}
                    </g>
                );
            }
        }
    };

    return (
        <svg style={{ overflow: 'visible', position: 'absolute', left: 0, top: 0 }}>
            {edgePaths}
            {/* Render labels */}
            {renderLabels()}
        </svg>
    );
};

export default Edge;
