import React from 'react';
import styles from './styles/AccountBody.module.css';
import AccountProfile from './AccountProfile';
import AccountScenarios from './AccountScenarios';
import Logs from './Logs';
import Feedback from './Feedback';
import AccountSettings from './AccountSettings';

const AccountBody = ({ isMobile, activeTab, userIsAccountOwner, accountOwner, setAccountOwner }) => {

    return (
        <div className={isMobile ? styles.bodyMobile : styles.bodyNotMobile}>
            {activeTab === 'Profile' && <AccountProfile userIsAccountOwner={userIsAccountOwner} accountOwner={accountOwner} setAccountOwner={setAccountOwner}/>}
            {activeTab === 'Scenarios' && <AccountScenarios userIsAccountOwner={userIsAccountOwner} accountOwner={accountOwner} setAccountOwner={setAccountOwner}/>}
            {userIsAccountOwner && (
                <>
                    {activeTab === 'Logs' && <Logs />}
                    {activeTab === 'Feedback' && <Feedback userId={accountOwner._id}/>}
                    {activeTab === 'Settings' && <AccountSettings />}
                </>
            )}
        </div>
    );
};

export default AccountBody;
