import React, { useState } from 'react';
import axios from 'axios';
import styles from './styles/LoginRegister.module.css';

function ForgotPassword({ setAuthModalState }) {
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const submitForm = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`${process.env.REACT_APP_API_URL_LOCAL}/api/users/forgot-password`, { email });
            setErrorMessage('');
            setSuccessMessage('A password reset link has been sent to your email');
        } catch (error) {
            if (error.response) {
                setErrorMessage(error.response.data.errorMessage);
            } else {
                console.error(error);
            }
        }
    };

    const handleClickBack = () => {
        setAuthModalState('login');
    };

    return (
        <div className={styles.container}>
            <h1 className={styles.h1}>Find your AlphaScenario account</h1>
            <form onSubmit={submitForm} className={styles.form}>
                <label className={styles.label}>
                    What's your email?
                </label>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className={styles.input}
                />
                {errorMessage && <div className={styles.error}>{errorMessage}</div>}
                {successMessage && <div className={styles.success}>{successMessage}</div>}
                <div className={styles.forgotPasswordButtonContainer}>
                    <button
                        type="button"
                        className={styles.forgotPasswordButton}
                        onClick={handleClickBack}
                    >
                        Back
                    </button>
                    <button
                        type="submit"
                        className={styles.forgotPasswordButton}
                    >
                        Submit
                    </button>
                </div>
            </form>
        </div>
    );
}

export default ForgotPassword;
