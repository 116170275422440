import React, { useContext } from 'react';
import styles from './styles/SituationsList.module.css';
import CanvasContext from '../../../context/CanvasContext';

const SituationsList = ({ situations }) => {
    const { updateSituation, nodes, updateNodes, getSituation, setActiveEditTab } = useContext(CanvasContext);

    const removeSituationFromGroup = async (situationId) => {
        try {
            await updateSituation({ situationGroup: null }, situationId);
            const updatedNodes = nodes.map(node => 
                node.id === situationId ? { ...node, data: { ...node.data, fillColor: '#ffffff' } } : node
            );
            await updateNodes(updatedNodes);
        } catch (error) {
            console.error("Error removing situation from group or updating canvas: ", error);
        }
    };

    const handleSituationClick = async (situationId) => {
        await getSituation(situationId);
        setActiveEditTab("1");

    };

    return (
        <div className={styles.situationsContainer}>
            {(!situations || situations.length === 0) ? (
                <div className={styles.noSituationsText}>No Situations</div>
            ) : (
                situations.map((situation) => (
                    <div
                        key={situation._id}
                        className={styles.situationItem}
                        onClick={() => handleSituationClick(situation._id)}
                    >
                        <div className={styles.situationTitle}>
                            {situation.title || 'Untitled'}
                        </div>
                        <button
                            className={styles.removeButton}
                            onClick={(e) => {
                                e.stopPropagation(); // Prevent triggering the situation click when removing
                                removeSituationFromGroup(situation._id);
                            }}
                            aria-label="Remove situation"
                        >
                            <span className={styles.minusIcon}>−</span>
                        </button>
                    </div>
                ))
            )}
        </div>
    );
    
};

export default SituationsList;
