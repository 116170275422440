import React, { useContext, useState } from "react";
import axios from "axios";
import AuthContext from "../../context/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import styles from './styles/LoginRegister.module.css';

function Login({ setShowModal, setAuthModalState, acceptInvite, setAcceptInvite }) {
    const [userIdentifier, setUserIdentifier] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false); // State for password visibility
    const [errorMessage, setErrorMessage] = useState(""); // New state for error message
    const { getLoggedIn } = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();

    const login = async (e) => {
        e.preventDefault();
        try {
            const loginData = { userIdentifier: userIdentifier.trim(), password };
            const response = await axios.post(`${process.env.REACT_APP_API_URL_LOCAL}/api/users/login`, loginData, { withCredentials: true });

            if (response.status === 200) {
                await getLoggedIn();
                setShowModal(false); // Close the modal immediately
                setAcceptInvite(false);
                if (acceptInvite) {
                    const token = location.state?.token;
                    if (token) {
                        // Update the state before making the request
                        navigate(`/my-classrooms/${location.state?.classroomId}/a`, { replace: true, state: { showModal: false } });
                        await axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/notifications/acceptInviteFromLink`, { token });
                    } else {
                        navigate("/home", { replace: true, state: { showModal: false } });
                    }
                } else {
                    navigate("/home", { replace: true, state: { showModal: false } });
                }
            } else {
                setErrorMessage("Unexpected response status: " + response.status);
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.errorMessage) {
                setErrorMessage(error.response.data.errorMessage);
            } else {
                setErrorMessage("An error occurred during login.");
                console.error(error);
            }
        }
    };

    const handleClickForgotPassword = () => {
        setAuthModalState('forgot password');
    };

    const handleClickSignUpHere = () => {
        setAuthModalState('sign up');
    };

    const handleClickContinueAsGuest = () => {
        setShowModal(false);
    };

    return (
        <div className={styles.container}>
            <img
                className={styles.logo}
                src={require('../../images/logo_teal_background.png')}
                alt="Home"
            />
            <h1 className={styles.h1}>Welcome to Scenario</h1>
            <form onSubmit={login} className={styles.form}>
                <input
                    placeholder="Username or Email"
                    onChange={(e) => setUserIdentifier(e.target.value)}
                    value={userIdentifier}
                    className={styles.input}
                />
                <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    className={styles.input}
                />
                <label className={styles.showPassword}>
                    <input 
                        type="checkbox" 
                        checked={showPassword}
                        onChange={(e) => setShowPassword(e.target.checked)} 
                        className={styles.checkbox}
                    />
                    Show Password
                </label>
                {errorMessage && <div className={styles.error}> {errorMessage} </div>}
                <div className={styles.forgotPassword}><span className={styles.linkSpan} onClick={handleClickForgotPassword}>Forgot Password?</span></div> 
                <button className={styles.button} type="submit">LOGIN</button>
            </form>
            <div className={styles.links}>
                Don't have an account? <span className={styles.linkSpan} onClick={handleClickSignUpHere}>Sign Up Here</span>
                {!acceptInvite && (
                    <>
                        <br />
                        <span className={styles.orText}>OR</span>
                        <br />
                        <span className={styles.linkSpan} onClick={handleClickContinueAsGuest}>Continue as a guest</span>
                    </>
                )}
            </div>
        </div>
    );
}

export default Login;
