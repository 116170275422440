import React from 'react';
import styles from './styles/DeleteScenarioModal.module.css';

const DeleteScenarioModal = ({ handleConfirmDelete, handleCancelDelete }) => {
    return (
        <div className={styles.overlay}>
            <div className={styles.modal}>
                <div className={styles.header}>
                    <h2>Confirm Delete</h2>
                    <button className={styles.closeButton} onClick={handleCancelDelete}>&times;</button>
                </div>
                <div className={styles.body}>
                    <p>Are you sure you want to delete this scenario?</p>
                </div>
                <div className={styles.footer}>
                    <button className={styles.secondaryButton} onClick={handleCancelDelete}>No</button>
                    <button className={styles.dangerButton} onClick={handleConfirmDelete}>Yes</button>
                </div>
            </div>
        </div>
    );
};

export default DeleteScenarioModal;
