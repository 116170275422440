import React, { useState, useContext, useEffect } from 'react';
import CanvasContext from '../../../context/CanvasContext';
import styles from './styles/UploadModal.module.css';
import MediaPlayer from '../../../utils/MediaPlayer';
import { getMediaSrc } from '../../../utils/utils';

const UploadModal = ({ uploadType, setIsModalOpen }) => {
    const {
        updateSituation,
        updateGroup,
        selectedSituation,
        setSelectedSituation,
        setSelectedGroup,
        selectedGroup,
    } = useContext(CanvasContext);
    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (selectedSituation || selectedGroup) {
            if (uploadType === 'image') {
                const existingImage = selectedSituation?.image || selectedGroup?.image;
                if (existingImage) {
                    setPreview(existingImage);
                }
            } else if (uploadType === 'audiovideo') {
                const existingMedia =
                    selectedSituation?.audio ||
                    selectedGroup?.audio ||
                    selectedSituation?.video ||
                    selectedGroup?.video;
                if (existingMedia) {
                    setPreview(existingMedia);
                }
            }
        }
    }, [selectedSituation, selectedGroup, uploadType]);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
          // Validate file size (e.g., max 50MB)
          if (file.size > 50 * 1024 * 1024) {
            setErrorMessage('File size exceeds 50MB limit.');
            return;
          }
          // Validate file type if necessary
      
          setFile(file);
      
          const reader = new FileReader();
          reader.onloadend = () => setPreview(reader.result);
          reader.readAsDataURL(file);
        }
    };
      

    const handleSave = async () => {
        if (file) {
            const formData = new FormData();
            formData.append(uploadType === 'image' ? 'image' : 'audio', file);
    
            try {
                let updatedSituation;
                let updatedGroup;
                if (selectedSituation) {
                    updatedSituation = await updateSituation(formData); // Update the situation
                } else if (selectedGroup) {
                    updatedGroup = await updateGroup(formData);
                }
                setIsModalOpen(false);
    
                if (updatedSituation) {
                    setSelectedSituation(updatedSituation);
                } else if (updatedGroup) {
                    setSelectedGroup(updatedGroup);
                }
            } catch (error) {
                console.error(`Error updating ${uploadType}: `, error);
                setErrorMessage('An error occurred while uploading. Please try again.');
            }
        }
    };
    

    const closeModal = () => {
        setIsModalOpen(false);
        setFile(null);
        setPreview(null);
    };

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
                <h2 className={styles.modalTitle}>
                    Upload {uploadType === 'image' ? 'Image' : 'Audio/Video'}
                </h2>
                <label
                    htmlFor="file-upload-input"
                    className={`${styles.buttonBase} ${styles.uploadButton}`}
                >
                    {preview
                        ? `Change ${uploadType === 'image' ? 'Image' : 'Media'}`
                        : `Upload ${uploadType === 'image' ? 'Image' : 'Media'}`}
                </label>
                <input
                    type="file"
                    id="file-upload-input"
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                    accept={uploadType === 'image' ? 'image/*' : 'audio/*,video/*'}
                />
                {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
                {preview && (
                    <div className={styles.previewContainer}>
                        {uploadType === 'image' ? (
                            <img src={preview} alt="Preview" className={styles.previewImage} />
                        ) : (
                            <MediaPlayer
                                src={getMediaSrc(preview)}
                                autoplay={false}
                                className={styles.previewMedia}
                            />
                        )}
                    </div>
                )}
                <div className={styles.buttonGroup}>
                    <button
                        onClick={handleSave}
                        className={`${styles.buttonBase} ${styles.saveButton}`}
                        disabled={!file} // Disable Save button if no file is selected
                    >
                        Save
                    </button>
                    <button
                        onClick={closeModal}
                        className={`${styles.buttonBase} ${styles.cancelButton}`}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

export default UploadModal;
