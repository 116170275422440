import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from './styles/TopBar.module.css';
import { useScenario } from '../../../context/ScenarioContext';
import CanvasContext from '../../../context/CanvasContext';
import { checkValidity } from '../../../utils/utils';
import  { Tooltip } from 'react-tooltip';
import {
    FaUserPlus,
    FaCopy,
    FaCheckCircle,
    FaExclamationCircle,
    FaEye,
    FaPaperPlane,
    FaPlus,
    FaSearch,
    FaSignOutAlt,
    FaEdit,
} from 'react-icons/fa';


const TopBar = ({renderEditSidebar, setRenderEditSidebar, isMobile}) => {

    const { 
        scenario, groups, nodes, edges, selectedNodeIds, updateNodes, createGroup, onSelectGroup,
        copyNode, updateSituation, graphErrors, setGraphErrors, onCanvasClick,
    } = useContext(CanvasContext);

    const navigate = useNavigate();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const { setCurrentSituationId } = useScenario();

    // Calculate the disabled state for reuse
    const isButtonDisabled = !selectedNodeIds || selectedNodeIds.length < 1;

    // Function to toggle dropdown visibility
    // const toggleDropdown = () => {
    //     // Only toggle if the button is not disabled
    //     if (!isButtonDisabled) {
    //         setIsDropdownOpen(!isDropdownOpen);
    //     }
    // };



    useEffect(() => {
        if (isButtonDisabled) {
            setIsDropdownOpen(false);
        }
    }, [isButtonDisabled, isDropdownOpen]);

    const toggleEditSidebar = () => {
        // If mobile and the edit sidebar is currently open (true)
        if (isMobile && renderEditSidebar) {
            // Close the sidebar and run onCanvasClick()
            setRenderEditSidebar(false);
            onCanvasClick();
        } else {
            // Otherwise, just toggle the sidebar as usual
            setRenderEditSidebar(!renderEditSidebar);
        }
    };
    


    // const handleCreateGroupClick = () => {
    //     if (!isButtonDisabled) {
    //         createGroup()
    //             .then(async (newGroup) => {
    //                 if (newGroup && newGroup._id) {
    //                     // Step 1: Update each situation to associate it with the new group
    //                     await Promise.all(
    //                         selectedNodeIds.map(async (nodeId) => {
    //                             await updateSituation({ situationGroup: newGroup._id }, nodeId);
    //                         })
    //                     );
    
    //                     // Step 2: Update the color of each selected node to the new group's color
    //                     const updatedNodes = nodes.map(node =>
    //                         selectedNodeIds.includes(node.id)
    //                             ? { ...node, data: { ...node.data, fillColor: newGroup.color } }
    //                             : node
    //                     );
    //                     await updateNodes(updatedNodes);
    
    //                     // Step 3: Select the newly created group and close the dropdown
    //                     onSelectGroup(newGroup._id);
    //                     setIsDropdownOpen(false);
    //                     setRenderEditSidebar(true);
    //                     console.log("Group created, situations associated, and node colors updated.");
    //                 }
    //             })
    //             .catch(err => {
    //                 console.error("Error in handleCreateGroupClick: ", err);
    //             });
    //     }
    // };
    
    
    

    // const handleAddToGroupClick = async (group) => {
    //     if (!isButtonDisabled) {
    //         if (!group._id || selectedNodeIds.length === 0) {
    //             console.error("No group selected or no situations to add.");
    //             return;
    //         }
    
    //         try {
    //             await Promise.all(
    //                 selectedNodeIds.map(async (nodeId) => {
    //                     await updateSituation({ situationGroup: group._id }, nodeId);
    //                 })
    //             );
    
    //             const updatedNodes = nodes.map(node =>
    //                 selectedNodeIds.includes(node.id)
    //                     ? { ...node, data: { ...node.data, fillColor: group.color } }
    //                     : node
    //             );
    //             await updateNodes(updatedNodes);
    
    //             onSelectGroup(group._id);
    //             setIsDropdownOpen(false);
    
    //             console.log("Situations successfully added to the group and node colors updated");
    //         } catch (error) {
    //             console.error("Error adding situations to group: ", error);
    //         }
    //     }
    // };
    
    
    


    /*
    Checks the graph for any errors before publishing.
    If any errors exist, set them and prevent publishing
    If there are no errors, proceed with publishing
    */
    const handlePublish = () => {
        const errors = checkValidity(nodes, edges);
    
        if (errors.length > 0) {
            setGraphErrors(errors);
        } else {
            const situationIds = nodes.map(node => node.id);
            const data = {
                situations: situationIds,
                isPublished: true,
                isEditing: false
            };
    
            const currentTime = Date.now();
    
            if (scenario.isPublished) {
                data.lastUpdated = currentTime;
            } else {
                data.publishedAt = currentTime;
                data.lastUpdated = currentTime;
            }
    
            axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/scenarios/${scenario?._id}`, data)
                .then(response => {
                    navigate('/home');
                })
                .catch(error => {
                    console.error(`Error publishing: ${error}`);
                });
        }
    };
    
    


    const handleClickPreview = () => {
        setCurrentSituationId(scenario._id, null);
        navigate(`/create/${scenario._id}/preview`);
    }
    
    
    
    return (
        <>
            <div className={styles.container}>
                {/* Left Section */}
                <div className={styles.leftSection}>
                    <div className={styles.logoContainer}>
                        <img
                            className={styles.logo}
                            src={require('../../../images/logo_teal_background.png')}
                            alt="Home"
                            onClick={() => navigate('/home')}
                        />
                    </div>
                </div>
    
                {/* Middle Section */}
                <div className={styles.middleSection}>
                    {/* <div
                        className={styles.item}
                        onClick={toggleDropdown}
                        aria-disabled={isButtonDisabled}
                        data-tooltip-id="tooltip"
                        data-tooltip-content="Add to Group"
                    >
                        <FaUserPlus />
                    </div> */}
    
                    {/* Dropdown for Add to Group */}
                    {/* {isDropdownOpen && (
                        <div className={styles.dropdown}>
                            <div
                                onClick={handleCreateGroupClick}
                                className={`${styles.dropdownItem} ${styles.createGroupItem}`}
                                data-type="create"
                                data-tooltip-id="tooltip"
                                data-tooltip-content="Create New Group"
                            >
                                <FaPlus className={styles.plusIcon} />
                                <div className={styles.newGroupText}>New Group</div>
                            </div>
                            {groups.map((group, index) => (
                                <div
                                    key={index}
                                    className={styles.dropdownItem}
                                    style={{
                                        borderColor: group.color,
                                    }}
                                    onClick={() => handleAddToGroupClick(group)}
                                    data-type="group"
                                    data-tooltip-id="tooltip"
                                    data-tooltip-content={group.title || 'Untitled Group'}
                                >
                                    {group.title || 'Untitled Group'}
                                </div>
                            ))}
                        </div>
                    )} */}
                    <div className={styles.titleContainer}>
                        <h1>{scenario?.title || 'Untitled Scenario'}</h1>
                    </div>
                </div>
    
                {/* Right Section */}
                <div className={styles.rightSection}>
                    <div
                        className={`${styles.item} ${renderEditSidebar ? styles.itemActive : ''}`}
                        onClick={toggleEditSidebar}
                        data-tooltip-id="tooltip"
                        data-tooltip-content="Edit Tools"
                    >
                        <FaEdit />
                    </div>
                </div>

            </div>
    
        </>
    );
    
}

export default TopBar;
