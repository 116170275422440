import React, { useContext } from 'react';
import CanvasContext from '../../../context/CanvasContext';
import styles from './styles/EditSidebarTabs.module.css';

const EditSidebarTabs = ({ editType }) => {
    const { activeEditTab, setActiveEditTab } = useContext(CanvasContext);

    const handleTabClick = (tab) => {
        setActiveEditTab(tab);
    };

    const getTabLabel = (tab) => {
        switch (tab) {
            case '1':
                return 'Content';
            case '2':
                if (editType === 'situation') return 'Choices';
                if (editType === 'choice') return 'Link';
                if (editType === 'group') return 'Situations';
                return '';
            case '3':
                return 'Settings';
            case '4':
                return 'Keys';
            default:
                return '';
        }
    };

    const tabs = ['1', '2', '3'];

    // Conditionally add 'Keys' tab if editType is 'situation' or 'choice'
    if (editType === 'situation' || editType === 'choice') {
        tabs.push('4');
    }

    return (
        <div className={styles.tabsContainer}>
            {tabs.map((tab) => (
                <div
                    key={tab}
                    className={`${styles.tab} ${
                        activeEditTab === tab ? styles.activeTab : ''
                    }`}
                    onClick={() => handleTabClick(tab)}
                >
                    {getTabLabel(tab)}
                </div>
            ))}
        </div>
    );
};

export default EditSidebarTabs;
