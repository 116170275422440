// TransferModal.js
import React, { useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import styles from './styles/TransferModal.module.css';

function TransferModal({ handleCloseTransfer, scenario }) {
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [currentStep, setCurrentStep] = useState('search');
    const [isLoading, setIsLoading] = useState(false);

    const handleInputChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleSearch = async () => {
        if (searchQuery.trim() === '') return;

        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/search`, {
                params: { query: searchQuery, type: 'user' },
            });
            setSearchResults(response.data.results);
        } catch (error) {
            console.error('Error searching users:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleUserSelect = (user) => {
        setSelectedUser(user);
    };

    const handleTransferClick = () => {
        setCurrentStep('confirm');
    };

    const handleConfirmYes = () => {
        // Placeholder for transfer function
        alert(`Transferred scenario to ${selectedUser.username}`);
        handleCloseTransfer();
    };

    const handleConfirmNo = () => {
        setCurrentStep('search');
        setSearchQuery('');
        setSearchResults([]);
        setSelectedUser(null);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') handleSearch();
    };

    return (
        <div className={styles.overlay}>
            <div className={styles.modal}>
                <div className={styles.exitModal} onClick={handleCloseTransfer}>
                    <FontAwesomeIcon icon={faTimes} />
                </div>
                {currentStep === 'search' && (
                    <>
                        <div className={styles.header}>Transfer Scenario Ownership</div>
                        <div className={styles.searchContainer}>
                            <input
                                type="text"
                                placeholder="Enter username"
                                value={searchQuery}
                                onChange={handleInputChange}
                                onKeyDown={handleKeyDown}
                            />
                            <button className={styles.searchButton} onClick={handleSearch}>
                                <FontAwesomeIcon icon={faSearch} />
                            </button>
                        </div>
                        {isLoading && <p>Loading...</p>}
                        {searchResults.length > 0 && (
                            <div className={styles.searchResults}>
                                {searchResults.map((user) => (
                                    <div
                                        key={user._id}
                                        className={`${styles.userCard} ${selectedUser && selectedUser._id === user._id ? styles.selected : ''}`}
                                        onClick={() => handleUserSelect(user)}
                                    >
                                        <img
                                            src={user.profilePicture || '/default-profile.png'}
                                            alt="Profile"
                                            className={styles.profilePicture}
                                        />
                                        <span>{user.username}</span>
                                    </div>
                                ))}
                            </div>
                        )}
                        {selectedUser && (
                            <button className={styles.transferButton} onClick={handleTransferClick}>
                                Transfer
                            </button>
                        )}
                    </>
                )}
                {currentStep === 'confirm' && (
                    <div className={styles.confirmContainer}>
                        <p>
                            Are you sure you want to transfer ownership of this scenario to{' '}
                            <strong>{selectedUser.username}</strong>?
                        </p>
                        <div className={styles.confirmButtons}>
                            <button className={styles.yesButton} onClick={handleConfirmYes}>
                                Yes
                            </button>
                            <button className={styles.noButton} onClick={handleConfirmNo}>
                                No
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default TransferModal;
