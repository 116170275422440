import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import styles from './styles/LoginRegister.module.css';

function VerifyEmail() {
    const [isVerified, setIsVerified] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');

    useEffect(() => {
        async function verifyEmail() {
            if (token) {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/users/verify-email?token=${token}`);
                    setIsVerified(true);
                    // Removed navigation to home with login modal open
                } catch (error) {
                    console.error(error);
                }
            }
        }
        verifyEmail();
    }, [token, navigate]);

    const handleLoginNavigation = () => {
        if (location.state?.acceptInvite) {
            navigate('/', {
                state: {
                    showModal: true,
                    authModalState: 'login',
                    acceptInvite: true,
                },
            });
        } else {
            navigate('/', {
                state: {
                    showModal: true,
                    authModalState: 'login',
                },
            });
        }
    };

    return (
        <div className={styles.container}>
            {isVerified ? (
                <p>Your account is now verified! <br/>
                    <span 
                        onClick={handleLoginNavigation} 
                        className={styles.linkSpan}
                    >
                        Click Here 
                    </span> 
                        to log in.
                </p>
            ) : (
                <p>Please check your email for a verification link. If you do not see the email in your inbox, make sure to check your spam folder.</p>
            )}
        </div>
    );
}

export default VerifyEmail;
