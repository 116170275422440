// ScenarioCarousel.js
import React from 'react';
import Masonry from 'react-masonry-css';
import ScenarioCard from './ScenarioCard';
import styles from './styles/ScenarioCarousel.module.css';

const ScenarioCarousel = ({ scenariosList }) => {
    // Calculate the maximum number of columns based on the number of items
    const maxColumns = Math.min(scenariosList.length, 5); // 5 is your default max columns

    const breakpointColumnsObj = {
        default: maxColumns,
        1400: Math.min(maxColumns, 4),
        1100: Math.min(maxColumns, 3),
        800: Math.min(maxColumns, 2),
        500: 1
    };

    return (
        <Masonry
            breakpointCols={breakpointColumnsObj}
            className={styles.myMasonryGrid}
            columnClassName={styles.myMasonryGridColumn}
        >
            {scenariosList.map((scenario) => (
                <ScenarioCard 
                    key={scenario._id} 
                    scenario={scenario}
                />
            ))}
        </Masonry>
    );
};

export default ScenarioCarousel;
