import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from "./styles/ClassroomTabs.module.css";
import { ClassroomContext } from '../../context/ClassroomContext';

const ClassroomTabs = () => {
    const { classroom, isTeacher } = useContext(ClassroomContext);
    const [activeTab, setActiveTab] = useState('');
    const location = useLocation();

    const tabs = [
        { path: 'a', label: 'Announcements' },
        { path: 'f', label: 'Folders' },
        { path: 'p', label: 'People' },
        { path: 'i', label: 'About' },
        ...(isTeacher ? [{ path: 'l', label: 'Logs' }, { path: 'settings', label: 'Settings' }] : [])
    ];

    useEffect(() => {
        const currentPath = location.pathname;
        const active = tabs.find(tab => currentPath.includes(`/${tab.path}`))?.path || '';
        setActiveTab(active);
    }, [location, tabs]);

    return (
        <div className={styles.tabsContainer}>
            {tabs.map(tab => (
                <Link
                    key={tab.path}
                    to={`/my-classrooms/${classroom?._id}/${tab.path}`}
                    className={`${styles.tab} ${activeTab === tab.path ? styles.active : ''}`}
                >
                    {tab.label}
                </Link>
            ))}
        </div>
    );
};

export default ClassroomTabs;
