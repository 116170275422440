import React, { useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import styles from './styles/LoginRegister.module.css';

function Register({ setShowModal, setAuthModalState, acceptInvite, setAcceptInvite }) {
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [passwordVerify, setPasswordVerify] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const location = useLocation();

    async function register(e) {
        e.preventDefault();
        try {
            const registerData = {
                email: email.trim(),
                username: username.trim(),
                password,
                passwordVerify,
                inviteToken: location.state?.token // Pass invite token
            };
            await axios.post(`${process.env.REACT_APP_API_URL_LOCAL}/api/users/`, registerData, { withCredentials: true });
            setAuthModalState('verify email');
        } catch (error) {
            if (error.response) {
                setErrorMessage(error.response.data.errorMessage);
            } else {
                console.error(error);
            }
        }
    }

    const handleClickLogInHere = () => {
        setAuthModalState('login');
    }

    const handleClickContinueAsGuest = () => {
        setShowModal(false);
    }

    return (
        <div className={styles.container}>
            <img
                className={styles.logo}
                src={require('../../images/logo_teal_background.png')}
                alt="Home"
            />
            <h1 className={styles.h1}>Create an Account</h1>
            <form onSubmit={register} className={styles.form}>
                <input
                    type="email"
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    className={styles.input}
                />
                <input
                    placeholder="Username"
                    onChange={(e) => setUsername(e.target.value)}
                    value={username}
                    className={styles.input}
                />
                <input
                    type="password"
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    className={styles.input}
                />
                <input
                    type="password"
                    placeholder="Verify your password"
                    onChange={(e) => setPasswordVerify(e.target.value)}
                    value={passwordVerify}
                    className={styles.input}
                />
                {errorMessage && <div className={styles.error}> {errorMessage} </div>}
                <button className={styles.button} type="submit">SIGN UP</button>
            </form>
            <div className={styles.links}>
                Already have an account? <span className={styles.linkSpan} onClick={handleClickLogInHere}>Log In Here</span>
                {!acceptInvite && (
                    <>
                        <br />
                        <span className={styles.orText}>OR</span>
                        <br />
                        <span className={styles.linkSpan} onClick={handleClickContinueAsGuest}>Continue as a guest</span>
                    </>
                )}
            </div>
        </div>
    );
}

export default Register;
