import React, { useState } from 'react';
import styles from './styles/ViewTableOfContents.module.css';
import { FaArrowLeft, FaChevronDown, FaChevronUp } from 'react-icons/fa';

const ViewTableOfContents = ({ tableOfContents, currentSituationId, setCurrentSituationId, toggleToC }) => {
    // State to track expanded sections
    const [expandedSections, setExpandedSections] = useState(
        tableOfContents.contents.map(content =>
            content.sections ? content.sections.map(() => true) : []
        )
    );

    // Handle section toggle
    const handleSectionToggle = (contentIndex, sectionIndex) => {
        setExpandedSections(prevState => {
            const newState = prevState.map((contentSections, cIndex) =>
                cIndex === contentIndex
                    ? contentSections.map((isExpanded, sIndex) =>
                        sIndex === sectionIndex ? !isExpanded : isExpanded
                    )
                    : contentSections
            );
            return newState;
        });
    };

    const handleItemClick = (situationId) => {
        setCurrentSituationId(situationId);
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <FaArrowLeft className={styles.backButton} onClick={toggleToC} />
                <div className={styles.tocTitle}>Table of Contents</div>
            </div>
            <div className={styles.tocContents}>
                {tableOfContents.contents.map((content, contentIndex) => (
                    <div key={contentIndex} className={styles.tocContent}>
                        {content.sections && content.sections.map((section, sectionIndex) => (
                            <div key={sectionIndex} className={styles.tocSection}>
                                {/* Section label with toggle */}
                                <div 
                                    className={styles.tocSectionLabel} 
                                    onClick={() => handleSectionToggle(contentIndex, sectionIndex)}
                                >
                                    {section.label || 'Untitled'}
                                    {expandedSections[contentIndex][sectionIndex] ? (
                                        <FaChevronUp className={styles.chevronIcon} />
                                    ) : (
                                        <FaChevronDown className={styles.chevronIcon} />
                                    )}
                                </div>
                                {/* Conditionally render items based on expanded state */}
                                {expandedSections[contentIndex][sectionIndex] && (
                                    <div className={styles.tocItemsList}>
                                        {section.items && section.items.map((item, itemIndex) => (
                                            <div 
                                                key={itemIndex} 
                                                className={`${styles.tocItem} ${item.situation === currentSituationId ? styles.selectedItem : ''}`} 
                                                onClick={() => handleItemClick(item.situation)}
                                            >
                                                {item.label || 'Untitled'}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ViewTableOfContents;
