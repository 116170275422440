import React from "react";


const LoggingOut = () => {


    return (
        <div>
            <p>Logging Out...</p>
        </div>
    )
}

export default LoggingOut