import React from 'react';
import Switch from 'react-switch';

const StyledSwitch = ({ checked, disabled, onChange }) => {
    return (
        <Switch
            onColor="#008080"
            onHandleColor="#A9A9A9"
            handleDiameter={30}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={20}
            width={48}
            checked={checked}
            onChange={onChange}
            disabled={disabled}
        />
    );
};

export default StyledSwitch;
