import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; 
import styles from './styles/Feedback.module.css';
import { AiOutlineClose } from 'react-icons/ai';

const Feedback = ({ userId }) => {
    const [sentFeedback, setSentFeedback] = useState([]);
    const [receivedFeedback, setReceivedFeedback] = useState([]);
    const [messageTexts, setMessageTexts] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        if (userId) {
            axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/users/${userId}/feedback`)
                .then(response => {
                    setSentFeedback(response.data.sentFeedback);
                    setReceivedFeedback(response.data.receivedFeedback);
                })
                .catch(error => console.error('Error fetching feedback:', error));
        }
    }, [userId]);

    const updateMessageText = (feedbackId, text) => {
        setMessageTexts({ ...messageTexts, [feedbackId]: text });
    };

    const sendMessage = (feedbackId) => {
        const messageText = messageTexts[feedbackId] || '';
        if (!messageText.trim()) return;

        const messageData = { sender: userId, text: messageText };

        axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/feedback/${feedbackId}`, messageData)
            .then(response => {
                const updatedFeedback = response.data;
                setSentFeedback(feedback => feedback.map(fb => fb._id === feedbackId ? updatedFeedback : fb));
                setReceivedFeedback(feedback => feedback.map(fb => fb._id === feedbackId ? updatedFeedback : fb));
                updateMessageText(feedbackId, '');
                const targetUserId = response.data.sender === userId ? response.data.recipient : response.data.sender;
                axios.post(`${process.env.REACT_APP_API_URL_LOCAL}/api/notifications/sendMessageNotification/${targetUserId}/${feedbackId}`, { messageSenderId: userId, messageText })
                    .catch(error => console.error('Error sending notification:', error));
            })
            .catch(error => console.error('Error sending message:', error));
    };

    const handleCloseFeedback = (feedback, type) => {
        if (feedback.isClosed) {
            axios.delete(`${process.env.REACT_APP_API_URL_LOCAL}/api/feedback/${feedback._id}`, { type })
                .then(() => {
                    if (type === 'sent') {
                        setSentFeedback(fb => fb.filter(f => f._id !== feedback._id));
                    } else if (type === 'received') {
                        setReceivedFeedback(fb => fb.filter(f => f._id !== feedback._id));
                    }
                })
                .catch(error => console.error('Error deleting feedback:', error));
        } else {
            axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/feedback/closeFeedback/${feedback._id}`, { type })
                .then(() => {
                    if (type === 'sent') {
                        setSentFeedback(fb => fb.filter(f => f._id !== feedback._id));
                    } else if (type === 'received') {
                        setReceivedFeedback(fb => fb.filter(f => f._id !== feedback._id));
                    }
                })
                .catch(error => console.error('Error closing feedback:', error));
        }
    };

    const handleClickSituationLink = (item, navigate) => {
        if (item?.scenarioOrigin && item?.situationOrigin) {
            navigate(`/view/${item.scenarioOrigin._id}`, {
                state: {
                    hasAccess: true,
                    currentSituationId: item.situationOrigin._id,
                    fromFeedback: true,
                }
            });
        }
    };
    

    const renderFeedbackPost = (item, index, type) => (
        <div key={index} className={styles.feedbackCard}>
            <h3 className={styles.feedbackName}>
                {type === 'sent' ? `To ${item.recipient.username}` : `From ${item.sender?.username || 'Anonymous'}`}
            </h3>
            <p className={styles.feedbackDate}>
                {new Date(item.createdAt).toLocaleString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit'
                })}
            </p>
            <p>Scenario: {item?.scenarioOrigin?.title || "No Origin Found"}</p>
            <p 
                className={item?.situationOrigin?.title ? styles.situationLink : styles.situationLinkInactive}
                onClick={item?.situationOrigin?.title ? () => handleClickSituationLink(item, navigate) : null}
            >
                Situation: {item?.situationOrigin?.title || "No Origin Found"}
            </p>
            <p className={styles.feedbackText}>{item.text}</p>
            <div className={styles.feedbackMessages}>
                {item.messages.map((msg, idx) => (
                    <div key={idx} className={styles.message}>
                        <span className={styles.messageSender}>{msg.sender?.username || 'Anonymous'}: </span>
                        <span className={styles.messageText}>{msg.text}</span>
                    </div>
                ))}
            </div>
            {item.sender && !item.isClosed && (
                <>
                    <textarea 
                        className={styles.messageInput}
                        value={messageTexts[item._id] || ''}
                        onChange={(e) => updateMessageText(item._id, e.target.value)}
                        placeholder="Write a message..."
                    />
                    <button className={styles.sendMessageButton} onClick={() => sendMessage(item._id)}>Send Message</button>
                </>
            )}
            <AiOutlineClose className={styles.deleteFeedback} onClick={() => handleCloseFeedback(item, type)} />
        </div>
    );

    return (
        <div className={styles.container}>
            <div className={styles.receivedFeedback}>
                <h2>Received Feedback</h2>
                {[...receivedFeedback].reverse().map((item, index) => renderFeedbackPost(item, index, 'received'))}
            </div>
            <div className={styles.sentFeedback}>
                <h2>Sent Feedback</h2>
                {[...sentFeedback].reverse().map((item, index) => renderFeedbackPost(item, index, 'sent'))}
            </div>
        </div>
    );
};

export default Feedback;
