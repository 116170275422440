import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './styles/EditClassroomModal.module.css';

const EditClassroomModal = ({ isOpen, onClose, classroom, setClassrooms }) => {
    const [classroomName, setClassroomName] = useState(classroom?.name || '');
    const [image, setImage] = useState(null); // Stores the new image file
    const [imagePreview, setImagePreview] = useState(null); // Stores the preview URL for the new image
    const [initialImage, setInitialImage] = useState(classroom?.image || null); // Stores the existing image URL
    const [error, setError] = useState(null);
    const [closing, setClosing] = useState(false); // State to manage fade-out animation

    useEffect(() => {
        if (classroom) {
            setClassroomName(classroom.name);
            setInitialImage(classroom.image || null); // Set initial image from classroom data
        }
    }, [classroom]);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        setImage(file);
        setImagePreview(URL.createObjectURL(file)); // Preview the new image
    };

    const handleEdit = async () => {
        if (!classroomName.trim()) {
            setError("Classroom name cannot be empty.");
            return;
        }

        const formData = new FormData();
        formData.append('name', classroomName);
        if (image) {
            formData.append('image', image);
        }

        try {
            await axios.patch(
                `${process.env.REACT_APP_API_URL_LOCAL}/api/classrooms/${classroom._id}`,
                formData
            );

            setClassrooms((prevClassrooms) =>
                prevClassrooms.map((item) =>
                    item._id === classroom._id
                        ? { ...item, name: classroomName, image: imagePreview || item.image }
                        : item
                )
            );
            triggerClose(); // Trigger fade-out on successful save
        } catch (error) {
            setError("Failed to update the classroom.");
            console.error(error);
        }
    };

    const triggerClose = () => {
        setClosing(true); // Start the fade-out animation
        setTimeout(() => {
            onClose(); // Close the modal after the animation completes
            setClosing(false); // Reset the closing state for future use
        }, 300); // Match this timeout with the CSS animation duration (0.3s)
    };

    if (!isOpen && !closing) return null; // Don't render if it's not open and not in the middle of closing

    return (
        <div className={`${styles.modalOverlay} ${closing ? styles.fadeOut : styles.fadeIn}`}>
            <div className={`${styles.modalContent} ${styles.wideModal}`}>
                {/* Modal Header */}
                <div className={styles.modalHeader}>
                    <h2 className={styles.modalTitle}>Edit Classroom</h2>
                </div>

                {/* Modal Body */}
                <div className={styles.modalBody}>
                    <input
                        type="text"
                        className={styles.modalInput}
                        value={classroomName}
                        onChange={(e) => setClassroomName(e.target.value)}
                        placeholder="Classroom Name"
                    />

                    <input type="file" onChange={handleImageUpload} />

                    {/* Image Preview */}
                    <div className={styles.imagePreviewContainer}>
                        {/* Show the new image preview if uploaded, otherwise show the initial image */}
                        {imagePreview ? (
                            <img src={imagePreview} alt="Classroom Preview" className={styles.imagePreview} />
                        ) : (
                            initialImage && (
                                <img src={initialImage} alt="Classroom Preview" className={styles.imagePreview} />
                            )
                        )}
                    </div>

                    {error && <div className={styles.error}>{error}</div>}
                </div>

                {/* Modal Footer */}
                <div className={styles.modalFooter}>
                    <div className={styles.buttonContainer}>
                        <button className={`${styles.buttonBase} ${styles.saveButton}`} onClick={handleEdit}>
                            Save
                        </button>
                        <button className={`${styles.buttonBase} ${styles.cancelButton}`} onClick={triggerClose}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditClassroomModal;
