import React from "react";
import axios from "axios";
import { AuthContextProvider } from "./context/AuthContext";
import { ShowAllProvider } from "./context/ShowAllContext";
import { SearchProvider } from "./context/SearchContext";
import { ThemeProvider } from "./context/ThemeContext";
import { LikedFollowedContextProvider } from "./context/LikedFollowedContext";
import CustomRouter from "./Router";
import './index.css';
import { ScenarioProvider } from "./context/ScenarioContext";
import AutoscrollButton from "./components/AutoscrollButton";

axios.defaults.withCredentials = true;

function App() {
  return (
    <ThemeProvider>
      <AuthContextProvider>
        <ShowAllProvider>
          <SearchProvider>
            <ScenarioProvider>
              <LikedFollowedContextProvider>
                <CustomRouter />
                {/* <AutoscrollButton /> */}
              </LikedFollowedContextProvider>
            </ScenarioProvider>
          </SearchProvider>
        </ShowAllProvider>
      </AuthContextProvider>
    </ThemeProvider>
  );
}

export default App;
