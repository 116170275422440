import React from 'react';
import styles from './styles/ShareModal.module.css';
import { AiOutlineCopy, AiOutlineClose } from 'react-icons/ai';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const ShareModal = ({ handleCloseShare, onCopy, copySuccess, shareUrl }) => {

    return (
        <div className={styles.overlay}>
            <div className={styles.modal}>
                <AiOutlineClose className={styles.exitModal} onClick={handleCloseShare} />
                <div className={styles.header}>Share Scenario</div>
                <div className={styles.body}>
                    <div className={styles.instructions}>
                        <p>Copy this link to share:</p>
                    </div>
                    <div className={styles.shareContainer}>
                        <input
                            type='text'
                            value={shareUrl}
                            readOnly
                            onFocus={(e) => e.target.select()}
                            className={styles.url}
                        />
                        <OverlayTrigger
                            placement='top'
                            overlay={
                                <Tooltip id={`tooltip-top`}>
                                    <strong>Click to copy link</strong>
                                </Tooltip>
                            }
                        >
                            <CopyToClipboard text={shareUrl} onCopy={onCopy}>
                                <AiOutlineCopy className={styles.icon} />
                            </CopyToClipboard>
                        </OverlayTrigger>
                    </div>
                    {copySuccess && <p className={styles.success}>{copySuccess}</p>}
                </div>
            </div>
        </div>
    );
};

export default ShareModal;
