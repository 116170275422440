// ScenarioCard.js
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './styles/ScenarioCard.module.css';
import blankImage from '../../../images/blankimage.png';
import shareImage from '../../../images/share.png';
import viewsImage from '../../../images/eye.png';
import favorite from '../../../images/Favorite.png';
import favoriteOutline from '../../../images/FavoriteOutline.png';
import { calculateTimeSince } from '../../../utils/utils';
import axios from 'axios';
import AuthContext from '../../../context/AuthContext';
import ShareModal from './ShareModal';
import TransferModal from './TransferModal';

const ScenarioCard = ({
    scenario,
    isAuthor,
    onDeleteScenario,
    onCopyScenario,
    searchQuery,
}) => {
    const navigate = useNavigate();
    const scenarioId = scenario._id;
    const { userId } = useContext(AuthContext);

    const [likesCount, setLikesCount] = useState();
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false);
    const [showTransferModal, setShowTransferModal] = useState(false);
    const [copySuccess, setCopySuccess] = useState('');
    const [openedDropdown, setOpenedDropdown] = useState(false);
    const [isHovered, setHovered] = useState(false);
    const [hasBeenLiked, setHasBeenLiked] = useState(false);

    useEffect(() => {
        setLikesCount(scenario.likedBy.length);
        if (userId) {
            setHasBeenLiked(scenario.likedBy.includes(userId));
        }
    }, [scenario.likedBy, userId]);

    const handleClickLikeScenario = async () => {
        if (scenarioId && userId) {
            try {
                await axios.patch(
                    `${process.env.REACT_APP_API_URL_LOCAL}/api/scenarios/likeScenario/${scenarioId}`
                );

                if (hasBeenLiked) {
                    setLikesCount((prev) => prev - 1);
                } else {
                    setLikesCount((prev) => prev + 1);
                }
                setHasBeenLiked(!hasBeenLiked);
            } catch (error) {
                console.error('Error liking scenario: ', error);
            }
        }
    };

    const highlightText = (text, query) => {
        if (!query) {
            return text;
        }
        const parts = text.split(new RegExp(`(${query})`, 'gi'));
        return parts.map((part, index) =>
            part.toLowerCase() === query.toLowerCase() ? (
                <span key={index} className={styles.highlight}>
                    {part}
                </span>
            ) : (
                part
            )
        );
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                openedDropdown &&
                !event.target.closest(`.${styles.optionsDropdown}`) &&
                !event.target.closest(`.${styles.optionsButton}`)
            ) {
                setOpenedDropdown(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [openedDropdown]);

    const handleShare = () => setShowShareModal(true);
    const handleCloseShare = () => setShowShareModal(false);

    const handleOpenTransfer = () => setShowTransferModal(true);
    const handleCloseTransfer = () => setShowTransferModal(false);

    const onCopy = () => {
        setCopySuccess('Copied!');
        setTimeout(() => setCopySuccess(''), 2000);
    };

    const navigateToUser = (username) => {
        if (username) {
            navigate(`/user/${username}`);
        }
    };

    if (!isImageLoaded) {
        return (
            <img
                src={scenario.image ? scenario.image : blankImage}
                className={styles.image}
                style={{ display: 'none' }}
                onLoad={() => setIsImageLoaded(true)}
                alt=""
            />
        );
    }

    const shareUrl = `${window.location.origin}/view/${scenario._id}`;

    const handleTagClick = (tag) => {
        navigate(`/browse/${tag}`);
    };

    return (
        <>
            <div
                className={`${styles.card} ${
                    !scenario.isPublished ? styles.unpublished : ''
                }`}
            >
                {isAuthor && (
                    <button
                        className={styles.optionsButton}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setOpenedDropdown(
                                scenario._id === openedDropdown
                                    ? null
                                    : scenario._id
                            );
                        }}
                    />
                )}
                {openedDropdown === scenario._id && (
                    <div className={styles.optionsDropdown}>
                        <div
                            className={styles.dropdownItem}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                navigate(`/create/${scenario._id}`);
                            }}
                        >
                            Edit
                        </div>
                        <div
                            className={styles.dropdownItem}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                onCopyScenario(e, scenario._id);
                                setOpenedDropdown(false);
                            }}
                        >
                            Copy
                        </div>
                        <div
                            className={styles.dropdownItem}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                onDeleteScenario(e, scenario._id);
                                setOpenedDropdown(false);
                            }}
                        >
                            Delete
                        </div>
                        {/* Uncomment if Transfer is needed */}
                        {/* <div
                            className={styles.dropdownItem}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                handleOpenTransfer();
                                setOpenedDropdown(false);
                            }}
                        >
                            Transfer
                        </div> */}
                    </div>
                )}
                {!scenario.isPublished ? (
                    <div className={styles.tooltipContainer}>
                        <img
                            src={
                                scenario.image ? scenario.image : blankImage
                            }
                            alt="Scenario"
                            className={styles.image}
                        />
                        <span className={styles.exclamationIcon}>!</span>
                        <div className={styles.tooltipText}>
                            This Scenario is not published
                        </div>
                    </div>
                ) : (
                    <img
                        src={scenario.image ? scenario.image : blankImage}
                        alt="Scenario"
                        className={styles.image}
                        onClick={() => navigate(`/view/${scenario._id}`)}
                        style={{ cursor: 'pointer' }}
                    />
                )}
                <div className={styles.body}>
                    <div className={styles.titleContainer}>
                        <div
                            className={`${styles.title} ${
                                isHovered ? styles.hovered : ''
                            }`}
                            onMouseEnter={() => setHovered(true)}
                            onMouseLeave={() => setHovered(false)}
                            title={scenario.title}
                            onClick={() => {
                                if (scenario.isPublished) {
                                    navigate(`/view/${scenario._id}`);
                                }
                            }}
                        >
                            {highlightText(
                                scenario.title || 'Untitled',
                                searchQuery
                            )}
                        </div>
                        <img
                            src={shareImage}
                            className={styles.share}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                handleShare();
                            }}
                        />
                    </div>
                    <div className={styles.userDateContainer}>
                        <div className={styles.username}>
                            <a
                                href={`/user/${scenario.creator?.username}`}
                                onClick={(event) => {
                                    event.preventDefault();
                                    navigateToUser(scenario.creator?.username);
                                }}
                            >
                                {scenario.creator?.username}
                            </a>
                        </div>
                        <div className={styles.date}>
                            {calculateTimeSince(scenario.createdAt)} ago
                        </div>
                    </div>
                    <div className={styles.viewsLikes}>
                        <div className={styles.like}>
                            {userId ? (
                                hasBeenLiked ? (
                                    <img
                                        src={favorite}
                                        className={styles.likesImage}
                                        onClick={handleClickLikeScenario}
                                    />
                                ) : (
                                    <img
                                        src={favoriteOutline}
                                        className={styles.likesImage}
                                        onClick={handleClickLikeScenario}
                                    />
                                )
                            ) : (
                                <img src={favorite} className={styles.likesImage} />
                            )}
                            <div className={styles.likeCount}>
                                {likesCount}
                            </div>
                        </div>
                        <div className={styles.views}>
                            <img src={viewsImage} className={styles.viewsImage} />
                            {scenario.views}
                        </div>
                    </div>
                </div>

                {scenario.tags && (
                    <div className={styles.tagSpan}>
                        {scenario.tags.map((tag, index) => (
                            <div
                                key={index}
                                className={styles.tagDiv}
                                onClick={() => handleTagClick(tag)}
                            >
                                <div className={styles.tagText}>
                                    {highlightText(tag, searchQuery)}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>

            {showShareModal && (
                <ShareModal
                    handleCloseShare={handleCloseShare}
                    onCopy={onCopy}
                    copySuccess={copySuccess}
                    shareUrl={shareUrl}
                />
            )}
            {showTransferModal && (
                <TransferModal
                    handleCloseTransfer={handleCloseTransfer}
                    scenario={scenario}
                />
            )}
        </>
    );
};

export default ScenarioCard;
