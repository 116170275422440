// ScenarioTitlePageTags.js
import React, { useState, useCallback, useRef } from 'react';
import styles from './styles/ScenarioTitlePageTags.module.css';

const ScenarioTitlePageTags = ({ tags, setTags }) => {
    const [inputValue, setInputValue] = useState('');
    const inputRef = useRef(null);

    const handleRemoveTag = useCallback(
        (tagToRemove) => {
            setTags((prevTags) => prevTags.filter((tag) => tag !== tagToRemove));
        },
        [setTags]
    );

    const handleAddTag = useCallback(
        (event) => {
            const value = event.target.value.trim().toLowerCase();
            if ((event.key === ' ' || event.key === ',') && value && !tags.includes(value)) {
                setTags((prevTags) => (prevTags.length < 6 ? [...prevTags, value] : prevTags));
                setInputValue('');
                event.preventDefault();
            }
            if (event.key === 'Backspace' && !inputValue) {
                setTags((prevTags) => prevTags.slice(0, -1));
            }
        },
        [tags, inputValue, setTags]
    );

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>
                <h2>Tags</h2>
            </div>
            <div className={styles.content}>
                <p>Press Space or Comma after each tag</p>
                <ul className={styles.tagList}>
                    {tags.map((tag, index) => (
                        <li key={index} className={styles.tagItem}>
                            {tag}
                            <i onClick={() => handleRemoveTag(tag)} className={styles.removeIcon}>
                                &times;
                            </i>
                        </li>
                    ))}
                    <li className={styles.inputItem}>
                        <input
                            type="text"
                            value={inputValue}
                            onChange={handleInputChange}
                            spellCheck="false"
                            ref={inputRef}
                            onKeyDown={handleAddTag}
                            className={styles.input}
                        />
                    </li>
                </ul>
            </div>
            <div className={styles.details}>
                <p>
                    <span>{6 - tags.length}</span> tags remaining
                </p>
                <button type="button" onClick={() => setTags([])} className={styles.clearButton}>
                    Clear
                </button>
            </div>
        </div>
    );
};

export default React.memo(ScenarioTitlePageTags);
