import React, { useState } from 'react';
import styles from './styles/FreeResponseBody.module.css';
import { stripHtml } from '../../../utils/utils';
import { useScenario } from '../../../context/ScenarioContext';
import HintModal from './HintModal'; // Import HintModal

const FreeResponseBody = ({ situation, setCurrentSituationId, isScenarioLogged, preview }) => {
    const [userResponse, setUserResponse] = useState('');
    const [showHintModal, setShowHintModal] = useState(false);
    const [hintText, setHintText] = useState('');
    const { addDecisionToLog } = useScenario();

    const handleInputChange = (event) => {
        setUserResponse(event.target.value);
    };

    const handleSubmit = async () => {
        const strippedUserResponse = stripHtml(userResponse).trim().toLowerCase();
        
        const matchingChoice = situation.choices.find(choice => 
            stripHtml(choice.text).trim().toLowerCase() === strippedUserResponse
        );

        if (matchingChoice) {
            if (isScenarioLogged && !preview) {
                await addDecisionToLog({ 
                    type: 'choice', 
                    id: matchingChoice._id, 
                    title: matchingChoice.title 
                });
            }
            if (matchingChoice.hasHint) {
                setHintText(matchingChoice.hint);
                setShowHintModal(true);
            } else {
                setCurrentSituationId(matchingChoice.nextSituation._id);
                setUserResponse('');
            }
        } else {
            if (isScenarioLogged && !preview) {
                await addDecisionToLog({ 
                    type: 'input', 
                    userInput: strippedUserResponse 
                });
            }
            setHintText('Try Again!');
            setShowHintModal(true);
        }
    };

    const closeModal = () => {
        setShowHintModal(false);
        setHintText('');
    };

    return (
        <div className={styles.container}>
            <textarea 
                className={styles.textarea}
                value={userResponse}
                onChange={handleInputChange}
                placeholder="Enter your response here..."
            />
            <button className={styles.submitButton} onClick={handleSubmit}>
                Submit
            </button>

            {showHintModal && (
                <HintModal hintText={hintText} closeModal={closeModal} />
            )}
        </div>
    );
};

export default FreeResponseBody;
