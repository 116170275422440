import React, { useContext, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import styles from './styles/ChoicesList.module.css';
import CanvasContext from '../../../context/CanvasContext';

const ChoicesList = ({ choices, setChoices }) => {
    
    const { getChoice, updateSituation, setActiveEditTab } = useContext(CanvasContext);

    const [loading, setLoading] = useState(false);

    const handleDragEnd = (result) => {
        if (!result.destination) return;
    
        const reorderedChoices = Array.from(choices);
        const [removed] = reorderedChoices.splice(result.source.index, 1);
        reorderedChoices.splice(result.destination.index, 0, removed);
    
        setChoices(reorderedChoices);
        console.log(reorderedChoices); // Log to verify the state is updated
    
        // Persist the new order to the backend
        updateSituation({ choices: reorderedChoices });
    };

    const handleChoiceClick = async (choiceId) => {
        await getChoice(choiceId); // Call the getChoice function from context with the choice ID
        setActiveEditTab('1');

    };

    if (loading) {
        return (
            <div>Loading...</div>
        )
    } else {
        return (
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="droppable-choices">
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className={styles.choicesContainer}
                        >
                            {choices.map((choice, index) => (
                                <Draggable key={choice?._id} draggableId={choice?._id} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className={styles.choiceItem}
                                            onClick={() => handleChoiceClick(choice?._id)}  // Add the onClick handler
                                        >
                                            <div className={styles.choiceTitle}>
                                                {choice.title || 'Untitled'}
                                            </div>
                                            <div className={styles.nextSituationTitle}>
                                                {choice.nextSituation?.title || 'Untitled Situation'}
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }

};

export default ChoicesList;
