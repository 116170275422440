import React, { useState, useContext, useEffect } from 'react';
import styles from './styles/CreateKey.module.css';
import axios from 'axios';
import { FaEdit, FaTrash, FaMinus, FaPlus, FaSave } from 'react-icons/fa';
import CanvasContext from '../../../context/CanvasContext';


const CreateKey = () => {

    const { scenario, keys, setKeys, situations, setSituations } = useContext(CanvasContext);
    const [mode, setMode] = useState('view'); // 'view', 'create', 'edit'
    const [selectedKeyId, setSelectedKeyId] = useState(null);
    const [keyFormData, setKeyFormData] = useState({ name: '', description: '' });
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [errors, setErrors] = useState({});

    const handleCreateKey = async () => {
        // Validate name
        if (!keyFormData.name.trim()) {
            setErrors({ name: 'Key name is required' });
            return;
        }
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL_LOCAL}/api/keys/${scenario._id}`, keyFormData);
            const newKey = response.data;
            setKeys([...keys, newKey]);
            setExpandedKeys([newKey._id]);
            setMode('view');
            setKeyFormData({ name: '', description: '' });
            setErrors({});
        } catch (err) {
            console.error('Error creating key:', err);
        }
    };
    

    const handleEditKey = (keyId) => {
        const keyToEdit = keys.find(k => k._id === keyId);
        setKeyFormData({ name: keyToEdit.name, description: keyToEdit.description });
        setSelectedKeyId(keyId);
        setMode('edit');
    };

    const handleUpdateKey = async () => {
        // Validate name
        if (!keyFormData.name.trim()) {
            setErrors({ name: 'Key name is required' });
            return;
        }
    
        try {
            const response = await axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/keys/${selectedKeyId}`, keyFormData);
            const updatedKey = response.data;
            setKeys(keys.map(k => (k._id === updatedKey._id ? updatedKey : k)));
            setMode('view');
            setSelectedKeyId(null);
            setKeyFormData({ name: '', description: '' });
            setErrors({});
        } catch (err) {
            console.error('Error updating key:', err);
        }
    };

    const handleDeleteKey = async (keyId) => {
        if (!window.confirm('Are you sure you want to delete this key?')) {
            return;
        }

        console.log("key ID: ", keyId);
    
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL_LOCAL}/api/keys/${keyId}`);
    
            // Remove key from frontend state
            setKeys(keys.filter(k => k._id !== keyId));
    
            // Update situations and choices
            const updatedSituations = situations.map(situation => {
                const updatedChoices = situation.choices.map(choice => {
                    if (choice.key === keyId) {
                        choice.key = null;
                        choice.hideIfKey = false;
                        choice.showIfKey = false;
                    }
                    if (choice.grantsKeys && choice.grantsKeys.includes(keyId)) {
                        choice.grantsKeys = choice.grantsKeys.filter(kId => kId !== keyId);
                    }
                    return choice;
                });
                if (situation.grantsKeys && situation.grantsKeys.includes(keyId)) {
                    situation.grantsKeys = situation.grantsKeys.filter(kId => kId !== keyId);
                }
                return { ...situation, choices: updatedChoices };
            });
    
            setSituations(updatedSituations);
        } catch (err) {
            console.error('Error deleting key:', err);
        }
    };

    const toggleExpandKey = (keyId) => {
        if (expandedKeys.includes(keyId)) {
            setExpandedKeys(expandedKeys.filter(id => id !== keyId));
        } else {
            setExpandedKeys([...expandedKeys, keyId]);
        }
    };

    const renderKeyForm = () => (
        <div className={styles.keyForm}>
            <div className={styles.formGroup}>
                <label>
                    Name <span className={styles.required}>*</span>
                </label>
                <input
                    type="text"
                    value={keyFormData.name}
                    onChange={(e) => setKeyFormData({ ...keyFormData, name: e.target.value })}
                    pattern="[A-Za-z0-9 ]+"
                    required
                />
                {errors.name && <span className={styles.error}>{errors.name}</span>}
            </div>
            <div className={styles.formGroup}>
                <label>Description</label>
                <textarea
                    value={keyFormData.description}
                    onChange={(e) => setKeyFormData({ ...keyFormData, description: e.target.value })}
                />
            </div>
            <div className={styles.formActions}>
                {mode === 'create' ? (
                    <>
                        <button onClick={handleCreateKey}>
                            <FaPlus /> Create
                        </button>
                        <button onClick={() => {
                            setMode('view');
                            setKeyFormData({ name: '', description: '' });
                        }}>Cancel</button>
                    </>
                ) : (
                    <>
                        <button onClick={handleUpdateKey}>
                            Save Changes
                        </button>
                        <button onClick={() => {
                            setMode('view');
                            setKeyFormData({ name: '', description: '' });
                        }}>Cancel</button>
                    </>
                )}
            </div>
            {mode === 'edit' && renderKeySections(keys.find(k => k._id === selectedKeyId))}
        </div>
    );
    

    const renderKeySections = (key) => {
        // Find all situations and choices related to the key
        const grantedByChoices = [];
        const grantedBySituations = [];
        const revealsChoices = [];
        const hidesChoices = [];
    
        situations.forEach(situation => {
            if (situation.grantsKeys && situation.grantsKeys.includes(key._id)) {
                grantedBySituations.push(situation);
            }
            situation.choices.forEach(choice => {
                if (choice.grantsKeys && choice.grantsKeys.includes(key._id)) {
                    grantedByChoices.push(choice);
                }
                if (choice.key === key._id) {
                    if (choice.showIfKey) {
                        revealsChoices.push(choice);
                    }
                    if (choice.hideIfKey) {
                        hidesChoices.push(choice);
                    }
                }
            });
        });
    
        return (
            <div className={styles.keySections}>
                <div className={styles.section}>
                    <h4>Granted by</h4>
                    {grantedBySituations.length > 0 || grantedByChoices.length > 0 ? (
                        <ul>
                            {grantedBySituations.map(s => (
                                <li key={s._id}>{s.title}</li>
                            ))}
                            {grantedByChoices.map(c => (
                                <li key={c._id}>{c.title}</li>
                            ))}
                        </ul>
                    ) : (
                        <p>No situations or choices grant this key.</p>
                    )}
                </div>
                <div className={styles.section}>
                    <h4>Reveals these choices</h4>
                    {revealsChoices.length > 0 ? (
                        <ul>
                            {revealsChoices.map(c => (
                                <li key={c._id}>{c.title}</li>
                            ))}
                        </ul>
                    ) : (
                        <p>No choices are revealed by this key.</p>
                    )}
                </div>
                <div className={styles.section}>
                    <h4>Hides these choices</h4>
                    {hidesChoices.length > 0 ? (
                        <ul>
                            {hidesChoices.map(c => (
                                <li key={c._id}>{c.title}</li>
                            ))}
                        </ul>
                    ) : (
                        <p>No choices are hidden by this key.</p>
                    )}
                </div>
            </div>
        );
    };
    

    // Inside your render function or component return statement
    return (
        <div className={styles.createKeyContainer}>
            {mode === 'view' && (
                <>
                    <button className={styles.createButton} onClick={() => setMode('create')}>
                        Create Key
                    </button>
                    <div className={styles.keysList}>
                        {keys.map(key => (
                            <div
                                key={key._id}
                                className={`${styles.keyItem} ${expandedKeys.includes(key._id) ? styles.expanded : ''}`}
                                onClick={() => {
                                    if (!expandedKeys.includes(key._id)) {
                                        toggleExpandKey(key._id);
                                    }
                                }}
                            >
                                <div className={styles.keyHeader}>
                                    <h3>{key.name}</h3>
                                    {!expandedKeys.includes(key._id) && (
                                        <div className={styles.keyActions}>
                                            <FaEdit
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    handleEditKey(key._id);
                                                }}
                                                className={styles.icon}
                                            />
                                            <FaTrash
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    handleDeleteKey(key._id);
                                                }}
                                                className={styles.icon}
                                            />
                                        </div>
                                    )}
                                    {expandedKeys.includes(key._id) && (
                                        <FaMinus
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                toggleExpandKey(key._id);
                                            }}
                                            className={styles.icon}
                                        />
                                    )}
                                </div>
                                {/* Description displayed in both states */}
                                <p
                                    className={`${styles.description} ${
                                        expandedKeys.includes(key._id) ? styles.expandedDescription : styles.collapsedDescription
                                    }`}
                                >
                                    {key.description}
                                </p>
                                {expandedKeys.includes(key._id) && (
                                    <div className={styles.keyContent} onClick={(event) => event.stopPropagation()}>
                                        {renderKeySections(key)}
                                        <div className={styles.keyActionsMiddle}>
                                            <button
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    handleEditKey(key._id);
                                                }}
                                            >
                                                Edit
                                            </button>
                                            <button
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    handleDeleteKey(key._id);
                                                }}
                                            >
                                                Delete
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </>
            )}
            {(mode === 'create' || mode === 'edit') && renderKeyForm()}
        </div>
    );

}

export default CreateKey
