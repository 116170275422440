import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CanvasContext from '../../../context/CanvasContext';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import EditSidebarTabs from './EditSidebarTabs';
import ChoicesList from './ChoicesList';
import StyledSwitch from './StyledSwitch';
import UploadModal from './UploadModal';
import textStyles from './styles/EditSidebarText.module.css';
import settingsStyles from './styles/EditSidebarSettings.module.css';
import keyStyles from './styles/EditSidebarKeys.module.css';
import DeleteModal from './DeleteModal';
import useIsMobile from '../../../utils/useIsMobile';
import MediaPlayer from '../../../utils/MediaPlayer';
import { getMediaSrc } from '../../../utils/utils';

const EditSituation = ({ setRenderEditSidebar }) => {
    // Context imports
    const {
        scenario,
        updateSituation,
        deleteSituation,
        selectedSituation,
        setSelectedSituation,
        updateNodes,
        updateEdges,
        updateChoice,
        selectedNodeId,
        nodes,
        edges,
        activeEditTab,
        keys,
    } = useContext(CanvasContext);

    const navigate = useNavigate();

    // Non-settings states
    const [title, setTitle] = useState(selectedSituation.title || '');
    const [text, setText] = useState(selectedSituation.text || '');
    const [choices, setChoices] = useState(selectedSituation.choices || []);
    const [image, setImage] = useState(selectedSituation.image || null);
    const [audio, setAudio] = useState(selectedSituation.audio || null);
    const [group, setGroup] = useState(selectedSituation.situationGroup || null);
    const [activeSubTab, setActiveSubTab] = useState('text');

    // Modal control
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [uploadType, setUploadType] = useState('');
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    // Settings states
    const [isStart, setIsStart] = useState(selectedSituation.isStart || false);
    const [isEnd, setIsEnd] = useState(selectedSituation.isEnd || false);
    const [isUserSubmitted, setIsUserSubmitted] = useState(selectedSituation.isUserSubmitted || false);
    const [isFreeResponse, setIsFreeResponse] = useState(selectedSituation.isFreeResponse || false);
    const [isLogged, setIsLogged] = useState(selectedSituation.isLogged || false);
    const [autoplay, setAutoplay] = useState(selectedSituation.autoplay || false);

    // Keys states
    const [grantsKeys, setGrantsKeys] = useState(selectedSituation.grantsKeys || []);
    const [isGrantingKey, setIsGrantingKey] = useState(false);

    const isMobile = useIsMobile();

    // Sync state with selectedSituation prop
    useEffect(() => {
        setTitle(selectedSituation.title || '');
        setText(selectedSituation.text || '');
        setIsStart(selectedSituation.isStart || false);
        setIsEnd(selectedSituation.isEnd || false);
        setIsUserSubmitted(selectedSituation.isUserSubmitted || false);
        setIsFreeResponse(selectedSituation.isFreeResponse || false);
        setIsLogged(selectedSituation.isLogged || false);
        setChoices(selectedSituation.choices || []);
        setImage(selectedSituation.image || null);
        setAudio(selectedSituation.audio || null);
        setGroup(selectedSituation.situationGroup || null);
        setGrantsKeys(selectedSituation.grantsKeys || []);
        setAutoplay(selectedSituation.autoplay || false);
    }, [selectedSituation]);

    const openModal = (type) => {
        setUploadType(type);
        setIsModalOpen(true);
    };

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };

    const handleTitleBlur = async () => {
        if (title !== selectedSituation.title) {
            try {
                await updateSituation({ title: title });
                const updatedNodes = nodes.map((node) =>
                    node.id === selectedNodeId ? { ...node, data: { ...node.data, label: title } } : node
                );
                await updateNodes(updatedNodes);
            } catch (error) {
                console.error('Error updating situation or canvas: ', error);
            }
        }
    };

    const handleEditorChange = (data) => {
        setText(data);
    };

    const handleTextBlur = async () => {
        if (text !== selectedSituation.text) {
            try {
                await updateSituation({ text });
            } catch (error) {
                console.error('Error updating situation text: ', error);
            }
        }
    };

    const handlePreviewSituation = () => {
        if (selectedSituation) {
            navigate(`/create/${scenario._id}/preview`, {state : {currentSituationId: selectedSituation?._id}});
        }
    };

    const handleSwitchChange = async (setting, value) => {
        try {
            let updates = { [setting]: value };

            // NAND logic for isStart and isEnd
            if (setting === 'isStart' && value) {
                updates.isEnd = false;
            } else if (setting === 'isEnd' && value) {
                updates.isStart = false;
            }

            // NAND logic for isUserSubmitted and isFreeResponse
            if (setting === 'isUserSubmitted' && value) {
                updates.isFreeResponse = false;
            } else if (setting === 'isFreeResponse' && value) {
                updates.isUserSubmitted = false;
            }


            // Apply local state changes
            if (updates.hasOwnProperty('isStart')) setIsStart(updates.isStart);
            if (updates.hasOwnProperty('isEnd')) setIsEnd(updates.isEnd);
            if (updates.hasOwnProperty('isUserSubmitted')) setIsUserSubmitted(updates.isUserSubmitted);
            if (updates.hasOwnProperty('isFreeResponse')) setIsFreeResponse(updates.isFreeResponse);
            if (updates.hasOwnProperty('isLogged')) setIsLogged(value);
            if (updates.hasOwnProperty('autoplay')) setAutoplay(value);

            await updateSituation(updates); // Delegate the rest to updateSituation in the context
        } catch (error) {
            console.error(`Error updating ${setting}: `, error);
        }
    };

    const handleDeleteSituation = () => {
        setIsDeleteModalOpen(true);  // Open the DeleteModal
    };

    const confirmDeleteSituation = async () => {
        try {
            const situationId = selectedSituation._id;

            const edgesToUpdate = edges.filter((edge) => edge.target === situationId);

            await Promise.all(
                edgesToUpdate.map(async (edge) => {
                    return updateChoice({ nextSituation: edge.source }, edge.id);
                })
            );

            const updatedEdges = edges.map((edge) =>
                edge.target === situationId ? { ...edge, target: edge.source } : edge
            );

            const updatedNodes = nodes.filter((node) => node.id !== situationId);

            await updateEdges(updatedEdges);
            await updateNodes(updatedNodes);

            await deleteSituation(situationId);
            setIsDeleteModalOpen(false);  // Close modal after deletion
            if(isMobile) {
                setRenderEditSidebar(false);
            }
        } catch (error) {
            console.error('Error deleting situation:', error);
        }
    };

    const cancelDeleteSituation = () => {
        setIsDeleteModalOpen(false);  // Close the modal without deleting
    };

     // Function to add a key to grantsKeys
     const handleAddKey = async (keyId) => {
        try {
            const updatedGrantsKeys = [...grantsKeys, keyId];
            const updatedSituation = await updateSituation({ grantsKeys: updatedGrantsKeys });
            setGrantsKeys(updatedSituation.grantsKeys || []);
            setIsGrantingKey(false);
        } catch (error) {
            console.error('Error adding key:', error);
        }
    };

    // Function to remove a key from grantsKeys
    const handleRemoveKey = async (keyId) => {
        try {
            const updatedGrantsKeys = grantsKeys.filter(id => id !== keyId);
            const updatedSituation = await updateSituation({ grantsKeys: updatedGrantsKeys });
            setGrantsKeys(updatedSituation.grantsKeys || []);
        } catch (error) {
            console.error('Error removing key:', error);
        }
    };

    return (
        <>
            <EditSidebarTabs editType="situation" />

            {activeEditTab === '1' && (
                <>
                    {/* Subtabs */}
                    <div className={textStyles.subTabsContainer}>
                        <div
                            className={`${textStyles.subTab} ${
                                activeSubTab === 'text' ? textStyles.activeSubTab : ''
                            }`}
                            onClick={() => setActiveSubTab('text')}
                        >
                            Text
                        </div>
                        <div
                            className={`${textStyles.subTab} ${
                                activeSubTab === 'media' ? textStyles.activeSubTab : ''
                            }`}
                            onClick={() => setActiveSubTab('media')}
                        >
                            Media
                        </div>
                    </div>
                    <div className={textStyles.textContainer}>
                        {/* Subtab Content */}
                        {activeSubTab === 'text' && (
                            <>
                                <input
                                    type="text"
                                    value={title}
                                    onChange={handleTitleChange}
                                    onBlur={handleTitleBlur}
                                    className={textStyles.titleInput}
                                />
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={text}
                                    className={textStyles.ckEditor}
                                    config={{
                                        toolbar: {
                                            items: [
                                                'undo',
                                                'redo',
                                                '|',
                                                'heading',
                                                '|',
                                                'bold',
                                                'italic',
                                                '|',
                                                'link',
                                                'uploadImage',
                                                'blockQuote',
                                                '|',
                                                'bulletedList',
                                                'numberedList',
                                                'outdent',
                                                'indent',
                                            ],
                                            shouldNotGroupWhenFull: true,
                                        },
                                        ckfinder: {
                                            uploadUrl: `${process.env.REACT_APP_API_URL_LOCAL}/api/images/upload`,
                                        },
                                        mediaEmbed: {
                                            elementName: 'iframe',
                                        },
                                    }}
                                    onChange={(event, editor) => {
                                        handleEditorChange(editor.getData());
                                    }}
                                    onBlur={handleTextBlur}
                                />
                                <button className={textStyles.previewButton} onClick={handlePreviewSituation}>Preview Situation</button>
                            </>
                        )}

                        {activeSubTab === 'media' && (
                            <>
                                {image && (
                                    <div className={textStyles.mediaPreview}>
                                        <img src={image} alt="Uploaded" className={textStyles.imagePreview} />
                                    </div>
                                )}

                                <div className={textStyles.buttonGroup}>
                                    <button
                                        onClick={() => openModal('image')}
                                        className={`${textStyles.buttonBase} ${textStyles.uploadButton}`}
                                    >
                                        {image ? 'Change Image' : 'Upload Image'}
                                    </button>
                                    {image && (
                                        <button
                                            onClick={async () => {
                                                const updatedSituation = await updateSituation({ image: null });
                                                setImage(null); // Update local state
                                                setSelectedSituation(updatedSituation); // Update selected situation to the returned situation
                                            }}
                                            className={`${textStyles.buttonBase} ${textStyles.removeButton}`}
                                        >
                                            Remove Image
                                        </button>
                                    )}
                                </div>

                                {audio && (
                                    <div className={textStyles.mediaPreview}>
                                        <MediaPlayer src={getMediaSrc(audio)} autoplay={false} />
                                    </div>
                                )}


                                <div className={textStyles.buttonGroup}>
                                    <button
                                        onClick={() => openModal('audiovideo')}
                                        className={`${textStyles.buttonBase} ${textStyles.uploadButton}`}
                                    >
                                        {audio ? (audio.type && audio.type.startsWith('video') ? 'Change Video' : 'Change Audio') : 'Upload Audio/Video'}
                                    </button>
                                    {audio && (
                                        <button
                                            onClick={async () => {
                                                const updatedSituation = await updateSituation({ audio: null });
                                                setAudio(null); // Update local state
                                                setSelectedSituation(updatedSituation); // Update selected situation to the returned situation
                                            }}
                                            className={`${textStyles.buttonBase} ${textStyles.removeButton}`}
                                        >
                                            {audio.type && audio.type.startsWith('video') ? 'Remove Video' : 'Remove Audio'}
                                        </button>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </>
            )}

            {activeEditTab === '2' && choices && (
                <ChoicesList choices={choices} setChoices={setChoices} />
            )}

            {activeEditTab === '3' && (
                <div className={settingsStyles.settingsContainer}>
                    <div className={settingsStyles.settingItem}>
                        <label>Starting Situation</label>
                        <StyledSwitch
                            onChange={(value) => handleSwitchChange('isStart', value)}
                            checked={isStart}
                            className={settingsStyles.switch}
                        />
                    </div>
                    <div className={settingsStyles.settingItem}>
                        <label>Ending Situation</label>
                        <StyledSwitch
                            onChange={(value) => handleSwitchChange('isEnd', value)}
                            checked={isEnd}
                            disabled={choices && choices.length > 0}
                            className={settingsStyles.switch}
                        />
                    </div>
                    <div className={settingsStyles.settingItem}>
                        <label>Open User Submission</label>
                        <StyledSwitch
                            onChange={(value) => handleSwitchChange('isUserSubmitted', value)}
                            checked={isUserSubmitted}
                            className={settingsStyles.switch}
                        />
                    </div>
                    <div className={settingsStyles.settingItem}>
                        <label>Free-Response Matching</label>
                        <StyledSwitch
                            onChange={(value) => handleSwitchChange('isFreeResponse', value)}
                            checked={isFreeResponse}
                            className={settingsStyles.switch}
                        />
                    </div>
                    <div className={settingsStyles.settingItem}>
                        <label>Track User Decisions</label>
                        <StyledSwitch
                            onChange={(value) => handleSwitchChange('isLogged', value)}
                            checked={isLogged}
                            className={settingsStyles.switch}
                        />
                    </div>
                    <div className={settingsStyles.settingItem}>
                        <label>Autoplay Media</label>
                        <StyledSwitch
                            onChange={(value) => handleSwitchChange('autoplay', value)}
                            checked={autoplay}
                            className={settingsStyles.switch}
                        />
                    </div>
                    {/* Delete Situation Button */}
                    <div className={settingsStyles.deleteContainer}>
                        <button onClick={handleDeleteSituation} className={settingsStyles.deleteButton}>
                            Delete Situation
                        </button>
                    </div>
                </div>
            )}

            {activeEditTab === '4' && (
                <div className={keyStyles.keysContainer}>
                    <h3>Keys Given</h3>
                    {grantsKeys.length > 0 ? (
                        <ul className={keyStyles.grantedKeysList}>
                            {grantsKeys.map(keyId => {
                                const key = keys.find(k => k._id === keyId);
                                return (
                                    <li key={keyId} className={keyStyles.grantedKeyItem}>
                                        <span>{key ? key.name : 'Unknown Key'}</span>
                                        <button
                                            onClick={() => handleRemoveKey(keyId)}
                                            className={keyStyles.removeKeyButton}
                                        >
                                            Remove
                                        </button>
                                    </li>
                                );
                            })}
                        </ul>
                    ) : (
                        <p>No keys are given to the user who reaches this situation.</p>
                    )}
                    {!isGrantingKey && (
                        <button
                            onClick={() => setIsGrantingKey(true)}
                            className={keyStyles.grantKeyButton}
                        >
                            Give Key
                        </button>
                    )}

                    {isGrantingKey && (
                        <div className={keyStyles.availableKeysContainer}>
                            <h4>Select a key to give:</h4>
                            <ul className={keyStyles.availableKeysList}>
                                {keys
                                    .filter(k => !grantsKeys.includes(k._id))
                                    .map(key => (
                                        <li
                                            key={key._id}
                                            className={keyStyles.availableKeyItem}
                                            onClick={() => handleAddKey(key._id)}
                                        >
                                            <div className={keyStyles.keyName}>{key.name}</div>
                                            <div className={keyStyles.keyDescription}>{key.description}</div>
                                        </li>
                                    ))}
                            </ul>
                            <button
                                onClick={() => setIsGrantingKey(false)}
                                className={keyStyles.cancelGrantButton}
                            >
                                Cancel
                            </button>
                        </div>
                    )}
                </div>
            )}


            {/* Modals */}
            {isModalOpen && (
                <UploadModal uploadType={uploadType} setIsModalOpen={setIsModalOpen} />
            )}

            {isDeleteModalOpen && (
                <DeleteModal
                    onConfirm={confirmDeleteSituation}
                    onCancel={cancelDeleteSituation}
                    type={'Situation'}
                />
            )}
        </>
    );
};

export default EditSituation;
