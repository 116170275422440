// MediaPlayer.js

import React from 'react';

const MediaPlayer = ({ src, autoplay, className }) => {
    const isVideo = (file) => {
        if (file.startsWith('data:')) {
            const mimeType = file.split(';')[0].split(':')[1];
            return mimeType && mimeType.startsWith('video/');
        } else {
            const videoExtensions = ['.mp4', '.webm', '.ogg'];
            return videoExtensions.some((ext) => file.toLowerCase().endsWith(ext));
        }
    };

    const getMimeType = (file) => {
        if (file.startsWith('data:')) {
            return file.split(';')[0].split(':')[1];
        } else {
            if (file.toLowerCase().endsWith('.mp4')) return 'video/mp4';
            if (file.toLowerCase().endsWith('.webm')) return 'video/webm';
            if (file.toLowerCase().endsWith('.ogg')) return 'video/ogg';
            if (file.toLowerCase().endsWith('.mp3')) return 'audio/mpeg';
            if (file.toLowerCase().endsWith('.wav')) return 'audio/wav';
            return 'application/octet-stream';
        }
    };

    return (
        <>
            {isVideo(src) ? (
                <video
                    controls
                    autoPlay={autoplay}
                    className={className ? className : 'media'}
                >
                    <source src={src} type={getMimeType(src)} />
                    Your browser does not support the video tag.
                </video>
            ) : (
                <audio
                    controls
                    autoPlay={autoplay}
                    className={className ? className : 'media'}
                >
                    <source src={src} type={getMimeType(src)} />
                    Your browser does not support the audio element.
                </audio>
            )}
        </>
    );
};

export default MediaPlayer;
