import React from 'react';
import { Link } from 'react-router-dom';
import styles from './styles/UserCard.module.css';
import profileIcon from '../../images/profileicon.png';

const UserCard = ({ user }) => {
    return (
        <div className={styles.userCard}>
            <div className={styles.imageContainer}>
                <img 
                    src={user.profilePicture ? user.profilePicture : profileIcon} 
                    alt={`${user.username}'s profile`} 
                    className={styles.profileImage}
                />
            </div>
            <div className={styles.textContainer}>
                <h5>
                    <Link to={`/user/${user.username}`} className={styles.usernameLink}>
                        {user.username}
                    </Link>
                </h5>
            </div>
        </div>
    );
};

export default UserCard;
