import React, { useState } from 'react';
import axios from 'axios';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import styles from './styles/ResetPassword.module.css';

function ResetPassword() {
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [errorMessage, setErrorMessage] = useState(''); 
    const [isReset, setIsReset] = useState(false); 
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const navigate = useNavigate();

    const submitForm = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL_LOCAL}/api/users/reset-password`, { token, password, passwordConfirm });
            setErrorMessage('');
            setIsReset(true); 
        } catch (error) {
            if (error.response) { 
                setErrorMessage(error.response.data.errorMessage); 
            } else {
                console.error(error);
            }
        }
    };

    const handleNavigation = () => {
        navigate('/', {
            state: {
                showModal: true,
                authModalState: 'login',
            },
        });
    };

    return (
        <div className={styles.container}>
            <div className={styles.formWrapper}>
                {isReset ? 
                    (
                        <p className={styles.success}>
                            Your password has been reset! <span onClick={handleNavigation} className={styles.linkSpan} >Click Here</span> to log in.
                        </p>
                    ) : (
                        <form onSubmit={submitForm} className={styles.form}>
                            <label className={styles.label}>
                                New Password:
                            </label>
                            <input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                className={styles.input}
                            />
                            <label className={styles.label}>
                                Confirm New Password:
                            </label>
                            <input
                                type="password"
                                value={passwordConfirm}
                                onChange={(e) => setPasswordConfirm(e.target.value)}
                                required
                                className={styles.input}
                            />
                            {errorMessage && <div className={styles.error}>{errorMessage}</div>}
                            <button className={styles.button} type="submit">Submit</button>
                        </form>
                    )
                }
            </div>
        </div>
    );
}

export default ResetPassword;
