import React from 'react';
import styles from './styles/AuthBody.module.css';
import logo from '../../images/logo_teal_background.png';

const AuthBody = () => {
    return (
        <div className={styles.container}>
            <img className={styles.logo} src={logo} alt="Home" />
            <p>Where Every Choice Counts</p>
        </div>
    );
};

export default AuthBody;
