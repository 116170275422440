// SituationsAndGroups.js
import React, { useContext, useState } from 'react';
import CanvasContext from '../../../context/CanvasContext';
import styles from './styles/SituationsAndGroups.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

const SituationsAndGroups = ({ setRenderEditSidebar }) => {
    const {
        groups,
        situations,
        selectedGroup,
        selectedSituation,
        getGroup,
        getSituation,
        centerNodeInView,
        updateSituation,
        updateNodes,
        nodes,
        createGroup,
        onSelectGroup,
    } = useContext(CanvasContext);

    const [collapsedGroups, setCollapsedGroups] = useState({});
    const [isDragging, setIsDragging] = useState(false);

    const handleCreateGroupClick = () => {
        createGroup()
            .then((newGroup) => {
                if (newGroup && newGroup._id) {
                    onSelectGroup(newGroup._id);
                    setRenderEditSidebar(true);
                    console.log('Group created and selected.');
                }
            })
            .catch((err) => {
                console.error('Error creating group: ', err);
            });
    };

    // Compute groupedSituations dynamically from situations and groups
    const groupedSituations = groups.reduce((acc, group) => {
        acc[group._id] = situations.filter(
            (situation) =>
                situation.situationGroup && situation.situationGroup._id === group._id
        );
        return acc;
    }, {});

    // Compute ungroupedSituations
    const ungroupedSituations = situations.filter(
        (situation) => !situation.situationGroup
    );

    const onDragStart = () => {
        setIsDragging(true);
    };

    const onDragEnd = async (result) => {
        setIsDragging(false);

        const { destination, source, draggableId } = result;

        // If the item was dropped outside a droppable area, do nothing
        if (!destination) {
            return;
        }

        // If the item was dropped back in the same place, do nothing
        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        const situationId = draggableId;
        const newGroupId =
            destination.droppableId === 'ungrouped' ? null : destination.droppableId;

        try {
            // Update the situation's group
            await updateSituation({ situationGroup: newGroupId }, situationId);

            // Update the node's color to match the new group's color
            const situationNode = nodes.find((node) => node.id === situationId);
            if (situationNode) {
                let fillColor = '#ffffff'; // Default color for ungrouped situations
                if (newGroupId) {
                    const group = groups.find((g) => g._id === newGroupId);
                    if (group) {
                        fillColor = group.color;
                    }
                }
                const updatedNodes = nodes.map((node) =>
                    node.id === situationId
                        ? { ...node, data: { ...node.data, fillColor } }
                        : node
                );
                await updateNodes(updatedNodes);
            }
            // No need to update groupedSituations manually; it will be recalculated on next render
        } catch (error) {
            console.error('Error updating situation group: ', error);
        }
    };

    const onDragUpdate = (update) => {
        if (!update.destination) return;

        const { destination } = update;
        const droppableId = destination.droppableId;

        // Expand the group when dragging over its droppable area
        if (droppableId !== 'ungrouped') {
            setCollapsedGroups((prev) => ({
                ...prev,
                [droppableId]: false,
            }));
        }
    };

    return (
        <div className={styles.container}>
            {/* Create Group Button */}
            <button
                className={styles.createGroupButton}
                onClick={handleCreateGroupClick}
            >
                Create Group
            </button>
            <DragDropContext
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                onDragUpdate={onDragUpdate}
            >
                <div className={styles.list}>
                    {groups.map((group) => (
                        <div key={group._id}>
                            {/* Group Title */}
                            <div
                                className={`${styles.groupTitle} ${
                                    selectedGroup?._id === group._id
                                        ? styles.selectedGroup
                                        : ''
                                }`}
                                style={{
                                    color:
                                        group.color === '#ffffff' ? 'black' : group.color,
                                }}
                                onClick={() => getGroup(group._id)}
                                onMouseEnter={() => {
                                    if (isDragging) {
                                        setCollapsedGroups((prev) => ({
                                            ...prev,
                                            [group._id]: false,
                                        }));
                                    }
                                }}
                            >
                                <span
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setCollapsedGroups((prev) => ({
                                            ...prev,
                                            [group._id]: !prev[group._id],
                                        }));
                                    }}
                                >
                                    {collapsedGroups[group._id] ? (
                                        <FontAwesomeIcon icon={faCaretRight} />
                                    ) : (
                                        <FontAwesomeIcon icon={faCaretDown} />
                                    )}
                                </span>
                                {group.title || 'Untitled Group'}
                                {collapsedGroups[group._id] && (
                                    <span>
                                        &nbsp;(
                                        {groupedSituations[group._id]?.length || 0})
                                    </span>
                                )}
                            </div>

                            {/* Always render the Droppable area */}
                            <Droppable droppableId={group._id}>
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        className={`${styles.situationList} ${
                                            snapshot.isDraggingOver
                                                ? styles.isDraggingOver
                                                : ''
                                        }`}
                                        style={{
                                            display: collapsedGroups[group._id]
                                                ? 'none'
                                                : 'block',
                                            minHeight: '10px', // Ensure droppable area is visible even when empty
                                        }}
                                    >
                                        {groupedSituations[group._id]?.map(
                                            (situation, index) => (
                                                <Draggable
                                                    key={situation._id}
                                                    draggableId={situation._id}
                                                    index={index}
                                                >
                                                    {(provided) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            className={`${styles.situationItem} ${
                                                                styles.groupedSituationItem
                                                            } ${
                                                                selectedSituation?._id ===
                                                                situation._id
                                                                    ? styles.selectedSituation
                                                                    : ''
                                                            }`}
                                                            onClick={() => {
                                                                getSituation(situation._id);
                                                                centerNodeInView(
                                                                    situation._id
                                                                );
                                                            }}
                                                            style={{
                                                                '--bullet-color':
                                                                    group.color === '#ffffff'
                                                                        ? '#000'
                                                                        : group.color,
                                                                ...provided.draggableProps
                                                                    .style,
                                                            }}
                                                        >
                                                            {situation.title ||
                                                                'Untitled Situation'}
                                                        </div>
                                                    )}
                                                </Draggable>
                                            )
                                        )}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </div>
                    ))}

                    {/* Ungrouped Situations */}
                    {groups.length > 0 && (
                        <>
                            <div className={styles.ungroupedTitle}>
                                Ungrouped Situations
                            </div>
                            <Droppable droppableId="ungrouped">
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        className={`${styles.situationList} ${
                                            snapshot.isDraggingOver
                                                ? styles.isDraggingOver
                                                : ''
                                        }`}
                                        style={{
                                            minHeight: '10px', // Ensure droppable area is visible
                                        }}
                                    >
                                        {ungroupedSituations.map((situation, index) => (
                                            <Draggable
                                                key={situation._id}
                                                draggableId={situation._id}
                                                index={index}
                                            >
                                                {(provided) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className={`${styles.situationItem} ${
                                                            selectedSituation?._id ===
                                                            situation._id
                                                                ? styles.selectedSituation
                                                                : ''
                                                        }`}
                                                        onClick={() => {
                                                            getSituation(situation._id);
                                                            centerNodeInView(situation._id);
                                                        }}
                                                        style={{
                                                            '--bullet-color': '#000',
                                                            ...provided.draggableProps.style,
                                                        }}
                                                    >
                                                        {situation.title ||
                                                            'Untitled Situation'}
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </>
                    )}

                    {/*no groups exist */}
                    {groups.length === 0 && (
                        <Droppable droppableId="ungrouped">
                            {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    className={`${styles.situationList} ${
                                        snapshot.isDraggingOver
                                            ? styles.isDraggingOver
                                            : ''
                                    }`}
                                    style={{ paddingLeft: '0', minHeight: '10px' }}
                                >
                                    {ungroupedSituations.map((situation, index) => (
                                        <Draggable
                                            key={situation._id}
                                            draggableId={situation._id}
                                            index={index}
                                        >
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className={`${styles.situationItem} ${
                                                        selectedSituation?._id ===
                                                        situation._id
                                                            ? styles.selectedSituation
                                                            : ''
                                                    }`}
                                                    onClick={() => {
                                                        getSituation(situation._id);
                                                        centerNodeInView(situation._id);
                                                    }}
                                                    style={{
                                                        '--bullet-color': '#000',
                                                        ...provided.draggableProps.style,
                                                    }}
                                                >
                                                    {situation.title ||
                                                        'Untitled Situation'}
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    )}
                </div>
            </DragDropContext>
        </div>
    );
};

export default SituationsAndGroups;
