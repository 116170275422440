import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Login from './Login';
import Register from './Register';
import ForgotPassword from './ForgotPassword';
import AuthBody from './AuthBody';
import styles from './styles/AuthForm.module.css';

const AuthForm = () => {
    const location = useLocation(); /* what does this do in relation to setting the useState for the authMode? */
    const [authMode, setAuthMode] = useState(location.state?.authMode || 'login');
    const [acceptInvite, setAcceptInvite] = useState(location.state?.acceptInvite || 'false');
    const [showForgotPassword, setShowForgotPassword] = useState(false);

    {/* Comment */}

    return (
        <div className={styles.container}>
            {authMode === 'login' ? (
                <>
                    <div className={`${styles.leftHalf} ${styles.loginLeft}`}>
                        {showForgotPassword ? (
                            <ForgotPassword setShowForgotPassword={setShowForgotPassword} />
                        ) : (
                            /* Form component itself */
                            <Login setAuthModalState={setAuthMode} setShowForgotPassword={setShowForgotPassword} acceptInvite={acceptInvite} />
                        )}
                    </div>
                    <div className={`${styles.rightHalf} ${styles.loginRight}`}>
                        <AuthBody />
                    </div>
                </>
            ) : (
                <>
                    <div className={`${styles.leftHalf} ${styles.registerLeft}`}>
                        <AuthBody />
                    </div>
                    <div className={`${styles.rightHalf} ${styles.registerRight}`}>
                        <Register setAuthModalState={setAuthMode} acceptInvite={acceptInvite} />
                    </div>
                </>
            )}
        </div>
    );
};

export default AuthForm;
