// MyClassroomsHeader.js
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useMatch } from 'react-router-dom';
import styles from './styles/MyClassroomsHeader.module.css';
import CreateClassroomModal from './CreateClassroomModal';
import { ClassroomContext } from '../../context/ClassroomContext';

const MyClassroomsHeader = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { classroom } = useContext(ClassroomContext);
    const [classroomName, setClassroomName] = useState(null);
    const navigate = useNavigate();

    const classroomMatch = useMatch('/my-classrooms/:classroomId/*');

    useEffect(() => {
        if (classroomMatch && classroom) {
            setClassroomName(classroom.name);
        } else {
            setClassroomName(null);
        }
    }, [classroomMatch, classroom]);

    const handleClassroomCreated = (classroomId) => {
        if (classroomId) {
            navigate(`/my-classrooms/${classroomId}`);
        }
        setIsModalOpen(false); // Close modal after creation
    };

    return (
        <>
            {isModalOpen && (
                <CreateClassroomModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    onClassroomCreated={handleClassroomCreated}
                />
            )}
            <div className={styles.headerContainer}>
                <h3 className={styles.title}>{classroomName || 'Classrooms'}</h3>
                <button
                    className={styles.createClassroomButton}
                    onClick={() => setIsModalOpen(true)}
                >
                    +
                </button>
            </div>
        </>
    );
};

export default MyClassroomsHeader;
