// EditAnnouncementModal.js

import React, { useState, useEffect } from 'react';
import styles from './styles/EditAnnouncementModal.module.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const EditAnnouncementModal = ({ isOpen, onClose, announcement, handleStopEditing }) => {
    const [editedText, setEditedText] = useState('');

    useEffect(() => {
        if (announcement?.text) {
            setEditedText(announcement.text);
        }
    }, [announcement]);

    const handleSave = () => {
        if (editedText.trim() === '') return;
        handleStopEditing(announcement._id, editedText);
        onClose();
    };

    const handleCancel = () => {
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
                <h2>Edit Announcement</h2>
                <CKEditor
                    editor={ClassicEditor}
                    data={editedText}
                    config={{
                        toolbar: {
                            items: [
                                'undo',
                                'redo',
                                '|',
                                'heading',
                                '|',
                                'bold',
                                'italic',
                                '|',
                                'link',
                                'uploadImage',
                                'blockQuote',
                                '|',
                                'bulletedList',
                                'numberedList',
                                'outdent',
                                'indent',
                            ],
                            shouldNotGroupWhenFull: true,
                        },
                        ckfinder: {
                            uploadUrl: `${process.env.REACT_APP_API_URL_LOCAL}/api/images/upload`,
                        },
                        mediaEmbed: {
                            elementName: 'iframe',
                        },
                    }}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        setEditedText(data);
                    }}
                />
                <div className={styles.buttonGroup}>
                    <button onClick={handleSave} className={styles.saveButton}>
                        Save
                    </button>
                    <button onClick={handleCancel} className={styles.cancelButton}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

export default EditAnnouncementModal;
