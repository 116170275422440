import React, { useContext } from 'react';
import { ClassroomContext } from '../../context/ClassroomContext';

const ClassroomSettings = () => {
    const { classroom, isOwner, deleteClassroom } = useContext(ClassroomContext);
    
    const handleDeleteClassroom = () => {
        if (classroom) {
            deleteClassroom(classroom._id); // Call deleteClassroom from context
        }
    };

    return (
        <div>
            <h1>Settings</h1>
            {isOwner ? (
                <>
                    <h2>Owner Settings</h2>
                    <button onClick={handleDeleteClassroom}>Delete Classroom</button>
                </>
            ) : (
                <h2>Member Settings</h2>
                // Other settings for non-owners can go here
            )}
        </div>
    );
};

export default ClassroomSettings;
