import React, { useContext, useState } from 'react';
import styles from './styles/NavbarSearch.module.css';
import SearchContext from '../../../context/SearchContext';
import { useNavigate } from 'react-router-dom';
import { FaSearch, FaArrowLeft } from 'react-icons/fa';

const NavbarSearch = ({ isMobile, setIsSearchExpanded }) => {
    const { setSearchQuery, setSearchType } = useContext(SearchContext);
    const [inputValue, setInputValue] = useState('');
    const [searchType, setSearchTypeLocal] = useState('scenario');
    const navigate = useNavigate();

    const handleSearch = () => {
        const trimmedInput = inputValue.trim();
        if (trimmedInput !== '') {
            const isTag = trimmedInput.startsWith('#');
            const query = isTag ? trimmedInput.slice(1) : trimmedInput;
            setSearchQuery(query);
            setSearchType(searchType);
            navigate(`/search?query=${query}&type=${searchType}&tag=${isTag}`);
        }
    };

    const handleTypeChange = (e) => {
        setSearchTypeLocal(e.target.value);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    const handleBackClick = () => {
        setIsSearchExpanded(false);
    };

    return (
        <div className={styles.searchContainer}>
            {isMobile && (
                <FaArrowLeft className={styles.backIcon} onClick={handleBackClick} />
            )}
            <div className={styles.searchBoxWrapper}>
                <input
                    type="text"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyPress={handleKeyPress}
                    placeholder="Search scenarios or users..."
                    className={styles.searchBox}
                />
                <FaSearch onClick={handleSearch} className={styles.searchIcon} />
            </div>
            <select onChange={handleTypeChange} className={styles.searchSelect}>
                <option value='scenario'>Scenario</option>
                <option value='user'>User</option>
            </select>
        </div>
    );
};

export default NavbarSearch;
