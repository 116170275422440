import React, { useContext, useState } from 'react';
import CanvasContext from '../../../context/CanvasContext';
import styles from './styles/LinkSituation.module.css';

const LinkSituation = ({ nextSituation }) => {
    const { situations, updateChoice, selectedChoice, setSelectedChoice, updateEdges, edges } = useContext(CanvasContext);
    const [searchTerm, setSearchTerm] = useState('');

    // Check if nextSituation is null/undefined
    const isNextSituationUndefined = !nextSituation || !situations.find(situation => situation._id === nextSituation._id);

    // Handle selecting a new situation and updating the choice's nextSituation
    const handleSituationClick = async (situationId) => {
        if (nextSituation && situationId === nextSituation._id) {
            return; // Prevent unnecessary updates
        }

        try {
            // Step 1: Update the selected choice's nextSituation to the new situation
            const updatedChoice = await updateChoice({ nextSituation: situationId });

            setSelectedChoice(updatedChoice);

            // Step 2: Update the corresponding edge's target to match the new situation's node
            const updatedEdges = edges.map(edge =>
                edge.id === selectedChoice._id ? { ...edge, target: situationId } : edge
            );
            await updateEdges(updatedEdges);

            console.log(`Updated nextSituation to ${situationId} and updated the edges.`);
        } catch (error) {
            console.error('Error updating nextSituation or edges:', error);
        }
    };

    // Filter and sort situations with nextSituation at the top
    const filteredSituations = situations
        .filter(situation => situation && situation.title.toLowerCase().includes(searchTerm.toLowerCase())); // Handle null/undefined situations

    return (
        <div className={styles.linkSituationContainer}>
            <h2 className={styles.selectedChoiceTitle}>{selectedChoice.title}</h2>

            <h3 className={styles.situationListTitle}>Select a Situation</h3>

            {/* Search bar */}
            <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search situations..."
                className={styles.searchInput}
            />

            {/* Warning message if nextSituation is undefined */}
            {isNextSituationUndefined && (
                <p className={styles.warningText}>This choice is linked to an undefined situation.</p>
            )}

            <ul className={styles.situationList}>
                {filteredSituations.map((situation) => (
                    <li
                        key={situation?._id}
                        className={`${styles.situationItem} ${
                            situation && nextSituation && situation._id === nextSituation._id
                                ? styles.highlightedSituation
                                : ''
                        }`}
                        onClick={() => handleSituationClick(situation?._id)}
                    >
                        {situation?.title || 'Untitled Situation'}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default LinkSituation;
