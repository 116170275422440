import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import AuthContext from './AuthContext';

//Unused at the moment
const LikedFollowedContext = createContext({
  likedScenarios: [],
  setLikedScenarios: () => { },
  followedScenarios: [],
  setFollowedScenarios: () => { },
});

export const LikedFollowedContextProvider = ({ children }) => {
  const { userId } = useContext(AuthContext);
  const [likedScenarios, setLikedScenarios] = useState([]);
  const [followedScenarios, setFollowedScenarios] = useState([]);

  useEffect(() => {
    if (userId) {
      axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/users/${userId}`)
        .then(response => {
          setLikedScenarios(response.data.likedScenarios);
          setFollowedScenarios(response.data.followedScenarios);
        })
        .catch(error => console.error('Error fetching user data:', error));
    }
  }, [userId]);

  return (
    <LikedFollowedContext.Provider value={{ likedScenarios, setLikedScenarios, followedScenarios, setFollowedScenarios }}>
      {children}
    </LikedFollowedContext.Provider>
  );
};

export default LikedFollowedContext;
