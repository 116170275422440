import React, { useContext } from 'react';
import styles from './styles/CreateSituation.module.css';
import CanvasContext from '../../../context/CanvasContext';
import SituationNode from './SituationNode'; // Adjust the path as necessary

const CreateSituation = ({ collapsePanel, isMobile }) => {
    const { createNodeAtCenter, onDragStart } = useContext(CanvasContext);

    const handleNodeClick = (nodeType) => {
        // Only allow click-to-create on mobile
        if (isMobile) {
            createNodeAtCenter(nodeType);
            collapsePanel();
        }
    };

    const handleDragStart = (event, nodeType) => {
        // Only allow drag-and-drop on non-mobile
        if (!isMobile) {
            // Clone the current target for the drag image
            const dragElement = event.currentTarget.cloneNode(true);

            // Position it off-screen
            dragElement.style.position = 'absolute';
            dragElement.style.top = '-1000px';
            dragElement.style.left = '-1000px';

            // Append to body
            document.body.appendChild(dragElement);

            // Use the cloned element as the drag image
            event.dataTransfer.setDragImage(
                dragElement,
                dragElement.offsetWidth / 2,
                dragElement.offsetHeight / 2
            );

            // Clean up after setting the drag image
            setTimeout(() => {
                document.body.removeChild(dragElement);
            }, 0);

            // Call the context's onDragStart function with the node type
            onDragStart(event, nodeType);
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.nodeTypes}>
                {/* Situation Node */}
                <div
                    draggable={!isMobile}
                    onClick={isMobile ? () => handleNodeClick('ovalNode') : null}
                    onDragStart={(event) => handleDragStart(event, 'ovalNode')}
                >
                    <SituationNode data={{ label: 'Situation' }} showHandles={false} />
                </div>

                {/* Start Node */}
                <div
                    draggable={!isMobile}
                    onClick={isMobile ? () => handleNodeClick('startNode') : null}
                    onDragStart={(event) => handleDragStart(event, 'startNode')}
                >
                    <SituationNode
                        data={{ label: 'START', endpointType: 'start' }}
                        showHandles={false}
                    />
                </div>

                {/* End Node */}
                <div
                    draggable={!isMobile}
                    onClick={isMobile ? () => handleNodeClick('endNode') : null}
                    onDragStart={(event) => handleDragStart(event, 'endNode')}
                >
                    <SituationNode
                        data={{ label: 'END', endpointType: 'end' }}
                        showHandles={false}
                    />
                </div>
            </div>
        </div>
    );
};

export default CreateSituation;
