import React, { useContext } from 'react';
import styles from './styles/ErrorOverlay.module.css';
import CanvasContext from '../../../context/CanvasContext';

function ErrorOverlay() {
    const { getSituation, getChoice, graphErrors } = useContext(CanvasContext);

    if (!graphErrors || graphErrors.length === 0) {
        return null;
    } else {
        console.log("ERRORS: ", graphErrors);
        return (
            <div className={styles.overlay}>
                {graphErrors.map((error, index) => (
                    <div
                        key={index}
                        className={styles.item}
                        onClick={() => {
                            if (error.node) {
                                getSituation(error.node.id);
                            } else if (error.edge) {
                                getChoice(error.edge.id);
                            }
                        }}
                    >
                        {error.node || error.edge ? (
                            <span className={styles.label}>
                                {error.node ? error.node.data.label : error.edge.data.label}
                            </span>
                        ) : null}
                        <span className={styles.reason}> - {error.reason}</span>
                    </div>
                ))}
            </div>
        );
    }
}

export default ErrorOverlay;
