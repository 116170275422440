import React, { createContext, useState, useContext } from "react";


// This isn't being used either, currently.
const ShowAllContext = createContext();

export default ShowAllContext;

export const useShowAll = () => {
  return useContext(ShowAllContext);
};

export const ShowAllProvider = ({ children }) => {
  const [showAll, setShowAll] = useState(false);
  const handleShowAll = (e) => {
    e.preventDefault();
    setShowAll(!showAll);
  }
  return (
    <ShowAllContext.Provider value={{ showAll, handleShowAll }}>
      {children}
    </ShowAllContext.Provider>
  );
};
