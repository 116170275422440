import React, { useRef } from 'react';
import styles from './styles/HintModal.module.css';
import { transformIframes } from '../../../utils/utils';
import { AiOutlineClose } from 'react-icons/ai';

const HintModal = ({ hintText, closeModal }) => {
    const modalContentRef = useRef(null);

    const handleBackdropClick = (event) => {
        if (modalContentRef.current && !modalContentRef.current.contains(event.target)) {
            closeModal();
        }
    };

    return (
        <div className={styles.modalBackdrop} onClick={handleBackdropClick}>
            <div className={styles.modalContent} ref={modalContentRef}>
                <div className={styles.text} dangerouslySetInnerHTML={{ __html: transformIframes(hintText) }} />
                <AiOutlineClose className={styles.exitModal} onClick={closeModal} />
            </div>
        </div>
    );
};

export default HintModal;
