import React, { useEffect, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Masonry from 'react-masonry-css';
import ScenarioCard from '../mainlayout/body/ScenarioCard';
import UserCard from './UserCard';
import SearchContext from '../../context/SearchContext';
import styles from './styles/Search.module.css';

function Search() {
    const { searchQuery, setSearchQuery, searchType, setSearchType, searchResults, setSearchResults } = useContext(SearchContext);
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const query = params.get('query');
        const type = params.get('type') || 'scenario';
        const isTag = params.get('tag') === 'true';

        if (!query) {
            setError('Query parameter is missing');
            setLoading(false);
            return;
        }

        if (!type) {
            setError('Type parameter is missing');
            setLoading(false);
            return;
        }

        setSearchQuery(query);
        setSearchType(type);
        setLoading(true);
        setError('');

        axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/search?query=${query}&type=${type}&tag=${isTag}`)
            .then(response => {
                setSearchResults(response.data.results);
                setLoading(false);
            })
            .catch(error => {
                setError('Error fetching search results');
                setLoading(false);
            });
    }, [location.search, setSearchQuery, setSearchType, setSearchResults]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    if (!searchResults || searchResults.length === 0) {
        return <div>No results found.</div>;
    }

    const breakpointColumnsObj = {
        default: 5,
        1500: 4,
        1200: 3,
        850: 2,
        600: 1
    };

    return (
        <div className={styles.container}>
            <h1>Search Results for "{searchQuery}"</h1>
            <Masonry
                breakpointCols={breakpointColumnsObj}
                className={styles.myMasonryGrid}
                columnClassName={styles.myMasonryGridColumn}
            >
                {searchType === 'scenario' && searchResults.map(result => (
                    <ScenarioCard key={result._id} scenario={result} searchQuery={searchQuery} />
                ))}
                {searchType === 'user' && searchResults.map(result => (
                    <UserCard key={result._id} user={result} />
                ))}
            </Masonry>
        </div>
    );
}

export default Search;
