import React, { createContext, useState } from 'react';

const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
    const [isSearchEmpty, setIsSearchEmpty] = useState("hi");
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [searchType, setSearchType] = useState('scenario');

    return (
        <SearchContext.Provider
            value={{
                isSearchEmpty, setIsSearchEmpty, searchQuery, setSearchQuery,
                searchResults, setSearchResults, searchType, setSearchType
            }}
        >
            {children}
        </SearchContext.Provider>
    );
};

export default SearchContext;
