import React from 'react';
import styles from './styles/AccountSidebar.module.css';

const AccountSidebar = ({ activeTab, setActiveTab, userIsAccountOwner }) => {
    return (
        <div className={styles.sidebar}>
            <button 
                className={`${styles.sidebarItem} ${activeTab === 'Profile' ? styles.active : ''}`} 
                onClick={() => setActiveTab('Profile')}
            >
                Profile
            </button>
            <button 
                className={`${styles.sidebarItem} ${activeTab === 'Scenarios' ? styles.active : ''}`} 
                onClick={() => setActiveTab('Scenarios')}
            >
                Scenarios
            </button>
            {userIsAccountOwner && (
                <>
                    <button 
                        className={`${styles.sidebarItem} ${activeTab === 'Logs' ? styles.active : ''}`} 
                        onClick={() => setActiveTab('Logs')}
                    >
                        Logs
                    </button>
                    <button 
                        className={`${styles.sidebarItem} ${activeTab === 'Feedback' ? styles.active : ''}`} 
                        onClick={() => setActiveTab('Feedback')}
                    >
                        Feedback
                    </button>
                    <button 
                        className={`${styles.sidebarItem} ${activeTab === 'Settings' ? styles.active : ''}`} 
                        onClick={() => setActiveTab('Settings')}
                    >
                        Settings
                    </button>
                </>
            )}
        </div>
    );
};

export default AccountSidebar;
