import React, { createContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useReactFlow } from 'react-flow-renderer';
import { checkValidity } from '../utils/utils';
import { useNavigate } from 'react-router-dom';

const CanvasContext = createContext();

export const CanvasProvider = ({ scenarioId, children }) => {
    const [scenario, setScenario] = useState(null);
    const [nodes, setNodes] = useState([]);
    const [edges, setEdges] = useState([]);
    const { getZoom, setCenter } = useReactFlow(); // Use from React Flow
    const [selectedNodeId, setSelectedNodeId] = useState(null);
    const [selectedNodeIds, setSelectedNodeIds] = useState([]);
    const [selectedEdgeId, setSelectedEdgeId] = useState(null);
    const [edgeConnectingNodeId, setEdgeConnectingNodeId] = useState(null);
    const [selectAllTriggered, setSelectAllTriggered] = useState(false);
    const [groups, setGroups] = useState([]);
    const [situations, setSituations] = useState([]);
    const [isDragging, setIsDragging] = useState(false);
    const [hasToc, setHasToc] = useState(false);
    const [scenarioTitle, setScenarioTitle] = useState('');
    const [isLogged, setIsLogged] = useState(false);
    const [logOrder, setLogOrder] = useState([]);
    const [isEditing, setIsEditing] = useState(null);
    const [isPublished, setIsPublished] = useState(false);
    const [selectedSituation, setSelectedSituation] = useState(null);
    const [selectedChoice, setSelectedChoice] = useState(null);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [graphErrors, setGraphErrors] = useState(null);
    const [activeEditTab, setActiveEditTab] = useState('1');
    const [showTocEditor, setShowTocEditor] = useState(false);
    const [loading, setLoading] = useState(false);
    const [keys, setKeys] = useState([]);
    const [getZoomFunc, setGetZoomFunc] = useState(null);
    const [projectFunc, setProjectFunc] = useState(null);
    const [reactFlowWrapper, setReactFlowWrapper] = useState(null);

    const navigate = useNavigate();

    // Fetch data and initialize the scenario
    useEffect(() => {
        const initializeCanvas = async () => {
            try {
                const scenarioResponse = await axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/scenarios/${scenarioId}`);
                const scenarioData = scenarioResponse.data;
                setScenario(scenarioData);
                setIsPublished(scenarioData.isPublished);
                setHasToc(scenarioData.tableOfContents);
                setScenarioTitle(scenarioData.title);
                setIsLogged(scenarioData.isLogged);
                setLogOrder(scenarioData.logOrder);

                if (!scenarioData.isEditing) {
                    await axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/scenarios/${scenarioId}`, { isEditing: true });
                }

                setIsEditing(scenarioData.isEditing);

                if (scenarioData.isLoadedToCanvas) {
                    await fetchCanvasData();
                } else {
                    await loadScenario();
                }

                await fetchSituationsAndGroups();
                await fetchKeys();
            } catch (err) {
                console.error("Error initializing canvas: ", err);
            }
        };

        initializeCanvas();
    }, [scenarioId]);

    const fetchCanvasData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/canvas/${scenarioId}`);
            setNodes(response.data.nodes);
            setEdges(response.data.edges);
        } catch (err) {
            console.error("Error fetching canvas data: ", err);
        }
    };

    const fetchKeys = async () => {
        try {
            const keysResponse = await axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/keys/all/${scenarioId}`);
            setKeys(keysResponse.data);
        } catch (err) {
            console.error("Error fetching keys: ", err);
        }
    };

    const loadScenario = async () => {
        try {
            const response = await axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/canvas/${scenarioId}/load`);
            setNodes(response.data.canvas.nodes);
            setEdges(response.data.canvas.edges);
        } catch (error) {
            console.error("Error loading scenario: ", error);
        }
    };

    const fetchSituationsAndGroups = async () => {
        try {
            const [situationsResponse, groupsResponse] = await Promise.all([
                axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/situations/${scenarioId}/all`),
                axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/situation-groups/scenario/${scenarioId}`)
            ]);
    
            const fetchedSituations = situationsResponse.data;
            const fetchedGroups = groupsResponse.data;
    
            setGroups(fetchedGroups);
            setSituations(fetchedSituations);
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    };
    

    const updateNodes = async (updatedNodes) => {
        try {
            const nodesToUpdate = updatedNodes || nodes; // Use existing nodes if not provided
        
            await axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/canvas/${scenarioId}`, {
                nodes: nodesToUpdate,
                edges, // Keep the current edges
            });
        
            setNodes([...nodesToUpdate]);
        } catch (error) {
            console.error("Error updating nodes: ", error);
        }
    };

    const updateEdges = async (updatedEdges) => {
        try {
            const edgesToUpdate = updatedEdges || edges; // Use existing edges if not provided
        
            await axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/canvas/${scenarioId}`, {
                nodes, // Keep the current nodes
                edges: edgesToUpdate,
            });
        
            setEdges([...edgesToUpdate]);
        } catch (error) {
            console.error("Error updating edges: ", error);
        }
    };

    // Function to center node in view
    const centerNodeInView = useCallback((nodeId) => {
        const node = nodes.find((n) => n.id === nodeId);
        if (node) {
            const { x, y } = node.position; // Get node's position
            const zoom = getZoom(); // Get the current zoom level
            setCenter(x, y, { zoom, duration: 800 }); // Smoothly center the node with a transition
        }
    }, [nodes, setCenter, getZoom]);
    
    
    

    const createSituation = async (newSituationData) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL_LOCAL}/api/situations/${scenarioId}`, newSituationData);
            const newSituation = response.data;
            setSelectedSituation(newSituation);
            onSelectNode(newSituation._id);
    
            // Update the situations state
            setSituations(prevSituations => [...prevSituations, newSituation]);
    
            // Return the newly created situation
            return newSituation;
        } catch (error) {
            console.error("Error adding situation: ", error);
            throw error; // Re-throw the error so it can be handled by the caller
        }
    };

    const createChoice = async (sourceId, targetId, hasHint, copying = false) => {
        try {
            // Step 1: Send a request to create a new choice in the backend
            const response = await axios.post(`${process.env.REACT_APP_API_URL_LOCAL}/api/choices/${sourceId}`, {
                text: '', 
                nextSituation: targetId, 
                hasHint: hasHint,
            });
    
            const newChoice = response.data;
    
            // Step 2: Set the newly created choice as the selected choice
            if (!copying) {
                setSelectedChoice(newChoice);
            }
    
            // Step 3: Update the situations state to include the new choice in the corresponding situation
            setSituations((prevSituations) => {
                return prevSituations.map((situation) => {
                    if (situation._id === newChoice.situation) {
                        // Add the new choice to the situation's choices array
                        const updatedChoices = [...situation.choices, newChoice];
                        return { ...situation, choices: updatedChoices };
                    }
                    return situation;
                });
            });
    
            // Step 4: Return the newly created choice
            return newChoice;
    
        } catch (error) {
            console.error("Error adding choice: ", error);
            throw error; // Re-throw the error so it can be handled by the caller
        }
    };
    

    const updateChoice = async (updatedSettings, choiceId = selectedEdgeId) => {
        try {
            const response = await axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/choices/choice/${choiceId}`, updatedSettings);
            const updatedChoice = response.data;
    
            // Update the situation in the situations array
            setSituations((prevSituations) => {
                return prevSituations.map((situation) => {
                    if (situation._id === updatedChoice.situation) {
                        const updatedChoices = situation.choices.map((choice) =>
                            choice._id === updatedChoice._id ? updatedChoice : choice
                        );
                        return { ...situation, choices: updatedChoices };
                    }
                    return situation;
                });
            });
    
            // Update edges if hasHint changed
            if (updatedSettings.hasOwnProperty('hasHint')) {
                const choiceType = updatedSettings.hasHint ? 'hint' : '';
                const updatedEdges = edges.map((edge) =>
                    edge.id === choiceId ? { ...edge, data: { ...edge.data, choiceType } } : edge
                );
                updateEdges(updatedEdges);
            }
    
            return updatedChoice;
        } catch (error) {
            console.error(`Error updating choice settings: ${error}`);
        }
    };
    
    

    const deleteChoice = async (choiceId = selectedEdgeId) => {
        try {
            // Step 1: Send a request to delete the choice from the backend
            await axios.delete(`${process.env.REACT_APP_API_URL_LOCAL}/api/choices/choice/${choiceId}`);
    
            // Step 2: Update the situations state to remove the deleted choice from the corresponding situation
            setSituations((prevSituations) => {
                return prevSituations.map((situation) => {
                    if (situation.choices.some((choice) => choice._id === choiceId)) {
                        // Remove the deleted choice from the situation's choices array
                        const updatedChoices = situation.choices.filter((choice) => choice._id !== choiceId);
                        return { ...situation, choices: updatedChoices };
                    }
                    return situation;
                });
            });
    
            // Step 3: Trigger onCanvasClick to update the canvas
            onCanvasClick();
    
            console.log(`Choice ${choiceId} deleted successfully`);
        } catch (error) {
            console.error(`Error deleting choice: ${error}`);
            throw error;
        }
    };
    
    
    
    
    

    const updateSituation = async (updatedSituationData, situationId = selectedNodeId) => {
        try {
            let response;

        if (updatedSituationData instanceof FormData) {
            // Send FormData with multipart/form-data
            response = await axios.patch(
                `${process.env.REACT_APP_API_URL_LOCAL}/api/situations/situation/${situationId}`,
                updatedSituationData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
        } else {
            // Send JSON data
            response = await axios.patch(
                `${process.env.REACT_APP_API_URL_LOCAL}/api/situations/situation/${situationId}`,
                updatedSituationData
            );
        }

        const updatedSituation = response.data;
    
            setSituations(prevSituations =>
                prevSituations.map(situation =>
                    situation._id === updatedSituation._id ? updatedSituation : situation
                )
            );
    
            // If isStart or isEnd is updated, update the corresponding node's endpointType
            if (updatedSituationData.hasOwnProperty('isStart') || updatedSituationData.hasOwnProperty('isEnd')) {
                let endpointType = '';
    
                if (updatedSituationData.isStart) {
                    endpointType = 'start';
                } else if (updatedSituationData.isEnd) {
                    endpointType = 'end';
                }
    
                // Update the corresponding node in the canvas
                const updatedNode = nodes.find(node => node.id === situationId);
                if (updatedNode) {
                    const updatedNodes = nodes.map(node => 
                        node.id === situationId 
                            ? { 
                                ...updatedNode, 
                                data: { 
                                    ...updatedNode.data, 
                                    endpointType 
                                } 
                            } 
                            : node
                    );
    
                    updateNodes(updatedNodes); // Update the nodes in the canvas
                }
            }
    
            return updatedSituation; // Return the updated situation
        } catch (error) {
            console.error("Error updating situation: ", error);
        }
    };
    
    
    
    
    const deleteSituation = async (situationId) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL_LOCAL}/api/situations/situation/${situationId}`);
    
            setSituations(prevSituations =>
                prevSituations.filter(situation => situation._id !== situationId)
            );

            onCanvasClick();
            
        } catch (error) {
            console.error("Error deleting situation: ", error);
        }
    };

    const createGroup = async (groupData) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL_LOCAL}/api/situation-groups/${scenarioId}`, groupData);
            const newGroup = response.data;
    
            // Update the groups state to include the new group 
            setGroups(prevGroups => [...prevGroups, newGroup]);
    
            // Set the newly created group as the selected group
            setSelectedGroup(newGroup);
    
            return newGroup;
        } catch (error) {
            console.error("Error creating group: ", error);
            throw error; // Re-throw the error so it can be handled by the caller
        }
    };
    
    
    
    

    const updateGroup = async (groupData) => {
        try {
            let response;

            if (groupData instanceof FormData) {
                // Send FormData with multipart/form-data
                response = await axios.patch(
                    `${process.env.REACT_APP_API_URL_LOCAL}/api/situation-groups/${selectedGroup._id}`,
                    groupData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );
            } else {
                // Send JSON data
                response = await axios.patch(
                    `${process.env.REACT_APP_API_URL_LOCAL}/api/situation-groups/${selectedGroup._id}`,
                    groupData
                );
            }

            const updatedGroup = response.data;

            // Update the group in the groups state
            setGroups(prevGroups =>
                prevGroups.map(group =>
                    group._id === updatedGroup._id ? updatedGroup : group
                )
            );

            return updatedGroup; // Return the updated group
        } catch (error) {
            console.error("Error updating group: ", error);
            throw error;
        }
    };


    const deleteGroup = async (groupId, groupSituations = []) => {
        try {
            const situationIds = groupSituations.length > 0 ? groupSituations.map(situation => situation._id) : [];
    
            await axios.delete(`${process.env.REACT_APP_API_URL_LOCAL}/api/situation-groups/${groupId}`, {
                data: { situationIds }
            });
    
            setGroups(prevGroups =>
                prevGroups.filter(group => group._id !== groupId)
            );
    
            if (situationIds.length > 0) {
                setSituations(prevSituations =>
                    prevSituations.map(situation =>
                        situationIds.includes(situation._id)
                            ? { ...situation, situationGroup: null }
                            : situation
                    )
                );
    
                const updatedNodes = nodes.map(node =>
                    situationIds.includes(node.id)
                        ? { ...node, data: { ...node.data, fillColor: '#FFFFFF' } }
                        : node
                );
                await updateNodes(updatedNodes);
            }
    
            onCanvasClick();
    
        } catch (error) {
            console.error("Error deleting situation group: ", error);
        }
    };
    
    
    
    
    
    

    const getSituation = async (situationId) => {
        try {
            const situation = situations.find(situation => situation._id === situationId);
            if (!situation) {
                throw new Error("Situation not found");
            }
            setSelectedSituation(situation);
            onSelectNode(situationId);
        } catch (error) {
            console.error("Error fetching situation: ", error);
        }
    };

    const getChoice = async (choiceId) => {
        try {
            setLoading(true);
            const response = await axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/choices/choice/${choiceId}`);
            const choice = response.data;
            onSelectEdge(choiceId);
            setSelectedChoice(choice);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching choice: ", error);
        }
    };

    const getGroup = async (groupId) => {
        try {
            const group = groups.find(group => group._id === groupId);
            if (!group) {
                throw new Error("Group not found");
            }
            setSelectedGroup(group);
            onSelectGroup(groupId);
        } catch (error) {
            console.error("Error fetching group: ", error);
        }
    };

    const copyNode = async (nodeId) => {
        try {
            const originalNode = nodes.find(node => node.id === nodeId);
    
            if (!originalNode) {
                console.error('Original node not found');
                return;
            }
    
            // Log the original node
            console.log('Original Node:', originalNode);
    
            // Create a new situation by reusing the createSituation function
            const newSituationData = {
                title: '', // Empty title for new situation
                text: '',  // Empty text for new situation
                isStart: originalNode.data.isStart,
                isEnd: originalNode.data.isEnd,
                isFreeResponse: originalNode.data.isFreeResponse,
                isUserSubmitted: originalNode.data.isUserSubmitted,
                isLogged: originalNode.data.isLogged,
                scenario: originalNode.data.scenario, // Scenario ID from the original node
            };
    
            // Use the existing createSituation function
            const newSituation = await createSituation(newSituationData);
    
            // Log the new situation created from the backend
            console.log('New Situation:', newSituation);
    
            // Deep clone the position to avoid coupling the original and new node
            const newNode = {
                ...originalNode,
                id: newSituation._id, // Use the new situation's ID
                position: { 
                    x: originalNode.position.x + 50, // Ensure independent position
                    y: originalNode.position.y + 50
                },
                data: { ...originalNode.data, label: 'Untitled', fillColor: '#ffffff' },
                selected: false, // Ensure the new node is not selected initially
            };
    
            const updatedNodes = [...nodes, newNode];
    
            // Handle the choices (edges) only for self-referential edges
            const originalChoices = edges.filter(edge => edge.source === nodeId && edge.source === edge.target); // Filter only self-referential edges
            const newEdges = [];
    
            for (const originalChoice of originalChoices) {
                // Create a new choice where both source and target are the new node (self-referential)
                const newChoice = await createChoice(newNode.id, newNode.id, originalChoice.data.choiceType === 'hint', true);
                const newEdge = {
                    ...originalChoice,
                    id: newChoice._id,  // Use the new choice's ID
                    source: newNode.id,  // Both source and target are the new node
                    target: newNode.id,  // Self-referential
                    data: {
                        ...originalChoice.data,
                        label: newChoice.title || 'Untitled',
                        choiceType: newChoice.hasHint ? 'hint' : (newChoice.isMLFRQ ? 'mlfrq' : ''),
                    }
                };
                newEdges.push(newEdge);
            }
    
            const updatedEdges = [...edges, ...newEdges];
    
            // Update nodes and edges in the canvas
            await updateNodes(updatedNodes);
            await updateEdges(updatedEdges);
    
        } catch (error) {
            console.error('Error copying node:', error);
        }
    };

    /*
    Checks the graph for any errors before publishing.
    If any errors exist, set them and prevent publishing
    If there are no errors, proceed with publishing
    */
    const handlePublish = () => {
        const errors = checkValidity(nodes, edges);
    
        if (errors.length > 0) {
            setGraphErrors(errors);
        } else {
            const situationIds = nodes.map(node => node.id);
            const data = {
                situations: situationIds,
                isPublished: true,
                isEditing: false
            };
    
            const currentTime = Date.now();
    
            if (scenario.isPublished) {
                data.lastUpdated = currentTime;
            } else {
                data.publishedAt = currentTime;
                data.lastUpdated = currentTime;
            }
    
            axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/scenarios/${scenario?._id}`, data)
                .then(response => {
                    navigate('/home');
                })
                .catch(error => {
                    console.error(`Error publishing: ${error}`);
                });
        }
    };
    
    
    
    



    const onSelectNode = (nodeId) => {
        setSelectedNodeId(nodeId);
        setSelectedNodeIds([nodeId]);
        setSelectedEdgeId(null);
        setSelectedChoice(null);
        setSelectedGroup(null);
        setEdgeConnectingNodeId(null); // Exit edge-connecting mode
    };
    
    const onSelectNodes = (nodeIds) => {
        setSelectedNodeIds(nodeIds);
        setSelectedEdgeId(null);
        setSelectedChoice(null);
        setSelectedGroup(null);
        setEdgeConnectingNodeId(null); // Exit edge-connecting mode
    };
    
    const onSelectEdge = (edgeId) => {
        setSelectedEdgeId(edgeId);
        setSelectedNodeId(null);
        setSelectedNodeIds([]);
        setSelectedSituation(null);
        setSelectedGroup(null);
        setEdgeConnectingNodeId(null); // Exit edge-connecting mode
    };
    
    const onSelectGroup = (groupId) => {
        setSelectedNodeId(null);
        setSelectedNodeIds([]);
        setSelectedEdgeId(null);
        setSelectedSituation(null);
        setSelectedChoice(null);
        setEdgeConnectingNodeId(null); // Exit edge-connecting mode
    };
    
    const onCanvasClick = () => {
        setSelectedEdgeId(null);
        setSelectedNodeId(null);
        setSelectedNodeIds([]);
        setSelectedSituation(null);
        setSelectedChoice(null);
        setSelectedGroup(null);
        setEdgeConnectingNodeId(null); // Exit edge-connecting mode
    };
    
    
    

    const onDragStart = useCallback((event, nodeType) => {
        event.dataTransfer.setData('application/reactflow', nodeType);
        event.dataTransfer.effectAllowed = 'move';
        setIsDragging(true);
    }, []);
    
    const onDragEnd = useCallback(() => {
        setIsDragging(false);
    }, []);

    const createNodeAtCenter = async (nodeType) => {
        if (!getZoomFunc || !projectFunc || !reactFlowWrapper || !reactFlowWrapper.current) {
            console.error('getZoom, project function, or reactFlowWrapper is not available.');
            return;
        }

        const getZoom = getZoomFunc;
        const project = projectFunc;

        const zoomLevel = getZoom();

        const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();

        try {
            const nodeWidth = 120 * zoomLevel;
            const nodeHeight = 50 * zoomLevel;

            const centerX = (reactFlowBounds.width / 2) - (nodeWidth / 2);
            const centerY = (reactFlowBounds.height / 2) - (nodeHeight / 2);

            // Add random offset
            const offsetX = Math.random() * 50 - 25; // Random number between -25 and +25
            const offsetY = Math.random() * 50 - 25;

            const clientX = centerX + offsetX;
            const clientY = centerY + offsetY;

            const position = project({ x: clientX, y: clientY });

            // Prepare the new situation data
            const newSituationData = {
                isLogged: scenario.isLogged,
                text: '', // Set other properties as needed
                isStart: nodeType === 'startNode',
                isEnd: nodeType === 'endNode',
            };

            // Call createSituation with the prepared data
            const newSituation = await createSituation(newSituationData);

            // Determine the endpointType based on nodeType
            const endpointType =
                nodeType === 'startNode' ? 'start' :
                nodeType === 'endNode' ? 'end' :
                undefined;

            // Create the new node
            const newNode = {
                id: newSituation._id,
                type: 'situation',
                position: position,
                data: {
                    label: newSituation.text || 'Untitled',
                    endpointType: endpointType,
                },
            };

            // Update the nodes and canvas
            const updatedNodes = [...nodes, newNode];
            setNodes(updatedNodes);
            updateNodes(updatedNodes);

        } catch (error) {
            console.error(`Error creating a new situation: ${error}`);
        }
    };
    

    return (
        <CanvasContext.Provider value={{
            scenario, setScenario, nodes, setNodes, edges, setEdges,
            selectedNodeId, setSelectedNodeId, selectedNodeIds, setSelectedNodeIds,
            selectedEdgeId, setSelectedEdgeId, selectAllTriggered, setSelectAllTriggered, groups, situations,
            isDragging, setIsDragging, hasToc, setHasToc, scenarioTitle, isLogged, setIsLogged, logOrder, setLogOrder,
            isEditing, isPublished, onSelectNode, onSelectNodes, onSelectEdge, onSelectGroup,
            onCanvasClick, updateNodes, updateEdges, onDragStart, onDragEnd, createSituation, createChoice,
            updateSituation, setSelectedSituation, updateChoice, deleteSituation, createGroup, updateGroup,
            deleteGroup, deleteChoice, selectedSituation, selectedChoice, setSelectedChoice, selectedGroup,
            getSituation, getChoice, getGroup, setSelectedGroup, graphErrors, setGraphErrors,
            activeEditTab, setActiveEditTab, fetchSituationsAndGroups, copyNode, showTocEditor,
            setShowTocEditor, loading, setLoading, centerNodeInView, keys, setKeys, setSituations, handlePublish, createNodeAtCenter,
            setGetZoomFunc, setProjectFunc, setReactFlowWrapper, edgeConnectingNodeId, setEdgeConnectingNodeId,
        }}>
            {children}
        </CanvasContext.Provider>
    );
};

export default CanvasContext;
