// Folder.js

import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from './styles/Folders.module.css';
import folderIcon from '../../images/foldericon.png';
import addFolderIcon from '../../images/addfoldericon.png';
import addScenarioIcon from '../../images/addfileicon.png';
import settingsIcon from '../../images/settingsicon.png';
import AuthContext from '../../context/AuthContext';
import { ClassroomContext } from '../../context/ClassroomContext';
import FolderModal from './FolderModal';
import ScenarioModal from './ScenarioModal';

const Folder = () => {
    const { classroomId, folderId } = useParams();
    const { userId } = useContext(AuthContext);
    const navigate = useNavigate();
    const [folder, setFolder] = useState(null);
    const { isTeacher, updateClassroom } = useContext(ClassroomContext);

    const [subfolders, setSubfolders] = useState([]);
    const [scenarios, setScenarios] = useState([]);
    const [parentAllowedStudents, setParentAllowedStudents] = useState([]);
    const [isScenarioModalOpen, setIsScenarioModalOpen] = useState(false);
    const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);
    const [modalMode, setModalMode] = useState('create');
    const [currentFolder, setCurrentFolder] = useState(null);
    const [selectedScenarios, setSelectedScenarios] = useState([]);

    const [userScenarios, setUserScenarios] = useState([]);

    // Fetch folder data
    useEffect(() => {
        const fetchFolder = async (folderId) => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/folders/folder/${folderId}`);
                const folderData = response.data;
                setFolder(folderData);
                setSubfolders(folderData.folders || []);
                setScenarios(folderData.scenarios || []);
                setParentAllowedStudents(folderData.allowedStudents || []);
            } catch (error) {
                console.error('Error fetching folder:', error);
            }
        };

        if (folderId) {
            fetchFolder(folderId);
        }
    }, [folderId]);

    useEffect(() => {
        const fetchUserScenarios = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL_LOCAL}/api/scenarios/my`
                );
                setUserScenarios(response.data);
            } catch (error) {
                console.error('Error fetching user scenarios:', error);
            }
        };

        fetchUserScenarios();
    }, []);

    const handleFolderClick = (folder) => {
    // Teachers can always access the folder
    if (isTeacher) {
        navigate(`/my-classrooms/${classroomId}/f/${folder._id}`);
        return;
    }

    // Check access based on privacy settings
    if (folder.isPrivate && !folder.allowedStudents.includes(userId)) {
        alert('You do not have permission to access this folder.');
    } else {
        navigate(`/my-classrooms/${classroomId}/f/${folder._id}`);
    }
};


    const openCreateModal = () => {
        setModalMode('create');
        setCurrentFolder(null);
        setIsFolderModalOpen(true);
    };

    const openEditModal = (folder) => {
        setModalMode('edit');
        setCurrentFolder(folder);
        setIsFolderModalOpen(true);
    };

    const closeModal = () => {
        setIsFolderModalOpen(false);
        setCurrentFolder(null);
    };

    const handleAddScenarios = () => {
        setSelectedScenarios(folder?.scenarios || []);
        setIsScenarioModalOpen(true);
    };

    const handleAddSubfolder = (newFolder) => {
        setSubfolders((prevSubfolders) => [...prevSubfolders, newFolder]);
    };

    const handleUpdateSubfolder = (updatedFolder) => {
        setSubfolders((prevSubfolders) =>
            prevSubfolders.map((f) => (f._id === updatedFolder._id ? updatedFolder : f))
        );
    };

    const handleDeleteSubfolder = (deletedFolderId) => {
        setSubfolders((prevSubfolders) =>
            prevSubfolders.filter((f) => f._id !== deletedFolderId)
        );
    };

    const handleScenarioSelection = (scenario) => {
        if (selectedScenarios.some((s) => s._id === scenario._id)) {
            setSelectedScenarios(selectedScenarios.filter((s) => s._id !== scenario._id));
        } else {
            setSelectedScenarios([...selectedScenarios, scenario]);
        }
    };

    const handleSubmitScenarios = async () => {
        const selectedScenarioIds = selectedScenarios.map((scenario) => scenario._id);

        try {
            const response = await axios.patch(
                `${process.env.REACT_APP_API_URL_LOCAL}/api/folders/${folderId}`,
                { scenarios: selectedScenarioIds }
            );
            const updatedFolder = response.data;
            setFolder(updatedFolder);
            setScenarios(updatedFolder.scenarios);
            updateClassroom(classroomId, { scenarios: selectedScenarioIds });
            setIsScenarioModalOpen(false);
        } catch (error) {
            console.error('Error updating scenarios:', error);
        }
    };

    return (
        <div className={styles.foldersContainer}>
            <FolderModal
                isOpen={isFolderModalOpen}
                onClose={closeModal}
                mode={modalMode}
                folder={currentFolder}
                parentAllowedStudents={parentAllowedStudents}
                parentFolderId={folder?._id}
                classroomId={classroomId}
                onFolderCreated={handleAddSubfolder}
                onFolderUpdated={handleUpdateSubfolder}
                onFolderDeleted={handleDeleteSubfolder}
            />

            <ScenarioModal
                isOpen={isScenarioModalOpen}
                onClose={() => setIsScenarioModalOpen(false)}
                scenarios={userScenarios}
                selectedScenarios={selectedScenarios}
                onScenarioSelect={handleScenarioSelection}
                onSubmit={handleSubmitScenarios}
            />

            <div className={styles.foldersHeader}>
                <h2 className={styles.title}>{folder?.name}</h2>
                {isTeacher && (
                    <div className={styles.buttonContainer}>
                        <img
                            src={addScenarioIcon}
                            className={styles.addScenarioIcon}
                            onClick={handleAddScenarios}
                            alt="Add Scenario"
                        />
                        <img
                            src={addFolderIcon}
                            className={styles.addFolderIcon}
                            onClick={openCreateModal}
                            alt="Add Folder"
                        />
                    </div>
                )}
            </div>

            <div className={styles.foldersBody}>
                {subfolders.map((subfolder) => (
                    <div key={subfolder._id} className={styles.folderItem}>
                        <div
                            onClick={() => handleFolderClick(subfolder)}
                            style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}
                        >
                            <img src={folderIcon} className={styles.folderItemImage} alt="Folder Icon" />
                            <div className={styles.folderName}>{subfolder?.name}</div>
                        </div>
                        {isTeacher && (
                            <img
                                src={settingsIcon}
                                className={styles.optionsButton}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    openEditModal(subfolder);
                                }}
                                alt="Edit Folder"
                            />
                        )}
                    </div>
                ))}

                {scenarios.map((scenario) => (
                    <div
                        key={scenario._id}
                        className={styles.folderItem}
                        onClick={() => navigate(`/view/${scenario._id}`, { state: { hasAccess: true } })}
                    >
                        <img src={scenario?.image} className={styles.folderItemImage} alt="Scenario Icon" />
                        <div className={styles.folderName}>{scenario?.title}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Folder;
