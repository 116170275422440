// CreateClassroomModal.js
import React, { useState, useContext } from 'react';
import styles from './styles/CreateClassroomModal.module.css';
import { ClassroomContext } from '../../context/ClassroomContext';

const CreateClassroomModal = ({ isOpen, onClose, onClassroomCreated }) => {
    const [classroomName, setClassroomName] = useState('');
    const [image, setImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [error, setError] = useState(null);
    const [closing, setClosing] = useState(false);
    const { createClassroom } = useContext(ClassroomContext); // Get createClassroom from context

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        setImage(file);
        setImagePreview(URL.createObjectURL(file));
    };

    const handleCreate = async () => {
        if (!classroomName.trim()) {
            setError('Classroom name cannot be empty.');
            return;
        }

        try {
            const newClassroom = await createClassroom({
                name: classroomName,
                image: image,
            });

            onClassroomCreated(newClassroom._id); // Pass the created classroom ID back
        } catch (error) {
            setError('Failed to create the classroom.');
        }
    };

    const triggerClose = () => {
        setClosing(true);
        setTimeout(() => {
            onClose();
            setClosing(false);
        }, 300);
    };

    if (!isOpen && !closing) return null;

    return (
        <div className={`${styles.modalOverlay} ${closing ? styles.fadeOut : styles.fadeIn}`}>
            <div className={`${styles.modalContent} ${styles.wideModal}`}>
                {/* Modal Header */}
                <div className={styles.modalHeader}>
                    <h2 className={styles.modalTitle}>Create Classroom</h2>
                </div>

                {/* Modal Body */}
                <div className={styles.modalBody}>
                    <input
                        type="text"
                        className={styles.modalInput}
                        value={classroomName}
                        onChange={(e) => setClassroomName(e.target.value)}
                        placeholder="Classroom Name"
                    />

                    <input type="file" onChange={handleImageUpload} />

                    {/* Image Preview */}
                    <div className={styles.imagePreviewContainer}>
                        {imagePreview && (
                            <img src={imagePreview} alt="Classroom Preview" className={styles.imagePreview} />
                        )}
                    </div>

                    {error && <div className={styles.error}>{error}</div>}
                </div>

                {/* Modal Footer */}
                <div className={styles.modalFooter}>
                    <div className={styles.buttonContainer}>
                        <button className={`${styles.buttonBase} ${styles.saveButton}`} onClick={handleCreate}>
                            Create
                        </button>
                        <button className={`${styles.buttonBase} ${styles.cancelButton}`} onClick={triggerClose}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateClassroomModal;
