import React, { useContext, useEffect, useRef } from 'react';
import { useLocation, Routes, Route } from 'react-router-dom';
import styles from './styles/MyClassrooms.module.css';
import MyClassroomsSidebar from './MyClassroomsSidebar';
import MyClassroomsHeader from './MyClassroomsHeader';
import MyClassroomsGrid from './MyClassroomsGrid';
import Classroom from './Classroom';

const MyClassrooms = () => {
    return (
        <div className={styles.myClassroomsContainer}>
            <MyClassroomsHeader />
            <div className={styles.myClassroomsBody}>
                <MyClassroomsSidebar />
                <Routes>
                    <Route path="/" element={<MyClassroomsGrid />} />
                    <Route path="/:classroomId/*" element={<Classroom />} />
                </Routes>
            </div>
        </div>
    );
};

export default MyClassrooms;
