// People.js
import React, { useContext, useState, useEffect } from "react";
import inviteIcon from "../../images/inviteicon.png";
import profileIcon from "../../images/profileicon.png";
import styles from "./styles/People.module.css";
import axios from "axios";
import AuthContext from '../../context/AuthContext';
import { ClassroomContext } from "../../context/ClassroomContext";
import ClassroomInviteModal from "./ClassroomInviteModal";

const People = () => {
    const { userId } = useContext(AuthContext);
    const { classroom, isTeacher, isOwner } = useContext(ClassroomContext);
    const [students, setStudents] = useState(classroom?.students || []);
    const [teachers, setTeachers] = useState(classroom?.teachers || []);

    useEffect(() => {
        if (classroom) {
            setStudents(classroom.students);
            setTeachers(classroom.teachers);
        }
    }, [classroom]);

    const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
    const [role, setRole] = useState(null);

    const inviteUserToClassroom = async (invitedUserId, role) => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL_LOCAL}/api/notifications/sendInvite`, {
                invitedUserId,
                classroomId: classroom._id,
                name: classroom.name,
                role,
            });
        } catch (error) {
            console.error(error);
        }
    };

    const removeUserFromClassroom = async (userIdToRemove, roleToRemove) => {
        try {
            await axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/classrooms/removeUserFromClassroom/${classroom._id}`, {
                userId: userIdToRemove,
                role: roleToRemove,
            });

            if (roleToRemove === 'student') {
                setStudents((prevStudents) => prevStudents.filter((student) => student._id !== userIdToRemove));
            } else if (roleToRemove === 'teacher') {
                setTeachers((prevTeachers) => prevTeachers.filter((teacher) => teacher._id !== userIdToRemove));
            }
        } catch (error) {
            console.error(error);
        }
    };

    const changeUserRole = async (userIdToChange, currentRole) => {
        const newRole = currentRole === 'student' ? 'teacher' : 'student';
        try {
            await axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/classrooms/changeUserRole/${classroom._id}`, {
                userId: userIdToChange,
                role: newRole,
            });

            if (currentRole === 'student') {
                const updatedStudent = students.find(student => student._id === userIdToChange);
                setStudents((prevStudents) => prevStudents.filter((student) => student._id !== userIdToChange));
                setTeachers((prevTeachers) => [...prevTeachers, updatedStudent]);
            } else if (currentRole === 'teacher') {
                const updatedTeacher = teachers.find(teacher => teacher._id === userIdToChange);
                setTeachers((prevTeachers) => prevTeachers.filter((teacher) => teacher._id !== userIdToChange));
                setStudents((prevStudents) => [...prevStudents, updatedTeacher]);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const existingUserIds = [...students, ...teachers].map(user => user._id);

    return (
        <div className={styles.peopleContainer}>
            {isInviteModalOpen && (
                <ClassroomInviteModal
                    isOpen={isInviteModalOpen}
                    onClose={() => setIsInviteModalOpen(false)}
                    role={role}
                    existingUsers={existingUserIds}
                    inviteUserToClassroom={inviteUserToClassroom}
                    classroomId={classroom._id}
                />
            )}
            <div className={styles.categoryContainer}>
                <div className={styles.header}>
                    <h2>Teachers</h2>
                    {isTeacher && (
                        <div onClick={() => { setRole('teacher'); setIsInviteModalOpen(true); }}>
                            <img src={inviteIcon} alt="Invite Icon" className={styles.inviteIcon} />
                        </div>
                    )}
                </div>
                <div className={styles.list}>
                    {teachers?.map((teacher) => (
                        <div key={teacher._id} className={styles.item}>
                            <div className={styles.profileInfo}>
                                <img src={teacher.profilePicture || profileIcon} alt="Teacher Icon" className={styles.profileIcon} />
                                {teacher.username}
                            </div>
                            {/* Only the owner can demote/remove other teachers, and cannot demote/remove themselves */}
                            {isOwner && teacher._id !== userId && (
                                <div className={styles.actions}>
                                    <button onClick={() => changeUserRole(teacher._id, 'teacher')} className={styles.changeRoleButton}>
                                        Demote
                                    </button>
                                    <button onClick={() => removeUserFromClassroom(teacher._id, 'teacher')} className={styles.removeButton}>
                                        Remove
                                    </button>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>

            <div className={styles.categoryContainer}>
                <div className={styles.header}>
                    <h2>Students</h2>
                    {isTeacher && (
                        <div onClick={() => { setRole('student'); setIsInviteModalOpen(true); }}>
                            <img src={inviteIcon} alt="Invite Icon" className={styles.inviteIcon} />
                        </div>
                    )}
                </div>
                <div className={styles.list}>
                    {students?.map((student) => (
                        <div key={student._id} className={styles.item}>
                            <div className={styles.profileInfo}>
                                <img src={student.profilePicture || profileIcon} alt="Student Icon" className={styles.profileIcon} />
                                {student.username}
                            </div>
                            {/* Any teacher can promote/remove students, excluding themselves */}
                            {isTeacher && student._id !== userId && (
                                <div className={styles.actions}>
                                    <button onClick={() => changeUserRole(student._id, 'student')} className={styles.changeRoleButton}>
                                        Promote
                                    </button>
                                    <button onClick={() => removeUserFromClassroom(student._id, 'student')} className={styles.removeButton}>
                                        Remove
                                    </button>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default People;
