import React, { useState, useCallback, useMemo } from 'react';
import axios from 'axios';
import styles from './styles/ViewScenarioTitlePage.module.css';
import { useScenario } from '../../../context/ScenarioContext';
import { transformIframes } from '../../../utils/utils';
import ScenarioComments from './ScenarioComments';

const ViewScenarioTitlePage = ({ userId, incompleteLogs, scenario, setCurrentSituationId, preview, userKeys, setUserKeys, keyScenarioMap }) => {
    const { startLog, setLogId } = useScenario();
    const [commentsVisible, setCommentsVisible] = useState(false);

    const handleStart = useCallback(async () => {
        if (scenario.isLogged && !preview) {
            let existingLog = null;

            if (incompleteLogs) {
                for (const [logId, log] of Object.entries(incompleteLogs)) {
                    if (log.scenarioId === scenario._id) {
                        existingLog = logId;
                        break;
                    }
                }
            }
            if (existingLog) {
                setLogId(existingLog);
            } else {
                await startLog(scenario._id, userId, scenario.creator);
            }
        }

        // Remove keys related to this scenario
        if (userId) {
            await axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/users/${userId}/removeKeys`, { scenarioId: scenario._id });
            // Now fetch updated user keys
            const userResponse = await axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/users/${userId}`);
            setUserKeys(userResponse.data.keys || []);
        } else {
            // Remove keys from session storage
            const storedKeys = JSON.parse(sessionStorage.getItem('userKeys')) || [];
            const updatedKeys = storedKeys.filter(keyId => keyScenarioMap[keyId] !== scenario._id);
            setUserKeys(updatedKeys);
            sessionStorage.setItem('userKeys', JSON.stringify(updatedKeys));
        }

        try {
            const { data: startSituation } = await axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/situations/starting/${scenario._id}`);
            if (startSituation) {
                setCurrentSituationId(startSituation._id);
                if (!preview) {
                    await axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/scenarios/${scenario._id}/views`);
                }
            } else {
                console.error('No starting situation found');
            }
        } catch (error) {
            console.error('Error fetching starting situation:', error);
        }
    }, [scenario._id, scenario.isLogged, preview, incompleteLogs, setCurrentSituationId, startLog, setLogId, userId, userKeys, setUserKeys, keyScenarioMap]);

    const toggleComments = useCallback(() => {
        setCommentsVisible((prev) => !prev);
    }, []);

    const commentSection = useMemo(() => (
        <div className={styles.comments}>
            {commentsVisible ? (
                <ScenarioComments scenarioId={scenario._id} userId={userId} />
            ) : (
                <span className={styles.viewCommentsLink} onClick={toggleComments}>
                    View Comments
                </span>
            )}
        </div>
    ), [commentsVisible, scenario._id, userId, toggleComments]);

    return (
        <div className={styles.container}>
            {scenario.image && (
                <div className={styles.imageContainer}>
                    <img src={scenario.image} alt={scenario.title} className={styles.image} />
                </div>
            )}

            <div className={styles.title}>
                {scenario.title}
            </div>

            <div className={styles.description}>
                <div dangerouslySetInnerHTML={{ __html: transformIframes(scenario.description) }} />
            </div>

            <button
                onClick={handleStart}
                className={styles.button}
            >
                Start
            </button>

            {commentSection}
        </div>
    );
};

export default React.memo(ViewScenarioTitlePage);
