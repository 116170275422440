import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { AiOutlineClose } from 'react-icons/ai';
import styles from './styles/ClassroomLogs.module.css';
import { ClassroomContext } from '../../context/ClassroomContext';

const ClassroomLogs = () => {

    const { classroom } = useContext(ClassroomContext);
    const [students, setStudents] = useState(classroom?.students || []);
    const [scenarios, setScenarios] = useState(classroom?.scenarios || []);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [logsPerPage] = useState(10);
    const [expandedLogs, setExpandedLogs] = useState({});
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [classroomLogs, setClassroomLogs] = useState([]);

    useEffect(() => {
        if (classroom) {
            fetchClassroomLogs();
            setStudents(classroom?.students);
            setScenarios(classroom?.scenarios);
            console.log("students: ", classroom.students);
            console.log("scenarios: ", classroom.scenarios);
        }
    }, [classroom]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const fetchClassroomLogs = () => {
        setLoading(true);
        setError(null);
        axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/logs`, { withCredentials: true })
            .then(res => {
                const studentIds = students.map(student => student._id);
                const scenarioIds = scenarios.map(scenario => scenario._id);
                const filteredLogs = res.data.filter(log =>
                    log.viewer && log.scenario && studentIds.includes(log.viewer._id) && scenarioIds.includes(log.scenario._id)
                );
                const processedLogs = filteredLogs.map(log => ({
                    ...log,
                    processedDecisions: processDecisions(log.decisions, log.viewer)
                }));

                console.log("processed logs: ", processedLogs);
                const orderedScenarios = determineScenarioOrder(scenarios);
                const aggregatedLogs = aggregateLogsByScenario(processedLogs, orderedScenarios);
                setClassroomLogs(aggregatedLogs);
                setLoading(false);
            })
            .catch(err => {
                setError('Error while getting logs');
                setLoading(false);
            });
    };

    const processDecisions = (decisions, viewer) => {
        const processedDecisions = [];
        let currentSituation = null;
        let choicesInputs = [];

        decisions.forEach((decision, index) => {
            if (decision.type === 'situation') {
                if (currentSituation && currentSituation.title !== decision.title) {
                    processedDecisions.push({
                        ...currentSituation,
                        choicesInputs: choicesInputs.join(' | '),
                        viewer: viewer.username,
                        logId: viewer.logId,
                    });
                    choicesInputs = [];
                }
                currentSituation = decision;
            } else if (decision.type === 'choice' || decision.type === 'input') {
                choicesInputs.push(decision.type === 'input' ? decision.userInput : decision.title);
            }
        });

        if (currentSituation) {
            processedDecisions.push({
                ...currentSituation,
                choicesInputs: choicesInputs.join(' | '),
                viewer: viewer.username,
                logId: viewer.logId,
            });
        }

        return processedDecisions;
    };

    const determineScenarioOrder = (scenarios) => {
        const scenarioOrder = {};
        scenarios.forEach(scenario => {
            scenarioOrder[scenario._id] = scenario.logOrder;
            console.log("scenario.logOrder: ", scenario.logOrder);

        });
        return scenarioOrder;
    };

    const aggregateLogsByScenario = (logs, scenarioOrder) => {
        const aggregated = {};
        logs.forEach(log => {
            if (!aggregated[log.scenario._id]) {
                aggregated[log.scenario._id] = {
                    scenario: log.scenario,
                    logs: [],
                    originalLogIds: [],
                };
            }
            aggregated[log.scenario._id].logs.push(...log.processedDecisions);
            aggregated[log.scenario._id].originalLogIds.push(log._id);
        });
    
        console.log("aggregated: ", aggregated);
    
        // Sort the logs within each scenario based on the predefined order if it exists and is not empty
        Object.values(aggregated).forEach(logGroup => {
            const order = scenarioOrder[logGroup.scenario._id];
            logGroup.logs.sort((a, b) => {
                if (Array.isArray(order) && order.length > 0) {
                    const aIndex = order.indexOf(a.id);
                    const bIndex = order.indexOf(b.id);
                    if (aIndex === bIndex) {
                        return a.viewer.localeCompare(b.viewer);
                    }
                    return aIndex - bIndex;
                } else {
                    // Default sorting when no order is present: group by situation title
                    if (a.title === b.title) {
                        return a.viewer.localeCompare(b.viewer);
                    }
                    return a.title.localeCompare(b.title);
                }
            });
        });
    
        return Object.values(aggregated);
    };
    
    


    const handleDeleteLog = (event, classroomLogId) => {
        event.stopPropagation();
        const logGroup = classroomLogs.find(group => group.scenario._id === classroomLogId);
        if (logGroup) {
            const originalLogIds = logGroup.originalLogIds;
            Promise.all(originalLogIds.map(id => axios.delete(`${process.env.REACT_APP_API_URL_LOCAL}/api/logs/${id}`, { withCredentials: true })))
                .then(() => {
                    setClassroomLogs(classroomLogs.filter(group => group.scenario._id !== classroomLogId));
                })
                .catch(err => {
                    setError('Error while deleting log');
                });
        }
    };

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true };
        return new Date(dateString).toLocaleString('en-US', options).replace(',', '');
    };

    const toggleExpandLog = (logIndex) => {
        setExpandedLogs(prevState => ({
            ...prevState,
            [logIndex]: !prevState[logIndex]
        }));
    };

    const indexOfLastLog = currentPage * logsPerPage;
    const indexOfFirstLog = indexOfLastLog - logsPerPage;
    const currentLogs = classroomLogs.slice(indexOfFirstLog, indexOfLastLog);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const getUniqueUsers = (logs) => {
        const uniqueUsers = new Set(logs.map(log => log.viewer));
        return Array.from(uniqueUsers).join(', ');
    };

    return (
        <div className={styles.container}>
            {loading && <p>Loading logs...</p>}
            {error && <p className={styles.error}>{error}</p>}
            {!loading && classroomLogs.length === 0 && <p>No Logs!</p>}
            {currentLogs.map((classroomLog, logIndex) => (
                <div
                    key={logIndex}
                    className={`${isMobile ? styles.logMobile : styles.log} ${expandedLogs[logIndex] ? styles.expanded : ''}`}
                >
                    <h3 onClick={() => toggleExpandLog(logIndex)}>
                        <strong>{classroomLog.scenario ? classroomLog.scenario.title : 'Unknown'}</strong>
                    </h3>
                    <p>Users: {getUniqueUsers(classroomLog.logs)}</p>
                    <AiOutlineClose className={styles.deleteLog} onClick={(event) => handleDeleteLog(event, classroomLog.scenario._id)} />

                    {expandedLogs[logIndex] && (
                        <div>
                            <table className={styles.table}>
                                <thead>
                                    <tr>
                                        <th>Situation</th>
                                        <th>User</th>
                                        <th>Choice / Input</th>
                                        <th>Time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {classroomLog.logs.map((decision, decisionIndex) => (
                                        <tr key={decisionIndex}>
                                            <td>{decision.title}</td>
                                            <td>{decision.viewer}</td>
                                            <td>{decision.choicesInputs}</td>
                                            <td>{formatDate(decision.createdAt)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            ))}
            <div className={styles.pagination}>
                {Array.from({ length: Math.ceil(classroomLogs.length / logsPerPage) }, (_, i) => (
                    <button
                        key={i + 1}
                        onClick={() => paginate(i + 1)}
                        className={currentPage === i + 1 ? styles.activePage : ''}
                    >
                        {i + 1}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default ClassroomLogs;
