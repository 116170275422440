import React, { useEffect, useRef } from 'react';
import { Network } from 'vis-network';

const ViewCanvas = ({ canvas, currentSituationId, isMinimapExpanded }) => {
    const networkRef = useRef(null);
    const networkInstance = useRef(null);

    useEffect(() => {
        const nodes = canvas.nodes.map(node => ({
            id: node.id,
            color: node.id === currentSituationId ? { background: 'yellow' } : {},
        }));

        const edges = canvas.edges.map(edge => {
            const edgeConfig = {
                from: edge.source,
                to: edge.target,
            };

            if (edge.source === edge.target) {
                edgeConfig.selfReferenceSize = 20; // Size of the loop
                edgeConfig.selfReference = {
                    size: 20, // Size of the loop
                    angle: Math.PI / 4, // Angle of the loop
                };
            }

            return edgeConfig;
        });

        const data = { nodes, edges };

        const options = {
            nodes: {
                shape: 'box',
                margin: 10,
                widthConstraint: {
                    maximum: 200,
                },
            },
            edges: {
                arrows: 'to',
                smooth: {
                    type: 'dynamic',
                    roundness: 0.5,
                },
            },
            interaction: {
                dragNodes: false,
                dragView: true,
                zoomView: true,
            },
            layout: {
                improvedLayout: true,
            },
            physics: {
                enabled: true,
                solver: 'forceAtlas2Based',
                forceAtlas2Based: {
                    gravitationalConstant: -50,
                    centralGravity: 0.01,
                    springLength: 200,
                    springConstant: 0.08,
                },
                maxVelocity: 50,
                stabilization: {
                    iterations: 1000,
                    updateInterval: 25,
                },
            },
        };

        if (networkRef.current) {
            networkInstance.current = new Network(networkRef.current, data, options);

            networkInstance.current.on('stabilizationIterationsDone', () => {
                networkInstance.current.setOptions({ physics: false });
                networkInstance.current.fit({
                    nodes: [currentSituationId],
                    animation: false,
                });
                networkInstance.current.focus(currentSituationId, {
                    scale: isMinimapExpanded ? 1.0 : 0.5, // Adjust scale as needed
                    animation: false,
                });
            });
        }

        // Cleanup function to destroy the network instance on component unmount
        return () => {
            if (networkInstance.current) {
                networkInstance.current.destroy();
            }
        };
    }, [canvas, currentSituationId, isMinimapExpanded]);

    // Adjust view on resize or toggle between minimap and expanded view
    useEffect(() => {
        if (networkInstance.current) {
            setTimeout(() => {
                networkInstance.current.fit({
                    nodes: [currentSituationId],
                    animation: false,
                });
                networkInstance.current.focus(currentSituationId, {
                    scale: isMinimapExpanded ? 1.0 : 0.5, // Adjust scale as needed
                    animation: false,
                });
            }, 100); // Adjust delay as needed
        }
    }, [isMinimapExpanded, currentSituationId]);

    return <div ref={networkRef} style={{ height: '100%', width: '100%' }} />;
};

export default ViewCanvas;
