import React, { useState, useEffect } from 'react';
import styles from './styles/EducationTabDropdown.module.css';
import { FaTimes, FaCaretDown, FaCaretRight } from 'react-icons/fa';

const EducationTabDropdown = ({ handleItemClick, handleViewAllClick, handleClose }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const [collapsedSections, setCollapsedSections] = useState({
        byGrade: false,
        bySubject: false,
        testPrep: false,
    });

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleSection = (section) => {
        setCollapsedSections(prevState => ({
            ...prevState,
            [section]: !prevState[section]
        }));
    };

    const closeDropdown = () => {
        if (typeof handleClose === 'function') {
            handleClose();
        }
    };

    const renderCaretIcon = (section) => {
        return collapsedSections[section] ? <FaCaretRight /> : <FaCaretDown />;
    };

    return (
        <div className={styles.dropdownContainer}>
            {isMobile && (
                <div className={styles.mobileHeader}>
                    <div>Education</div>
                    <FaTimes className={styles.closeIcon} onClick={closeDropdown} />
                </div>
            )}
            <div className={styles.columnsContainer}>
                {/* By Grade */}
                <div className={styles.column}>
                    <div className={styles.columnHeader} onClick={() => isMobile && toggleSection('byGrade')}>
                        {isMobile && renderCaretIcon('byGrade')}
                        <div>By Grade</div>
                    </div>
                    {(!collapsedSections.byGrade || !isMobile) && (
                        <ul className={styles.columnList}>
                            <li onClick={() => handleItemClick('Elementary')}>Elementary</li>
                            <li onClick={() => handleItemClick('Middle School')}>Middle School</li>
                            <li onClick={() => handleItemClick('High School')}>High School</li>
                            <li onClick={() => handleItemClick('College')}>College</li>
                        </ul>
                    )}
                </div>
                {/* By Subject */}
                <div className={styles.column}>
                    <div className={styles.columnHeader} onClick={() => isMobile && toggleSection('bySubject')}>
                        {isMobile && renderCaretIcon('bySubject')}
                        <div>By Subject</div>
                    </div>
                    {(!collapsedSections.bySubject || !isMobile) && (
                        <ul className={styles.columnList}>
                            <li onClick={() => handleItemClick('Math')}>Math</li>
                            <li onClick={() => handleItemClick('English')}>English</li>
                            <li onClick={() => handleItemClick('Reading')}>Reading</li>
                            <li onClick={() => handleItemClick('Grammar')}>Grammar</li>
                            <li onClick={() => handleItemClick('Physics')}>Physics</li>
                            <li onClick={() => handleItemClick('Chemistry')}>Chemistry</li>
                            <li onClick={() => handleItemClick('Biology')}>Biology</li>
                            <li onClick={() => handleItemClick('History')}>History</li>
                            <li onClick={() => handleItemClick('Statistics')}>Statistics</li>
                            <li onClick={() => handleItemClick('Computer Science')}>Computer Science</li>
                            <li onClick={() => handleItemClick('Economics')}>Economics</li>
                        </ul>
                    )}
                </div>
                {/* Test Prep */}
                <div className={styles.column}>
                    <div className={styles.columnHeader} onClick={() => isMobile && toggleSection('testPrep')}>
                        {isMobile && renderCaretIcon('testPrep')}
                        <div>Test Prep</div>
                    </div>
                    {(!collapsedSections.testPrep || !isMobile) && (
                        <ul className={styles.columnList}>
                            <li onClick={() => handleItemClick('SAT')}>SAT</li>
                            <li onClick={() => handleItemClick('ACT')}>ACT</li>
                            <li onClick={() => handleItemClick('GRE')}>GRE</li>
                            <li onClick={() => handleItemClick('AP')}>AP</li>
                        </ul>
                    )}
                </div>
            </div>
            {/* View All */}
            <div className={styles.viewAll} onClick={handleViewAllClick}>
                View All
            </div>
        </div>
    );
};

export default EducationTabDropdown;
