import React, { useState, useContext, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import styles from './styles/Account.module.css';
import AccountSidebar from './AccountSidebar';
import AccountBody from './AccountBody';
import AuthContext from '../../context/AuthContext';
import axios from 'axios';
import AccountDropdown from './AccountDropdown';

const Account = () => {
    const location = useLocation();
    const [activeTab, setActiveTab] = useState(location.state?.activeTab || 'Profile');
    const [accountOwner, setAccountOwner] = useState(null);
    const [userIsAccountOwner, setUserIsAccountOwner] = useState(false);
    const {username} = useParams();
    const {user} = useContext(AuthContext);
    const {fromNavbar} = location.state || {};
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);


    /**
     * If we navigate to the Account from the navbar, we automatically set the account owner to the
     * currently logged-in user and set userIsAccountOwner to true, thus allowing the user to access sidebar tabs like
     * ogs, feedback, and settings that other users cannot access.
     * Otherwise, we grab the user object from the username acquired via the params and set the account owner
     * to the fetched object.
     * If the account owner's id is the same as the logged in user's id, we set userIsAccountOwner to true.
     * This useEffect runs every time there is a change in the username in the url.
     */
    useEffect(() => {
        if (fromNavbar) {
            setUserIsAccountOwner(true);
            setAccountOwner(user);
        } else if (username) {
            axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/users/username/${username}`)
                .then(response => {
                    setAccountOwner(response.data);
                    if (response.data._id === user._id) {
                        setUserIsAccountOwner(true);
                    }
            }).catch(error => {
                console.error(`Error: ${error}`);
            });
        } else {
            console.error("No username in params");
        }
    }, [username]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    if(!accountOwner) {
        return <div>Loading...</div>
    }

    return (
        <div className={styles.container}>
            <div className={styles.accountHeader}>
                <h2 className={styles.accountHeaderText}>{userIsAccountOwner ? `${accountOwner.username}'s Account`: 'My Account'}</h2>
            </div>
            <div className={isMobile ? styles.sidebarBodyContainerMobile : styles.sidebarBodyContainer}>
                {isMobile ?
                    <AccountDropdown
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                    />
                        :
                    <AccountSidebar
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        userIsAccountOwner={userIsAccountOwner}
                    />
                }
                
                <AccountBody 
                    isMobile={isMobile}
                    activeTab={activeTab}
                    userIsAccountOwner={userIsAccountOwner}
                    accountOwner={accountOwner}
                    setAccountOwner={setAccountOwner}
                />
            </div>
        </div>
    );
};

export default Account;
