import React, { useState, useEffect, useContext } from "react";
import axios from 'axios';
import styles from "./styles/ClassroomAnnouncements.module.css";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ClassroomContext } from "../../context/ClassroomContext";
import AuthContext from "../../context/AuthContext";
import EditAnnouncementModal from './EditAnnouncementModal';
import { transformIframes } from '../../utils/utils';

const ClassroomAnnouncements = () => {
    const { classroom, updateClassroom, isTeacher } = useContext(ClassroomContext);
    const { userId } = useContext(AuthContext);

    const [announcements, setAnnouncements] = useState(classroom?.announcements || []);
    const [students, setStudents] = useState(classroom?.students || []);

    const [newAnnouncementText, setNewAnnouncementText] = useState(""); 
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [announcementToEdit, setAnnouncementToEdit] = useState(null);
    const [openedDropdown, setOpenedDropdown] = useState(null); // Initialize as null

    useEffect(() => {
        if (classroom?.announcements) {
            setAnnouncements(classroom.announcements);
        }
        if (classroom?.students) {
            setStudents(classroom.students);
        }
    }, [classroom]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (openedDropdown && !event.target.closest(`.${styles.optionsDropdown}`) && !event.target.closest(`.${styles.optionsButton}`)) {
                setOpenedDropdown(null);
            }
        };

        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [openedDropdown]);

    const cleanText = (text) => {
        let cleanedText = text.replace(/<[^>]*>?/gm, '');
        cleanedText = cleanedText.replace(/&nbsp;/gi, ' ');
        return cleanedText;
    };

    const handleNewAnnouncement = async () => {
        if (newAnnouncementText.trim() === "") return;

        const cleanedAnnouncementText = cleanText(newAnnouncementText);
        const newAnnouncement = {
            _id: Date.now().toString(), // Temporary ID for local state
            poster: { _id: userId, username: 'Your Username' }, // Replace with actual username
            text: cleanedAnnouncementText,
        };

        try {
            const updatedAnnouncements = [...announcements, newAnnouncement];
            setAnnouncements(updatedAnnouncements);

            await updateClassroom({ announcements: updatedAnnouncements });

            setNewAnnouncementText("");

            // Send notifications to students
            await axios.post(`${process.env.REACT_APP_API_URL_LOCAL}/api/notifications/sendAnnouncementToStudents`, {
                students: students, 
                classroomId: classroom._id,
                classroomName: classroom.name,
                announcementText: cleanedAnnouncementText,
            });

        } catch (err) {
            console.error("Error posting announcement:", err);
        }
    };

    const handleStopEditing = async (id, newText) => {
        if (newText.trim() === '') return;

        const updatedAnnouncements = announcements.map((announcement) =>
            announcement._id === id ? { ...announcement, text: newText } : announcement
        );

        try {
            setAnnouncements(updatedAnnouncements);

            await axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/classrooms/${classroom._id}`, {
                announcements: updatedAnnouncements
            });
        } catch (err) {
            console.error("An error occurred while updating the announcement:", err);
        }
    };

    const handleDeleteAnnouncement = async (announcementToDelete) => {
        const updatedAnnouncements = announcements.filter(
            (announcement) => announcement._id !== announcementToDelete._id
        );

        try {
            setAnnouncements(updatedAnnouncements);

            await axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/classrooms/${classroom._id}`, {
                announcements: updatedAnnouncements
            });
        } catch (err) {
            console.error("An error occurred while deleting the announcement:", err);
        }
    };

    return (
        <div className={styles.announcementsContainer}>
            {isEditModalOpen && (
                <EditAnnouncementModal
                    isOpen={isEditModalOpen}
                    onClose={() => setIsEditModalOpen(false)}
                    announcement={announcementToEdit}
                    handleStopEditing={handleStopEditing}
                />
            )}
            {isTeacher && (
                <div className={styles.createAnnouncementContainer}>
                    <div>
                        <CKEditor
                            editor={ClassicEditor}
                            data={newAnnouncementText}
                            config={{
                                toolbar: {
                                    items: [
                                        'undo',
                                        'redo',
                                        '|',
                                        'heading',
                                        '|',
                                        'bold',
                                        'italic',
                                        '|',
                                        'link',
                                        'uploadImage',
                                        'blockQuote',
                                        '|',
                                        'bulletedList',
                                        'numberedList',
                                        'outdent',
                                        'indent',
                                    ],
                                    shouldNotGroupWhenFull: true,
                                },
                                ckfinder: {
                                    uploadUrl: `${process.env.REACT_APP_API_URL_LOCAL}/api/images/upload`,
                                },
                                mediaEmbed: {
                                    elementName: 'iframe',
                                },
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setNewAnnouncementText(data);
                            }}
                        />
                    </div>
                    <div className={styles.postAnnouncement}>
                        <button onClick={handleNewAnnouncement}>Submit</button>
                    </div>
                </div>
            )}
            <div className={styles.announcementsGrid}>
                {[...announcements].reverse().map((announcement) => (
                    <div key={announcement._id} className={styles.announcement}>
                        <div className={styles.username}>{announcement.poster.username}</div>
                        <p dangerouslySetInnerHTML={{ __html: transformIframes(announcement.text) }} />
                        {isTeacher && (
                            <button 
                                className={styles.optionsButton} 
                                onClick={() => setOpenedDropdown(openedDropdown === announcement._id ? null : announcement._id)}
                            >
                                ⋮
                            </button>
                        )}
                        {openedDropdown === announcement._id && (
                            <div className={styles.optionsDropdown}>
                                <div 
                                    className={styles.optionsDropdownItem} 
                                    onClick={() => {
                                        setAnnouncementToEdit(announcement);
                                        setIsEditModalOpen(true);
                                        setOpenedDropdown(null);
                                    }}
                                >
                                    Edit
                                </div>
                                <div 
                                    className={styles.optionsDropdownItem} 
                                    onClick={() => handleDeleteAnnouncement(announcement)}
                                >
                                    Delete
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ClassroomAnnouncements;
