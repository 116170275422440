// ClassroomInviteModal.js

import React, { useState } from 'react';
import styles from './styles/ClassroomInviteModal.module.css';
import axios from 'axios';
import profileIcon from "../../images/profileicon.png";
import { FaCopy } from 'react-icons/fa'; // Ensure this is installed

const ClassroomInviteModal = ({ isOpen, onClose, role, existingUsers, inviteUserToClassroom, classroomId }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [error, setError] = useState(null);
    const [hasSearched, setHasSearched] = useState(false);
    const [inviteLink, setInviteLink] = useState('');
    const [linkCopied, setLinkCopied] = useState(false);
    const [closing, setClosing] = useState(false); // State to manage fade-out animation

    const triggerClose = () => {
        setClosing(true);
        setTimeout(() => {
            handleClose();
            setClosing(false);
        }, 300); // Match this timeout with the CSS animation duration (0.3s)
    };

    const handleClose = () => {
        setSelectedUsers([]);
        setSearchResults([]);
        setSearchTerm('');
        setHasSearched(false);
        setError(null);
        setInviteLink('');
        setLinkCopied(false);
        onClose();
    };

    if (!isOpen && !closing) return null;

    const searchUser = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/users/search?username=${searchTerm.trim()}`);
            const filteredResults = response.data.filter(user => !existingUsers.includes(user._id));
            setSearchResults(filteredResults);
            setError(null);
            setHasSearched(true);
        } catch (err) {
            setError(err.response?.data?.message || 'An error occurred during search.');
            setHasSearched(true);
        }
    };

    const toggleSelectUser = (user) => {
        if (selectedUsers.some(selectedUser => selectedUser._id === user._id)) {
            setSelectedUsers(selectedUsers.filter(selectedUser => selectedUser._id !== user._id));
        } else {
            setSelectedUsers([...selectedUsers, user]);
        }
    };

    const handleInvite = () => {
        selectedUsers.forEach(user => {
            inviteUserToClassroom(user._id, role);
        });
        setSelectedUsers([]);
        setSearchResults([]);
        setSearchTerm('');
        setHasSearched(false);
        onClose();
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && searchTerm.trim()) {
            searchUser();
        }
    };

    const generateInviteLink = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL_LOCAL}/api/classrooms/generateInviteLink`, {
                classroomId: classroomId,
                role: role,
            });
            const { inviteLink } = response.data;
            setInviteLink(inviteLink);
            setLinkCopied(false);
        } catch (error) {
            console.error('Error generating invite link:', error);
            setError('Failed to generate invite link.');
        }
    };

    const copyInviteLink = () => {
        navigator.clipboard.writeText(inviteLink)
            .then(() => {
                setLinkCopied(true);
            })
            .catch(err => {
                console.error('Failed to copy invite link:', err);
            });
    };

    return (
        <div className={`${styles.modalOverlay} ${closing ? styles.fadeOut : styles.fadeIn}`}>
            <div className={styles.modalContent}>
                {/* Modal Header */}
                <div className={styles.modalHeader}>
                    <h2 className={styles.modalTitle}>Invite {role === 'student' ? 'Students' : 'Teachers'}</h2>
                </div>

                {/* Modal Body */}
                <div className={styles.modalBody}>
                    {/* Invite Link Section */}
                    <div className={styles.inviteLinkContainer}>
                        <button onClick={generateInviteLink} className={`${styles.buttonBase} ${styles.generateLinkButton}`}>
                            Generate Invite Link
                        </button>
                        {inviteLink && (
                            <div className={styles.inviteLinkDisplay}>
                                <input
                                    type="text"
                                    readOnly
                                    value={inviteLink}
                                    className={styles.inviteLinkInput}
                                />
                                <button onClick={copyInviteLink} className={`${styles.buttonBase} ${styles.copyButton}`}>
                                    <FaCopy />
                                </button>
                                {linkCopied && <span className={styles.copiedText}>Copied!</span>}
                            </div>
                        )}
                    </div>

                    <div className={styles.divider}>Or invite by username</div>

                    {/* Search and Invite by Username */}
                    <div className={styles.searchContainer}>
                        <input
                            type="text"
                            placeholder="Search users by username"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            onKeyPress={handleKeyPress}
                            className={styles.searchInput}
                        />
                        <button
                            onClick={searchUser}
                            className={`${styles.buttonBase} ${styles.searchButton}`}
                            disabled={!searchTerm.trim()}
                        >
                            Search
                        </button>
                    </div>
                    {error && <div className={styles.error}>{error}</div>}
                    {hasSearched && searchResults.length === 0 && !error && (
                        <div className={styles.noResults}>No users found.</div>
                    )}
                    <div className={styles.resultsContainer}>
                        {searchResults.map(user => (
                            <div
                                key={user._id}
                                className={`${styles.userItem} ${
                                    selectedUsers.some(selectedUser => selectedUser._id === user._id)
                                        ? styles.selectedUser
                                        : ''
                                }`}
                                onClick={() => toggleSelectUser(user)}
                            >
                                <img src={user.profilePicture || profileIcon} alt="User Icon" className={styles.profileIcon} />
                                <span>{user.username}</span>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Modal Footer */}
                <div className={styles.modalFooter}>
                    <div className={styles.buttonGroup}>
                        <button
                            onClick={handleInvite}
                            className={`${styles.buttonBase} ${styles.submitButton}`}
                            disabled={selectedUsers.length === 0}
                        >
                            Invite
                        </button>
                        <button onClick={triggerClose} className={`${styles.buttonBase} ${styles.cancelButton}`}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ClassroomInviteModal;
