import React from 'react';
import styles from './styles/CopyScenarioModal.module.css';

const CopyScenarioModal = ({ handleConfirmCopy, handleConfirmCopyStructure, handleCancelCopy }) => {
    return (
        <div className={styles.overlay}>
            <div className={styles.modal}>
                <div className={styles.header}>
                    <h2>Confirm Copy</h2>
                    <button className={styles.closeButton} onClick={handleCancelCopy}>&times;</button>
                </div>
                <div className={styles.body}>
                    <p>How do you want to copy this scenario?</p>
                </div>
                <div className={styles.footer}>
                    <button className={styles.secondaryButton} onClick={handleCancelCopy}>Cancel</button>
                    <button className={styles.dangerButton} onClick={handleConfirmCopyStructure}>Copy Structure</button>
                    <button className={styles.dangerButton} onClick={handleConfirmCopy}>Copy Everything</button>
                </div>
            </div>
        </div>
    );
};

export default CopyScenarioModal;
