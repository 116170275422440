import React, { useCallback, useMemo, useState, useEffect, useRef, useContext } from 'react';
import ReactFlow, { applyNodeChanges, applyEdgeChanges, Controls, Background, useReactFlow } from 'react-flow-renderer';
import SituationNode from './SituationNode';
import ErrorOverlay from './ErrorOverlay';
import styles from './styles/Canvas.module.css';
import CanvasContext from '../../../context/CanvasContext';
import Edge from './Edge';

const addEdge = (newEdge, edges) => {
    return [...edges, newEdge];
};

const Canvas = ({ setRenderEditSidebar, isMobile }) => {
    const {
        nodes, setNodes, edges, setEdges, updateNodes, updateEdges, scenario,
        selectedEdgeId, selectedNodeIds, onSelectNode, onSelectNodes, onSelectEdge,
        onCanvasClick, createSituation, createChoice, getSituation, getChoice, setGetZoomFunc,
        setProjectFunc, setReactFlowWrapper, edgeConnectingNodeId, setEdgeConnectingNodeId,
    } = useContext(CanvasContext);

    const { project, getZoom } = useReactFlow();
    const reactFlowWrapper = useRef(null);

    // State to track edge creation on mobile
    const [edgeSourceNodeId, setEdgeSourceNodeId] = useState(null);
    const lastTapRef = useRef(0); // To detect double taps

    useEffect(() => {
        setGetZoomFunc(() => getZoom);
        setProjectFunc(() => project);
        setReactFlowWrapper(reactFlowWrapper);
    }, [getZoom, project]);

    const onNodesChange = useCallback(
        (changes) => {
            setNodes((currentNodes) => {
                const updatedNodes = applyNodeChanges(changes, currentNodes);

                const positionChange = changes.find(change => change.type === 'position' && !change.dragging);

                if (positionChange) {
                    updateNodes(updatedNodes);
                }

                return updatedNodes;
            });
        },
        [setNodes, edges, updateNodes] 
    );

    const onEdgesChange = useCallback(
        (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
        [setEdges]
    );

    const onConnect = useCallback(async (params) => {
        try {
            const { source, target } = params;
            const hasHint = (source === target);

            // Use createChoice from the context
            const newChoice = await createChoice(source, target, hasHint);

            const newEdge = {
                id: newChoice._id,
                source,
                target,
                type: 'choice',
                data: {
                    label: `untitled`,
                    choiceType: hasHint ? 'hint' : ''
                },
            };

            const updatedEdges = addEdge(newEdge, edges);
            setEdges(updatedEdges);
            await getChoice(newEdge.id);
            await updateEdges(updatedEdges);
            if (!isMobile) {
                setRenderEditSidebar(true);
            }
        } catch (error) {
            console.error(`Error creating a new choice: ${error}`);
        }
    }, [createChoice, edges, setEdges, updateEdges, nodes, isMobile]);

    const onEdgeClick = async (event, edge) => {
        await getChoice(edge.id);
    };

    const onNodeClick = (event, node) => {
        const { id } = node;
        const currentTime = new Date().getTime();
        const tapLength = currentTime - lastTapRef.current;
        const doubleTapThreshold = 300; // milliseconds
    
        if (isMobile) {
            if (tapLength && tapLength < doubleTapThreshold) {
                // Double tap detected
                if (edgeConnectingNodeId === id) {
                    // Cancel edge-connecting mode
                    setEdgeConnectingNodeId(null);
                } else {
                    // Enter edge-connecting mode
                    setEdgeConnectingNodeId(id);
                }
            } else {
                // Single tap
                if (edgeConnectingNodeId) {
                    // We are in edge-connecting mode
                    // Allow connecting a node to itself
                    onConnect({ source: edgeConnectingNodeId, target: id });
                    setEdgeConnectingNodeId(null); // Reset edge-connecting mode
                } else {
                    // Regular node selection
                    getSituation(id);
                    const alreadySelected = selectedNodeIds.includes(id);
                    if (alreadySelected) {
                        onCanvasClick();
                    } else {
                        onSelectNode(id);
                    }
                }
            }
            lastTapRef.current = currentTime;
        } else {
            // Desktop behavior
            const alreadySelected = selectedNodeIds.includes(id);
    
            if (event.ctrlKey || event.metaKey) {
                if (alreadySelected) {
                    const newSelectedNodeIds = selectedNodeIds.filter(nId => nId !== id);
                    onSelectNodes(newSelectedNodeIds);
                } else {
                    const newSelectedNodeIds = [...selectedNodeIds, id];
                    onSelectNodes(newSelectedNodeIds); 
                }
            } else {
                getSituation(id);
                if (alreadySelected) {
                    onCanvasClick();
                } else {
                    onSelectNode(id);
                }
            }
        }
    };
    

    const onDrop = useCallback(async (event) => {
        event.preventDefault();
        const reactFlowBounds = event.target.getBoundingClientRect();
        const nodeType = event.dataTransfer.getData('application/reactflow');
        const zoomLevel = getZoom();
    
        if (nodeType) {
            try {
                const nodeWidth = 120 * zoomLevel;
                const nodeHeight = 50 * zoomLevel;
                const clientX = event.clientX - reactFlowBounds.left - (nodeWidth / 2);
                const clientY = event.clientY - reactFlowBounds.top - (nodeHeight / 2);
    
                const position = project({ x: clientX, y: clientY });
    
                // Prepare the new situation data
                const newSituationData = {
                    isLogged: scenario.isLogged,
                    text: '', // Set other properties as needed
                    isStart: nodeType === 'startNode' ? true : false,
                    isEnd: nodeType === 'endNode' ? true : false,
                };
    
                // Call createSituation with the prepared data
                const newSituation = await createSituation(newSituationData);
    
                // Determine the endpointType based on nodeType
                const endpointType =
                    nodeType === 'startNode' ? 'start' :
                    nodeType === 'endNode' ? 'end' :
                    undefined;
    
                // Create the new node
                const newNode = {
                    id: newSituation._id,
                    type: 'situation',
                    position: position,
                    data: {
                        label: newSituation.text || 'Untitled',
                        endpointType: endpointType,
                    },
                };
    
                // Update the nodes and canvas
                const updatedNodes = [...nodes, newNode];
                setNodes(updatedNodes);
                updateNodes(updatedNodes);
                setRenderEditSidebar(true);
    
            } catch (error) {
                console.error(`Error creating a new situation: ${error}`);
            }
        }
    }, [nodes, scenario, updateNodes, getZoom, setNodes, createSituation]);

    const nodeTypes = useMemo(() => ({
        situation: (nodeProps) => {
            const isSelected = selectedNodeIds.includes(nodeProps.id);
            const isEdgeConnecting = edgeConnectingNodeId === nodeProps.id;
            return <SituationNode {...nodeProps} selected={isSelected} edgeConnecting={isEdgeConnecting} />;
        },
    }), [selectedNodeIds, edgeConnectingNodeId]);    

    const edgeTypes = useMemo(() => ({
        choice: (edgeProps) => (
            <Edge
                {...edgeProps}
            />
        ),
    }), [selectedEdgeId]);

    return (
        <div
            onDrop={onDrop}
            onDragOver={(event) => event.preventDefault()}
            className={styles.container}
            ref={reactFlowWrapper}
        >
            <ReactFlow
                nodes={nodes}
                onNodesChange={onNodesChange}
                edges={edges}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                onEdgeClick={onEdgeClick}
                onPaneClick={onCanvasClick}
                onNodeClick={onNodeClick}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                deleteKeyCode={null}
            >
                <Controls />
                <Background color="#aaa" gap={16} />
            </ReactFlow>
            <ErrorOverlay
                onSelectNode={onSelectNode}
                onSelectEdge={onSelectEdge}
            />
        </div>
    );
};

export default Canvas;
