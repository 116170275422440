// ScenarioModal.js

import React, { useState } from 'react';
import styles from './styles/ScenarioModal.module.css';

const ScenarioModal = ({
    isOpen,
    onClose,
    scenarios,
    selectedScenarios,
    onScenarioSelect,
    onSubmit,
}) => {
    const [closing, setClosing] = useState(false);
    const [searchTerm, setSearchTerm] = useState(''); // New state for search term

    const triggerClose = () => {
        setClosing(true);
        setTimeout(() => {
            onClose();
            setClosing(false);
        }, 300); // Match this timeout with the CSS animation duration (0.3s)
    };

    if (!isOpen && !closing) return null;

    // Filter scenarios based on the search term
    const filteredScenarios = scenarios.filter((scenario) =>
        scenario.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className={`${styles.modalOverlay} ${closing ? styles.fadeOut : styles.fadeIn}`}>
            <div className={styles.modalContent}>
                {/* Modal Header */}
                <div className={styles.modalHeader}>
                    <h2 className={styles.modalTitle}>Add Scenarios</h2>
                </div>

                {/* Modal Body */}
                <div className={styles.modalBody}>
                    {/* Search Input */}
                    <div className={styles.searchContainer}>
                        <input
                            type="text"
                            placeholder="Search scenarios..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className={styles.searchInput}
                        />
                    </div>

                    {/* Scenarios List */}
                    <ul className={styles.list}>
                        {filteredScenarios.map((scenario) => (
                            <li
                                key={scenario._id}
                                onClick={() => onScenarioSelect(scenario)}
                                className={`${styles.item} ${
                                    selectedScenarios.some((s) => s._id === scenario._id)
                                        ? styles.selectedItem
                                        : ''
                                }`}
                            >
                                {scenario.title}
                            </li>
                        ))}
                    </ul>
                </div>

                {/* Modal Footer */}
                <div className={styles.modalFooter}>
                    <div className={styles.buttonContainer}>
                        <button
                            className={`${styles.buttonBase} ${styles.saveButton}`}
                            onClick={onSubmit}
                        >
                            Add Selected Scenarios
                        </button>
                        <button
                            className={`${styles.buttonBase} ${styles.cancelButton}`}
                            onClick={triggerClose}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ScenarioModal;
