import React from 'react';
import Login from './Login';
import styles from './styles/AuthModal.module.css';
import Register from './Register';
import ForgotPassword from './ForgotPassword';
import VerifyEmail from './VerifyEmail';
import { AiOutlineClose } from 'react-icons/ai';

// pass in states from nav bar (prop) *pass setAuthModalState into login
const AuthModal = ({ showModal, setShowModal, authModalState, setAuthModalState, acceptInvite, setAcceptInvite }) => {

const exitAuthModal = () => {
    setShowModal(false);
}

    return (
        <div className={styles.overlay}>
            <div className={styles.modal}>
                {!acceptInvite && <AiOutlineClose className={styles.exitModal} onClick={exitAuthModal}/>}
                {(authModalState === 'login') && (
                    <Login 
                        authModalState={authModalState} setAuthModalState={setAuthModalState}
                        showModal={showModal} setShowModal={setShowModal} acceptInvite={acceptInvite} setAcceptInvite={setAcceptInvite}
                    />
                )} 
                {(authModalState === 'sign up') && (
                    <Register 
                        authModalState={authModalState} setAuthModalState={setAuthModalState}
                        showModal={showModal} setShowModal={setShowModal} acceptInvite={acceptInvite} setAcceptInvite={setAcceptInvite}
                    />
                )} 
                {(authModalState === 'forgot password') && (
                    <ForgotPassword 
                        setAuthModalState={setAuthModalState}
                    />
                )} 
                {(authModalState === 'verify email') && (
                    <VerifyEmail/>
                )}
            </div>
        </div>
    )
}

export default AuthModal