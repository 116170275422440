import React, { useState } from 'react';
import styles from './styles/AccountDropdown.module.css';

const AccountDropdown = ({ activeTab, setActiveTab }) => {
    const handleTypeChange = (e) => {
        setActiveTab(e.target.value);
    };

    return (
        <select value={activeTab} onChange={handleTypeChange} className={styles.searchSelect}>
            <option value="Profile">Profile</option>
            <option value="Scenarios">Scenarios</option>
            <option value="Logs">Logs</option>
            <option value="Feedback">Feedback</option>
            <option value="Settings">Settings</option>
        </select>
    );
};

export default AccountDropdown;
