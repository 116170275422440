import React, { useState, useEffect } from 'react';
import styles from './styles/CategoryModal.module.css';
import axios from 'axios';

const CategoryModal = ({ onClose, scenarios, accountOwner, setAccountOwner, category }) => {
    const [label, setLabel] = useState(category ? category.label : '');
    const [color, setColor] = useState(category ? category.color : '#000000');
    const [selectedScenarios, setSelectedScenarios] = useState(category ? category.scenarios : []);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredScenarios, setFilteredScenarios] = useState([]);

    useEffect(() => {
        const sortedScenarios = [...scenarios].sort((a, b) =>
            a.title.localeCompare(b.title)
        );
    
        let filtered = sortedScenarios;
    
        if (searchQuery) {
            const query = searchQuery.trim().toLowerCase();
    
            if (query.startsWith('#')) {
                const tag = query.slice(1);
                filtered = filtered.filter(scenario =>
                    scenario.tags.some(tagItem => tagItem.toLowerCase().includes(tag))
                );
            } else {
                filtered = filtered.filter(scenario =>
                    scenario.title.toLowerCase().includes(query)
                );
            }
        }
    
        setFilteredScenarios(filtered);
    }, [searchQuery, scenarios]);
    

    const handleLabelChange = (event) => {
        setLabel(event.target.value);
    };

    const handleColorChange = (event) => {
        setColor(event.target.value);
    };

    const handleScenarioClick = (scenarioId) => {
        setSelectedScenarios(prevSelected =>
            prevSelected.includes(scenarioId)
                ? prevSelected.filter(id => id !== scenarioId)
                : [...prevSelected, scenarioId]
        );
    };

    const handleSaveCategory = () => {
        const newCategory = {
            label,
            color,
            scenarios: selectedScenarios
        };

        const updatedCategories = category
            ? accountOwner.categories.map(cat =>
                cat.label === category.label ? newCategory : cat
            )
            : [...accountOwner.categories, newCategory];

        axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/users/${accountOwner._id}`, {
            categories: updatedCategories
        }).then(response => {
            setAccountOwner(response.data);
            onClose();
        }).catch(error => {
            console.error('Error saving category:', error);
        });
    };

    const handleDeleteCategory = () => {
        const updatedCategories = accountOwner.categories.filter(cat => cat.label !== category.label);

        axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/users/${accountOwner._id}`, {
            categories: updatedCategories
        }).then(response => {
            setAccountOwner(response.data);
            onClose();
        }).catch(error => {
            console.error('Error deleting category:', error);
        });
    };

    return (
        <div className={styles.backdrop}>
            <div className={styles.modalContent}>
                <div className={styles.topSection}>
                    <div className={styles.leftHalf}>
                        <h2>{category ? 'Edit Category' : 'Create Category'}</h2>
                        <input
                            type="text"
                            placeholder="Category Label"
                            value={label}
                            onChange={handleLabelChange}
                            className={styles.inputField}
                        />
                        <input
                            type="color"
                            value={color}
                            onChange={handleColorChange}
                            className={styles.colorPicker}
                        />
                        {category && (
                            <button onClick={handleDeleteCategory} className={styles.deleteButton}>
                                Delete Category
                            </button>
                        )}
                    </div>
                    <div className={styles.rightHalf}>
                        <input
                            type="text"
                            placeholder="Search scenarios..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className={styles.inputField}
                        />
                        <div className={styles.scenarioList}>
                            {filteredScenarios.map(scenario => (
                                <div
                                    key={scenario._id}
                                    className={`
                                        ${styles.scenarioItem} 
                                        ${selectedScenarios.includes(scenario._id) ? styles.selected : ''}
                                    `}
                                    onClick={() => handleScenarioClick(scenario._id)}
                                >
                                    <img src={scenario.image} alt={scenario.title} className={styles.scenarioImage} />
                                    <p>{scenario.title}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className={styles.bottomSection}>
                    <button onClick={onClose} className={styles.closeButton}>Close</button>
                    <button onClick={handleSaveCategory} className={styles.saveButton}>
                        {category ? 'Save Changes' : 'Create Category'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CategoryModal;
