import React, { useContext, useEffect, useState } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import styles from './styles/ClassroomAbout.module.css';
import { ClassroomContext } from '../../context/ClassroomContext';
import { transformIframes } from "../../utils/utils";

const ClassroomAbout = () => {
    const { classroom, isOwner, updateClassroom } = useContext(ClassroomContext);

    const [aboutText, setAboutText] = useState(classroom.about || '');
    const [isEditing, setIsEditing] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    // On initial load, set the local `aboutText` state from the classroom context
    useEffect(() => {
        if (classroom?.about) {
            setAboutText(classroom.about);
        }
    }, [classroom?.about]);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveClick = async () => {
        setIsSaving(true);
        try {
            await updateClassroom(classroom?._id, { about: aboutText });
            setIsEditing(false);
        } catch (error) {
            console.error(error);
        } finally {
            setIsSaving(false);
        }
    };

    const handleCancelClick = () => {
        setAboutText(classroom.about || '');
        setIsEditing(false);
    };

    return (
        <div className={styles.classroomAbout}>
            {isEditing ? (
                <div>
                    <CKEditor
                        editor={ClassicEditor}
                        data={aboutText}
                        config={{
                            toolbar: {
                                items: [
                                    'undo',
                                    'redo',
                                    '|',
                                    'heading',
                                    '|',
                                    'bold',
                                    'italic',
                                    '|',
                                    'link',
                                    'uploadImage',
                                    'blockQuote',
                                    '|',
                                    'bulletedList',
                                    'numberedList',
                                    'outdent',
                                    'indent',
                                ],
                                shouldNotGroupWhenFull: true,
                            },
                            ckfinder: {
                                uploadUrl: `${process.env.REACT_APP_API_URL_LOCAL}/api/images/upload`,
                            },
                            mediaEmbed: {
                                elementName: 'iframe',
                            },
                        }}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            setAboutText(data);
                        }}
                    />
                    <div className={styles.buttonGroup}>
                        <button
                            onClick={handleSaveClick}
                            className={styles.saveButton}
                            disabled={isSaving}
                        >
                            {isSaving ? 'Saving...' : 'Save'}
                        </button>
                        <button
                            onClick={handleCancelClick}
                            className={styles.cancelButton}
                            disabled={isSaving}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            ) : (
                <div>
                    <div
                        className={styles.aboutContent}
                        dangerouslySetInnerHTML={{ __html: transformIframes(aboutText) }}
                    />
                    {isOwner && (
                        <button onClick={handleEditClick} className={styles.editButton}>
                            Edit
                        </button>
                    )}
                </div>
            )}
        </div>
    );
};

export default ClassroomAbout;
