import React, { useContext } from 'react';
import styles from './styles/EditSidebar.module.css';
import CanvasContext from '../../../context/CanvasContext';
import EditSituation from './EditSituation';
import EditChoice from './EditChoice';
import EditGroup from './EditGroup';

const EditSidebar = ({ setRenderEditSidebar }) => {
    const { selectedSituation, selectedEdgeId, selectedGroup } = useContext(CanvasContext);

    return (
        <div className={styles.container}>
            {selectedSituation && <EditSituation setRenderEditSidebar={setRenderEditSidebar} />}
            {selectedEdgeId && <EditChoice setRenderEditSidebar={setRenderEditSidebar} />}
            {selectedGroup && <EditGroup setRenderEditSidebar={setRenderEditSidebar} />}
            
            {!selectedSituation && !selectedEdgeId && !selectedGroup && (
                <div className={styles.noSelection}>
                    No Element Selected
                </div>
            )}
        </div>
    );
};

export default EditSidebar;
