import React, { useContext, useState, useEffect } from 'react';
import CanvasContext from '../../../context/CanvasContext';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKFinderUploadAdapter, MediaEmbed } from '@ckeditor/ckeditor5-build-classic';
import EditSidebarTabs from './EditSidebarTabs';
import StyledSwitch from './StyledSwitch';
import textStyles from './styles/EditSidebarText.module.css';
import settingsStyles from './styles/EditSidebarSettings.module.css';
import keyStyles from './styles/EditSidebarKeys.module.css'; // Reusing styles
import LinkSituation from './LinkSituation';
import DeleteModal from './DeleteModal';
import useIsMobile from '../../../utils/useIsMobile';

const EditChoice = ({ setRenderEditSidebar }) => {
    const {
        updateChoice,
        deleteChoice,
        selectedChoice,
        updateEdges,
        selectedEdgeId,
        edges,
        activeEditTab,
        keys,
    } = useContext(CanvasContext);

    const [title, setTitle] = useState(selectedChoice.title || '');
    const [text, setText] = useState(selectedChoice.text || '');
    const [hasHint, setHasHint] = useState(selectedChoice.hasHint || false);
    const [hintText, setHintText] = useState(selectedChoice.hint || '');
    const [nextSituation, setNextSituation] = useState(selectedChoice.nextSituation || null);

    // State for keys granted by the choice
    const [grantsKeys, setGrantsKeys] = useState(selectedChoice.grantsKeys || []);
    const [isGrantingKey, setIsGrantingKey] = useState(false);

    // State for visibility conditions
    const [visibilityKey, setVisibilityKey] = useState(selectedChoice.key || null);
    const [isSelectingVisibilityKey, setIsSelectingVisibilityKey] = useState(false);
    const [showIfKey, setShowIfKey] = useState(selectedChoice.showIfKey || false);
    const [hideIfKey, setHideIfKey] = useState(selectedChoice.hideIfKey || false);

    // Modal
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const isMobile = useIsMobile();

    useEffect(() => {
        if (selectedChoice) {
            setTitle(selectedChoice.title || '');
            setText(selectedChoice.text || '');
            setHasHint(selectedChoice.hasHint || false);
            setHintText(selectedChoice.hint || '');
            setNextSituation(selectedChoice.nextSituation || null);
            setGrantsKeys(selectedChoice.grantsKeys || []);
            setVisibilityKey(selectedChoice.key || null);
            setShowIfKey(selectedChoice.showIfKey || false);
            setHideIfKey(selectedChoice.hideIfKey || false);
        }
    }, [selectedChoice]);

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };

    const handleTitleBlur = async () => {
        if (title !== selectedChoice.title) {
            try {
                await updateChoice({ title: title });
                const updatedEdges = edges.map((edge) =>
                    edge.id === selectedEdgeId ? { ...edge, data: { ...edge.data, label: title } } : edge
                );
                await updateEdges(updatedEdges);
            } catch (error) {
                console.error('Error updating choice or canvas: ', error);
            }
        }
    };

    // Separate handler for the main text editor
    const handleTextEditorChange = (data) => {
        setText(data);
    };

    const handleTextEditorBlur = async () => {
        if (text !== selectedChoice.text) {
            try {
                await updateChoice({ text: text });
            } catch (error) {
                console.error('Error updating choice text: ', error);
            }
        }
    };

    // Separate handler for the hint text editor
    const handleHintEditorChange = (data) => {
        setHintText(data);
    };

    const handleHintEditorBlur = async () => {
        if (hintText !== selectedChoice.hint) {
            try {
                await updateChoice({ hint: hintText });
            } catch (error) {
                console.error('Error updating hint text: ', error);
            }
        }
    };

    // Functions for handling keys granted
    const handleAddKey = async (keyId) => {
        try {
            const updatedGrantsKeys = [...grantsKeys, keyId];
            const updatedChoice = await updateChoice({ grantsKeys: updatedGrantsKeys });
            setGrantsKeys(updatedChoice.grantsKeys || []);
            setIsGrantingKey(false);
        } catch (error) {
            console.error('Error adding key:', error);
        }
    };

    const handleRemoveKey = async (keyId) => {
        try {
            const updatedGrantsKeys = grantsKeys.filter((id) => id !== keyId);
            const updatedChoice = await updateChoice({ grantsKeys: updatedGrantsKeys });
            setGrantsKeys(updatedChoice.grantsKeys || []);
        } catch (error) {
            console.error('Error removing key:', error);
        }
    };

    // Functions for handling visibility conditions
    const handleSelectVisibilityKey = async (keyId) => {
        try {
            const updatedChoice = await updateChoice({ key: keyId });
            setVisibilityKey(updatedChoice.key || null);
            setIsSelectingVisibilityKey(false);
    
            // Update the edge data
            const updatedEdges = edges.map((edge) => {
                if (edge.id === selectedEdgeId) {
                    return {
                        ...edge,
                        data: {
                            ...edge.data,
                            key: keyId,
                        },
                    };
                }
                return edge;
            });
            updateEdges(updatedEdges);
        } catch (error) {
            console.error('Error setting visibility key:', error);
        }
    };
    

    const handleRemoveVisibilityKey = async () => {
        try {
            const updatedChoice = await updateChoice({ key: null, showIfKey: false, hideIfKey: false });
            setVisibilityKey(null);
            setShowIfKey(false);
            setHideIfKey(false);
    
            // Update the edge data
            const updatedEdges = edges.map((edge) => {
                if (edge.id === selectedEdgeId) {
                    return {
                        ...edge,
                        data: {
                            ...edge.data,
                            key: null,
                            showIfKey: false,
                            hideIfKey: false,
                        },
                    };
                }
                return edge;
            });
            updateEdges(updatedEdges);
        } catch (error) {
            console.error('Error removing visibility key:', error);
        }
    };    

    const handleSwitchChange = async (setting, value) => {
        try {
            // Enforce NAND logic: if one is set to true, set the other to false
            let updates = { [setting]: value };
            if (value) {
                if (setting === 'showIfKey') {
                    updates.hideIfKey = false;
                    setHideIfKey(false);
                } else if (setting === 'hideIfKey') {
                    updates.showIfKey = false;
                    setShowIfKey(false);
                }
            }
    
            const updatedChoice = await updateChoice(updates);
            if (updatedChoice) {
                if (updates.hasOwnProperty('showIfKey')) setShowIfKey(updatedChoice.showIfKey);
                if (updates.hasOwnProperty('hideIfKey')) setHideIfKey(updatedChoice.hideIfKey);
    
                // Update the edge data
                const updatedEdges = edges.map((edge) => {
                    if (edge.id === selectedEdgeId) {
                        return {
                            ...edge,
                            data: {
                                ...edge.data,
                                showIfKey: updatedChoice.showIfKey,
                                hideIfKey: updatedChoice.hideIfKey,
                            },
                        };
                    }
                    return edge;
                });
                updateEdges(updatedEdges);
            }
        } catch (error) {
            console.error(`Error updating ${setting}:`, error);
        }
    };
    

    const handleDeleteChoice = () => {
        setIsDeleteModalOpen(true);
    };

    const confirmDeleteChoice = async () => {
        try {
            await deleteChoice(selectedEdgeId);

            const updatedEdges = edges.filter((edge) => edge.id !== selectedEdgeId);
            await updateEdges(updatedEdges);

            setIsDeleteModalOpen(false);

            if(isMobile) {
                setRenderEditSidebar(false);
            }
        } catch (error) {
            console.error('Error deleting choice:', error);
        }
    };

    const cancelDeleteChoice = () => {
        setIsDeleteModalOpen(false);
    };

    return (
        <>
            <EditSidebarTabs editType='choice' />
            {activeEditTab === '1' && (
                <div className={textStyles.textContainer}>
                    <input
                        type='text'
                        value={title}
                        onChange={handleTitleChange}
                        onBlur={handleTitleBlur}
                        className={textStyles.titleInput}
                    />
                    <CKEditor
                        editor={ClassicEditor}
                        data={text}
                        plugins={[CKFinderUploadAdapter, MediaEmbed]}
                        config={{
                            toolbar: {
                                items: [
                                    'undo',
                                    'redo',
                                    '|',
                                    'heading',
                                    '|',
                                    'bold',
                                    'italic',
                                    '|',
                                    'link',
                                    'uploadImage',
                                    'blockQuote',
                                    '|',
                                    'bulletedList',
                                    'numberedList',
                                    'outdent',
                                    'indent',
                                ],
                                shouldNotGroupWhenFull: true,
                            },
                            ckfinder: {
                                uploadUrl: `${process.env.REACT_APP_API_URL_LOCAL}/api/images/upload`,
                            },
                            mediaEmbed: {
                                elementName: 'iframe',
                            },
                        }}
                        onChange={(event, editor) => handleTextEditorChange(editor.getData())}
                        onBlur={handleTextEditorBlur}
                    />
                </div>
            )}

            {activeEditTab === '2' && (
                <>
                    <LinkSituation nextSituation={nextSituation} />
                </>
            )}

            {activeEditTab === '3' && (
                <div className={settingsStyles.settingsContainer}>
                    <div className={settingsStyles.settingItem}>
                        <label>Enable Hint</label>
                        <StyledSwitch
                            onChange={(value) => {
                                setHasHint(value);
                                updateChoice({ hasHint: value });
                            }}
                            checked={hasHint}
                            disabled={
                                !selectedChoice?.nextSituation ||
                                selectedChoice?.nextSituation?._id !== selectedChoice?.situation
                            } // Safeguard: Disable if nextSituation doesn't exist
                        />
                    </div>

                    {hasHint && (
                        <div className={settingsStyles.expandedSettingItem}>
                            <CKEditor
                                editor={ClassicEditor}
                                data={hintText}
                                plugins={[CKFinderUploadAdapter, MediaEmbed]}
                                config={{
                                    toolbar: {
                                        items: [
                                            'undo',
                                            'redo',
                                            '|',
                                            'heading',
                                            '|',
                                            'bold',
                                            'italic',
                                            '|',
                                            'link',
                                            'uploadImage',
                                            'blockQuote',
                                            '|',
                                            'bulletedList',
                                            'numberedList',
                                            'outdent',
                                            'indent',
                                        ],
                                        shouldNotGroupWhenFull: true,
                                    },
                                    ckfinder: {
                                        uploadUrl: `${process.env.REACT_APP_API_URL_LOCAL}/api/images/upload`,
                                    },
                                    mediaEmbed: {
                                        elementName: 'iframe',
                                    },
                                }}
                                onChange={(event, editor) => handleHintEditorChange(editor.getData())}
                                onBlur={handleHintEditorBlur}
                            />
                        </div>
                    )}
                    {/* Delete Choice Button */}
                    <button onClick={handleDeleteChoice} className={textStyles.deleteButton}>
                        Delete Choice
                    </button>
                </div>
            )}

            {activeEditTab === '4' && (
                <div className={keyStyles.keysContainer}>
                    {/* Keys Granted Section */}
                    <h3>Keys Given</h3>
                    {grantsKeys.length > 0 ? (
                        <ul className={keyStyles.grantedKeysList}>
                            {grantsKeys.map((keyId) => {
                                const key = keys.find((k) => k._id === keyId);
                                return (
                                    <li key={keyId} className={keyStyles.grantedKeyItem}>
                                        <span>{key ? key.name : 'Unknown Key'}</span>
                                        <button
                                            onClick={() => handleRemoveKey(keyId)}
                                            className={keyStyles.removeKeyButton}
                                        >
                                            Remove
                                        </button>
                                    </li>
                                );
                            })}
                        </ul>
                    ) : (
                        <p>No keys are given when this choice is made.</p>
                    )}
                    {!isGrantingKey && (
                        <button onClick={() => setIsGrantingKey(true)} className={keyStyles.grantKeyButton}>
                            Give Key
                        </button>
                    )}

                    {isGrantingKey && (
                        <div className={keyStyles.availableKeysContainer}>
                            <h4>Select a key to give:</h4>
                            <ul className={keyStyles.availableKeysList}>
                                {keys
                                    .filter((k) => !grantsKeys.includes(k._id))
                                    .map((key) => (
                                        <li
                                            key={key._id}
                                            className={keyStyles.availableKeyItem}
                                            onClick={() => handleAddKey(key._id)}
                                        >
                                            <div className={keyStyles.keyName}>{key.name}</div>
                                            <div className={keyStyles.keyDescription}>{key.description}</div>
                                        </li>
                                    ))}
                            </ul>
                            <button
                                onClick={() => setIsGrantingKey(false)}
                                className={keyStyles.cancelGrantButton}
                            >
                                Cancel
                            </button>
                        </div>
                    )}

                    {/* Visibility Conditions Section */}
                    <div className={keyStyles.visibilityContainer}>
                        <h3>Visibility Conditions</h3>
                        {visibilityKey ? (
                            <div className={keyStyles.visibilityCondition}>
                                {/* Display different messages based on switch states */}
                                {showIfKey || hideIfKey ? (
                                    <>
                                        <p>
                                            This choice is ONLY {showIfKey ? 'SHOWN' : 'HIDDEN'} if the user{' '}
                                            has the following key:
                                        </p>
                                    </>
                                ) : (
                                    <>
                                        <p className={keyStyles.inactiveMessage}>
                                            A visibility key is selected but not active. This key is currently not affecting the choice's visibility.
                                        </p>
                                    </>
                                )}
                                {/* Group key name and buttons on the same line */}
                                <div className={keyStyles.keyNameAndButtons}>
                                    <strong>{keys.find((k) => k._id === visibilityKey)?.name || 'Unknown Key'}</strong>
                                    {!isSelectingVisibilityKey && (
                                        <div className={keyStyles.visibilityButtonsInline}>
                                            <button
                                                onClick={() => setIsSelectingVisibilityKey(true)}
                                                className={keyStyles.changeKeyButton}
                                            >
                                                Change
                                            </button>
                                            <button
                                                onClick={handleRemoveVisibilityKey}
                                                className={keyStyles.removeKeyButtonInline}
                                            >
                                                Remove
                                            </button>
                                        </div>
                                    )}
                                </div>
                                {/* Key list appears here when changing */}
                                {isSelectingVisibilityKey && (
                                    <div className={keyStyles.availableKeysContainer}>
                                        <h4>Select a new visibility key:</h4>
                                        <ul className={keyStyles.availableKeysList}>
                                            {keys.map((key) => (
                                                <li
                                                    key={key._id}
                                                    className={keyStyles.availableKeyItem}
                                                    onClick={() => {
                                                        handleSelectVisibilityKey(key._id);
                                                        setIsSelectingVisibilityKey(false);
                                                    }}
                                                >
                                                    <div className={keyStyles.keyName}>{key.name}</div>
                                                    <div className={keyStyles.keyDescription}>{key.description}</div>
                                                </li>
                                            ))}
                                        </ul>
                                        <button
                                            onClick={() => setIsSelectingVisibilityKey(false)}
                                            className={keyStyles.cancelGrantButton}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                )}
                                {/* Switches inside the visibility container */}
                                <div className={keyStyles.switchesContainer}>
                                    <div className={keyStyles.switchItem}>
                                        <label>ONLY show with key</label>
                                        <StyledSwitch
                                            onChange={(value) => handleSwitchChange('showIfKey', value)}
                                            checked={showIfKey}
                                            disabled={hideIfKey}
                                        />
                                    </div>
                                    <div className={keyStyles.switchItem}>
                                        <label>Hide with key</label>
                                        <StyledSwitch
                                            onChange={(value) => handleSwitchChange('hideIfKey', value)}
                                            checked={hideIfKey}
                                            disabled={showIfKey}
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className={keyStyles.visibilityCondition}>
                                <p>No visibility key set for this choice.</p>
                                <button
                                    onClick={() => setIsSelectingVisibilityKey(true)}
                                    className={keyStyles.grantKeyButton}
                                >
                                    Set Visibility Key
                                </button>
                                {isSelectingVisibilityKey && (
                                    <div className={keyStyles.availableKeysContainer}>
                                        <h4>Select a key to control visibility:</h4>
                                        <ul className={keyStyles.availableKeysList}>
                                            {keys.map((key) => (
                                                <li
                                                    key={key._id}
                                                    className={keyStyles.availableKeyItem}
                                                    onClick={() => {
                                                        handleSelectVisibilityKey(key._id);
                                                        setIsSelectingVisibilityKey(false);
                                                    }}
                                                >
                                                    <div className={keyStyles.keyName}>{key.name}</div>
                                                    <div className={keyStyles.keyDescription}>{key.description}</div>
                                                </li>
                                            ))}
                                        </ul>
                                        <button
                                            onClick={() => setIsSelectingVisibilityKey(false)}
                                            className={keyStyles.cancelGrantButton}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>

                </div>
            )}


            {/* Modals */}
            {isDeleteModalOpen && (
                <DeleteModal onConfirm={confirmDeleteChoice} onCancel={cancelDeleteChoice} type={'Choice'} />
            )}
        </>
    );
};

export default EditChoice;
