import React, { useContext, useEffect, useState } from 'react';
import styles from './styles/ScenarioComments.module.css';
import axios from 'axios';
import AuthContext from '../../../context/AuthContext';
import deleteImage from '../../../images/delete.png';
import favoriteOutline from '../../../images/FavoriteOutline.png';
import favorite from '../../../images/Favorite.png';

const ScenarioComments = ({ scenarioId, userId }) => {
    const { user } = useContext(AuthContext);
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [likedComments, setLikedComments] = useState([]);
    const [likesCount, setLikesCount] = useState({});

    useEffect(() => {
        if (scenarioId) {
            axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/comment/scenario/${scenarioId}`)
                .then(response => {
                    setComments(response.data);
                    const initialLikesCount = {};
                    response.data.forEach(comment => {
                        initialLikesCount[comment._id] = comment.likers.length;
                    });
                    setLikesCount(initialLikesCount);
                })
                .catch(error => console.error('Error fetching comments:', error));
        }

        if (user) {
            setLikedComments(user.likedComments);
        }
    }, [scenarioId, user]);

    const handleCreateComment = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL_LOCAL}/api/comment`, {
                message: newComment,
                sender: userId,
                origin: scenarioId,
            });
    
            const newCommentData = response.data;
            if (!newCommentData.sender || !newCommentData.sender.username) {
                newCommentData.sender = {
                    _id: userId,
                    username: user.username,
                    profilePicture: user.profilePicture,
                };
            }
    
            setComments([...comments, newCommentData]);
            setLikesCount({
                ...likesCount,
                [newCommentData._id]: 0,
            });
            setNewComment('');
        } catch (error) {
            console.error('Error submitting comment:', error);
        }
    };
    

    const handleClickLike = async (commentId) => {
        try {
            await axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/comment/like/${commentId}`, {
                userId
            });

            setLikedComments(likedComments => {
                if (likedComments.includes(commentId)) {
                    setLikesCount({
                        ...likesCount,
                        [commentId]: likesCount[commentId] - 1,
                    });
                    return likedComments.filter(id => id !== commentId);
                } else {
                    setLikesCount({
                        ...likesCount,
                        [commentId]: likesCount[commentId] + 1,
                    });
                    return [...likedComments, commentId];
                }
            });

        } catch (error) {
            console.error('Error liking comment: ', error);
        }
    };

    const handleDeleteComment = async (commentId) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL_LOCAL}/api/comment/${commentId}`);
            setComments(comments.filter(comment => comment._id !== commentId));
            const { [commentId]: _, ...rest } = likesCount; // Remove the deleted comment's like count
            setLikesCount(rest);
        } catch (error) {
            console.error('Error deleting comment: ', error);
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.title}>Comments</div>
            <div className={styles.commentsList}>
                {[...comments].reverse().map(comment => (
                    <div key={comment._id} className={styles.comment}>
                        <div className={styles.messageDeleteModule}>
                            <div className={styles.message}>{comment.message}</div>
                            {(comment.sender._id === userId) && (
                                    <img
                                    src={deleteImage}
                                    onClick={() => handleDeleteComment(comment._id)}
                                    className={styles.deleteButton}
                                    />
                                )}
                        </div>
                        <div className={styles.username}>{comment.sender.username}</div>
                        <div className={styles.userandlike}>
                            <div className={styles.likesModule}>
                                {likedComments.includes(comment._id) ? 
                                    <img className={styles.likesImage} src={favorite} onClick={() => handleClickLike(comment._id)}/> : <img className={styles.likesImage} src={favoriteOutline} onClick={() => handleClickLike(comment._id)}/>
                                }
                                <div className={styles.likes}>{likesCount[comment._id] || 0}</div>
                            </div>
                            <div className={styles.timestamp}>{new Date(comment.createdAt).toLocaleString()}</div>
                        </div>
                    </div>
                ))}
            </div>

            <form onSubmit={handleCreateComment} className={styles.commentForm}>
                <textarea
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                    className={styles.textarea}
                    minLength={2}
                    placeholder="Add a comment..."
                />
                <button type="submit" className={styles.submitButton}>Submit</button>
            </form>
        </div>
    )
}

export default ScenarioComments;
