// ScenarioContext.jsx

import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';

export const ScenarioContext = createContext();

export const ScenarioProvider = ({ children }) => {
    const [lastUpdate, setLastUpdate] = useState(Date.now());
    const [currentSituations, setCurrentSituations] = useState(() => {
        const savedSituations = JSON.parse(localStorage.getItem('currentSituations')) || {};
        return savedSituations;
    });
    const [logId, setLogId] = useState(null); // Track the current log ID

    useEffect(() => {
        if (currentSituations) {
            localStorage.setItem('currentSituations', JSON.stringify(currentSituations));
        }
    }, [currentSituations]);

    const setCurrentSituationId = (scenarioId, situationId) => {
        setCurrentSituations(prev => {
            const newSituations = { ...prev, [scenarioId]: situationId };
            localStorage.setItem('currentSituations', JSON.stringify(newSituations));
            return newSituations;
        });
    };

    const startLog = async (scenarioId, viewerId, ownerId) => {
        const response = await axios.post(`${process.env.REACT_APP_API_URL_LOCAL}/api/logs/start`, { scenarioId, viewerId, ownerId });
        setLogId(response.data.logId);
    };

    const addDecisionToLog = async (decision) => {
        if (logId) {
            await axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/logs/${logId}/addDecision`, { decision });
        }
    };

    const completeLog = async () => {
        if (logId) {
            await axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/logs/${logId}/complete`);
            setLogId(null);
        }
    };

    // This function might not be needed
    const triggerUpdate = () => {
        setLastUpdate(Date.now());
    };

    return (
        <ScenarioContext.Provider value={{ 
            lastUpdate, 
            triggerUpdate, 
            currentSituations, 
            setCurrentSituationId, 
            startLog, 
            logId,
            setLogId,
            addDecisionToLog, 
            completeLog,
        }}>
            {children}
        </ScenarioContext.Provider>
    );
};

export const useScenario = () => {
    return useContext(ScenarioContext);
};
