import React, { useContext } from 'react';
import styles from './styles/MyClassroomsSidebar.module.css';
import { FaHome } from 'react-icons/fa';
import { Link, useMatch } from 'react-router-dom';
import { ClassroomContext } from '../../context/ClassroomContext';

const MyClassroomsSidebar = () => {
    const { classrooms, joinedClassrooms } = useContext(ClassroomContext);
    const match = useMatch('/my-classrooms/:classroomId/*');
    const classroomId = match ? match.params.classroomId : null;

    return (
        <div className={styles.sidebarContainer}>
            <div className={styles.sidebarOptions}>
                <Link to="/my-classrooms" className={styles.linkStyle}>
                    <div
                        className={`${styles.sidebarClassroomItem} ${!classroomId ? styles.sidebarClassroomItemSelected : ''}`}
                    >
                        <FaHome className={styles.icon} />Home
                    </div>
                </Link>
            </div>

            {/* My Classrooms Section */}
            <div className={styles.sectionContainer}>
                <div className={styles.sectionTitle}>My Classrooms</div>
                <div className={styles.sidebarClassrooms}>
                    {classrooms.slice().reverse().map((classroom) => (
                        <Link to={`/my-classrooms/${classroom._id}/a`} key={classroom._id} className={styles.linkStyle}>
                            <div
                                className={`${styles.sidebarClassroomItem} ${classroomId === classroom._id ? styles.sidebarClassroomItemSelected : ''}`}
                            >
                                {classroom.name}
                            </div>
                        </Link>
                    ))}
                </div>
            </div>

            {/* Divider */}
            <div className={styles.divider}></div>

            {/* Joined Classrooms Section */}
            <div className={styles.sectionContainer}>
                <div className={styles.sectionTitle}>Joined Classrooms</div>
                <div className={styles.sidebarClassrooms}>
                    {joinedClassrooms.slice().reverse().map((classroom) => (
                        <Link to={`/my-classrooms/${classroom._id}/a`} key={classroom._id} className={styles.linkStyle}>
                            <div
                                className={`${styles.sidebarClassroomItem} ${classroomId === classroom._id ? styles.sidebarClassroomItemSelected : ''}`}
                            >
                                {classroom.name}
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default MyClassroomsSidebar;
