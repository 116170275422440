// FolderModal.js

import React, { useState, useEffect, useContext } from 'react';
import styles from './styles/FolderModal.module.css'; // Updated import
import { ClassroomContext } from '../../context/ClassroomContext';
import profileIcon from '../../images/profileicon.png';

const FolderModal = ({
    isOpen,
    onClose,
    mode,
    folder,
    parentAllowedStudents,
    parentFolderId,
    onFolderCreated,
    onFolderUpdated,
    onFolderDeleted,
}) => {
    const { classroom, createFolder, updateFolder, deleteFolder } = useContext(ClassroomContext);
    const [folderName, setFolderName] = useState('');
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [isPrivate, setIsPrivate] = useState(false);
    const [error, setError] = useState(null);
    const [closing, setClosing] = useState(false); // State to manage fade-out animation

    const students = Array.isArray(parentAllowedStudents) && parentAllowedStudents.length > 0 
        ? parentAllowedStudents 
        : classroom?.students || [];

    useEffect(() => {
        if (folder) {
            setFolderName(folder.name);
            setIsPrivate(folder.isPrivate || false);
            setSelectedStudents(folder.allowedStudents || []);
        } else {
            setFolderName('');
            setIsPrivate(false);
            setSelectedStudents([]);
        }
    }, [folder]);

    const handleNameChange = (e) => {
        setFolderName(e.target.value);
    };

    const toggleStudentSelection = (studentId) => {
        if (selectedStudents.includes(studentId)) {
            setSelectedStudents(selectedStudents.filter((id) => id !== studentId));
        } else {
            setSelectedStudents([...selectedStudents, studentId]);
        }
    };

    const handleIsPrivateChange = (e) => {
        setIsPrivate(e.target.checked);
    };

    const handleSubmit = async () => {
        if (folderName.trim() === '') {
            setError('Folder name cannot be empty');
            return;
        }
    
        const folderData = {
            name: folderName,
            classroomId: classroom._id,
            allowedStudents: isPrivate ? selectedStudents : [],
            isPrivate,
            parentFolderId: parentFolderId || null,
        };
    
        try {
            if (mode === 'create') {
                const newFolder = await createFolder(folderData);
                if (onFolderCreated) {
                    onFolderCreated(newFolder);
                }
            } else if (mode === 'edit') {
                const updatedFolder = await updateFolder(folder._id, folderData);
                if (onFolderUpdated) {
                    onFolderUpdated(updatedFolder);
                }
            }
            triggerClose(); // Trigger fade-out on successful save
        } catch (error) {
            console.error('Error in FolderModal submit:', error);
            setError('An error occurred. Please try again.');
        }
    };

    const handleDelete = async () => {
        try {
            await deleteFolder(folder._id);
            if (onFolderDeleted) {
                onFolderDeleted(folder._id);
            }
            triggerClose(); // Trigger fade-out on successful delete
        } catch (error) {
            console.error('Error deleting folder:', error);
            setError('An error occurred. Please try again.');
        }
    };

    const triggerClose = () => {
        setClosing(true); // Start the fade-out animation
        setTimeout(() => {
            onClose(); // Close the modal after the animation completes
            setClosing(false); // Reset the closing state for future use
        }, 300); // Match this timeout with the CSS animation duration (0.3s)
    };

    if (!isOpen && !closing) return null; // Don't render if it's not open and not in the middle of closing

    return (
        <div className={`${styles.modalOverlay} ${closing ? styles.fadeOut : styles.fadeIn}`}>
            <div className={styles.modalContent}>
                {/* Modal Header */}
                <div className={styles.modalHeader}>
                    <h2 className={styles.modalTitle}>{mode === 'create' ? 'Create Folder' : 'Edit Folder'}</h2>
                </div>

                {/* Modal Body */}
                <div className={styles.modalBody}>
                    <label htmlFor="folderName" className={styles.label}>Folder Name</label>
                    <input
                        type="text"
                        id="folderName"
                        value={folderName}
                        onChange={handleNameChange}
                        className={styles.modalInput}
                    />

                    <div className={styles.checkboxContainer}>
                        <input
                            type="checkbox"
                            id="isPrivate"
                            checked={isPrivate}
                            onChange={handleIsPrivateChange}
                        />
                        <label htmlFor="isPrivate">Private Folder</label>
                    </div>

                    {isPrivate && (
                        <>
                            <div className={styles.listHeader}>
                                Select students to have access to this folder:
                            </div>
                            <ul className={styles.list}>
                                {students.map((student) => (
                                    <li
                                        key={student._id}
                                        onClick={() => toggleStudentSelection(student._id)}
                                        className={`${styles.item} ${selectedStudents.includes(student._id) ? styles.selectedItem : ''}`}
                                    >
                                        <img
                                            src={profileIcon}
                                            alt="User Icon"
                                            className={styles.profileIcon}
                                        />{' '}
                                        {student.username}
                                    </li>
                                ))}
                            </ul>
                        </>
                    )}

                    {error && (
                        <div className={styles.error}>{error}</div>
                    )}
                </div>

                {/* Modal Footer */}
                <div className={styles.modalFooter}>
                    <div className={styles.buttonContainer}>
                        <button
                            className={`${styles.buttonBase} ${styles.saveButton}`}
                            onClick={handleSubmit}
                        >
                            {mode === 'create' ? 'Create' : 'Save'}
                        </button>
                        {mode === 'edit' && (
                            <button
                                onClick={handleDelete}
                                className={`${styles.buttonBase} ${styles.deleteButton}`}
                            >
                                Delete
                            </button>
                        )}
                        <button
                            className={`${styles.buttonBase} ${styles.cancelButton}`}
                            onClick={triggerClose}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FolderModal;
